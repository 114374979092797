import React, { useContext, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { useGetCheckoutDotComCards } from 'views/Accounts/hooks/useGetCheckoutDotComCards';
import AddCheckoutDotComCardModal from 'views/Accounts/components/Checkout.Com/AddCardModal';
import ThreeDSecureFeedbackSuccessModal from 'views/Accounts/components/Checkout.Com/3DSecureFeedback/Success';
import ThreeDSecureFeedbackFailureModal from 'views/Accounts/components/Checkout.Com/3DSecureFeedback/Failure';
import TopUpSuccess from 'views/Accounts/components/Checkout.Com/3DSecureFeedback/TopUpSuccess';
import TopUpFailure from 'views/Accounts/components/Checkout.Com/3DSecureFeedback/TopUpFailure';
import { useLocation, useNavigate } from 'react-router-dom';
import { isLoggedInVar } from 'graphql/cache';
import { dateIsInPast } from 'utils';
import { AppContext } from 'context/ContextProvider';
import { selectCheckoutDotComCards, userHasValidCards } from 'views/Accounts/utils';

import { AppEventTypes } from 'types/AppType';
import { MgmtFeeTypeEnum } from 'views/shared/types';

export default function AddCardModalWrapper() {
  const { cardManagement14583 } = useFlags();
  const isLoggedIn = isLoggedInVar();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    state: {
      app: { showAddCardModal, hasCard, testMode },
      settings: { firstLoginDate, mgmtFeesPolicy },
    },
    dispatch,
  } = useContext(AppContext);

  const threeDSecureSuccessModalOpen = location.hash === '#addCard_success';
  const topUpSuccessModalOpen = location.hash === '#topUp_success';
  const threeDSecureFailureModalOpen = location.hash === '#addCard_failure';
  const topUpFailureModalOpen = location.hash === '#topUp_failure';
  const { data, error, loading } = useGetCheckoutDotComCards({
    skip:
      // 🚨 Running this query prior to login causes all sorts of issues apparently.
      !isLoggedIn || !cardManagement14583,
  });
  const _cards = selectCheckoutDotComCards(data);
  const requiredCardDateMs =
    firstLoginDate && firstLoginDate.length ? moment(firstLoginDate).add(1, 'year') : undefined;
  const [addCardModalOpen, setAddCardModalOpen] = useState(false);
  const hasAlreadyDismissedModal = Boolean(sessionStorage.getItem('userHasDismissedAddCardModal'));
  const isDisabled = true;

  useEffect(() => {
    const hasValidCard = !!testMode && testMode ? false : userHasValidCards(_cards);
    if (
      !isDisabled &&
      !addCardModalOpen &&
      cardManagement14583 &&
      !hasValidCard &&
      !loading &&
      !error &&
      !!mgmtFeesPolicy.type &&
      mgmtFeesPolicy.type === MgmtFeeTypeEnum.MONTHLY &&
      (!hasAlreadyDismissedModal || dateIsInPast(requiredCardDateMs?.valueOf()))
    ) {
      setAddCardModalOpen(true);
    }

    if (hasCard !== hasValidCard) {
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: {
          hasCard: hasValidCard,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_cards, loading, error, requiredCardDateMs, cardManagement14583, hasAlreadyDismissedModal]);

  useEffect(() => {
    if (!addCardModalOpen && showAddCardModal) {
      setAddCardModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddCardModal]);

  function handleClose3DSecureFeedbackModal() {
    navigate({ pathname: location.pathname, search: location.search, hash: '' });
  }

  function handleCloseCardEntryModal() {
    setAddCardModalOpen(false);
    sessionStorage.setItem('userHasDismissedAddCardModal', 'true');
    dispatch({
      type: AppEventTypes.UPDATE_STATE,
      payload: {
        showAddCardModal: false,
      },
    });
  }

  return (
    <>
      {cardManagement14583 && (
        <AddCheckoutDotComCardModal open={addCardModalOpen} onClose={handleCloseCardEntryModal} />
      )}
      {cardManagement14583 && threeDSecureSuccessModalOpen && (
        <ThreeDSecureFeedbackSuccessModal
          open={threeDSecureSuccessModalOpen}
          onClose={handleClose3DSecureFeedbackModal}
        />
      )}
      {cardManagement14583 && threeDSecureFailureModalOpen && (
        <ThreeDSecureFeedbackFailureModal
          open={threeDSecureFailureModalOpen}
          onClose={handleClose3DSecureFeedbackModal}
        />
      )}
      {topUpSuccessModalOpen && (
        <TopUpSuccess open={topUpSuccessModalOpen} onClose={handleClose3DSecureFeedbackModal} />
      )}
      {topUpFailureModalOpen && (
        <TopUpFailure open={topUpFailureModalOpen} onClose={handleClose3DSecureFeedbackModal} />
      )}
    </>
  );
}
