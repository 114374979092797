import { httpPost } from 'utils';

interface SetNotificationPreferencesParams {
  portalPreferences: {
    categoryId: string;
    email: boolean;
  }[];
}

interface SetNotificationPreferencesResponse {
  status: string;
  errorMessage: string;
  result: {
    userId: string;
    portalPreferences: {
      categoryId: string;
      email: boolean;
    }[];
  };
}

export const setNotificationPreferencesApi = (params?: SetNotificationPreferencesParams) =>
  httpPost<SetNotificationPreferencesResponse, SetNotificationPreferencesParams>(
    'notification/Notification/PortalPreferences',
    params,
  );
