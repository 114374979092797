import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayTextKeys, SetSlideoutConfigType, SlideoutConfigType } from 'views/Trade/types';
import { AcceptBidEventType, Product } from 'types/productType';
import WineInfoTemplate from 'views/Trade/components/SlideOutView/WineInfoTemplate';
import { generateQuantityDropdown } from 'views/Trade/helpers';
import { AccountInfoType } from 'views/hooks/useFetchAccountData';
import useAcceptBid from 'hooks/useAcceptBid';
import { FeedbackTemplate } from 'views/shared/FeedbackTemplate';
import { buildDisplayText } from '../../../utils';
import Button from '../../Button';
import Dropdown, { DropdownItem } from '../../Dropdown';
import { ViewStateType } from '../types';
import { SortAndFilterLayoutContext } from '../../Layout/SortAndFilterLayout';
import { GbpToTargetCurrency } from '../../../types/commonTypes';

interface AcceptBidTemplateProps {
  slideOutConfig: SlideoutConfigType;
  setSlideOutConfig: SetSlideoutConfigType;
  product: Product;
  gbpToTargetCurrency: GbpToTargetCurrency;
  accountInfo: AccountInfoType | null;
}

const AcceptBidTemplate = ({
  setSlideOutConfig,
  slideOutConfig,
  gbpToTargetCurrency,
  product,
  accountInfo,
}: AcceptBidTemplateProps) => {
  const { t } = useTranslation();
  const slideoutContext = useContext(SortAndFilterLayoutContext);

  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [viewState, setViewState] = useState<AcceptBidEventType>({
    viewStateType: ViewStateType.DEFAULT,
    messageType: {},
  });
  const { physicalQuantity, highestBid } = product;

  const qtyDropdown = (
    generateDropdown: (quantity?: number) => DropdownItem[],
    stockQuantity: number,
    bidQty: number,
  ) => {
    let qtyToGenerate = 0;
    if (bidQty > stockQuantity) {
      qtyToGenerate = stockQuantity;
    } else {
      qtyToGenerate = bidQty ?? 0;
    }
    return generateDropdown(qtyToGenerate);
  };

  const lwin18AndHighestBidPrice = useMemo(() => {
    return { lwin18: product?.lwin18, highestBidPrice: product?.highestBid?.priceGbp ?? null };
  }, [product]);

  const { acceptBid } = useAcceptBid(lwin18AndHighestBidPrice.lwin18, lwin18AndHighestBidPrice.highestBidPrice);

  const onClick = async (
    acceptBidFun: (selectedQty: number) => Promise<void>,
    selectedQty: number,
    account: AccountInfoType | null,
  ) => {
    setSlideOutConfig((prev) => ({ ...prev, showBackButton: false }));
    try {
      setViewState({ viewStateType: ViewStateType.LOADING });
      await acceptBidFun(selectedQty);
      setViewState({
        viewStateType: ViewStateType.THANK_YOU,
        messageType: { translationKey: 'common:generalSuccess' },
      });
      setTimeout(() => {
        account?.account.refetchCashBalances?.();
      }, 3000);
    } catch (error) {
      setViewState({ viewStateType: ViewStateType.ACCEPT_BID_ERROR });
    }
  };

  const onClose = () => {
    setSlideOutConfig((prev) => ({ ...prev, isOpen: false }));
    slideoutContext?.updateSlideoutConfig({ open: false });
  };

  return (
    <FeedbackTemplate
      onClose={onClose}
      templateConfig={viewState.messageType}
      viewState={viewState.viewStateType}
      onCTA={onClose}
    >
      <WineInfoTemplate config={slideOutConfig.wineInfoTemplateConfig}>
        <div className="flex flex-col py-10 px-5 drop-shadow-sm ">
          <Dropdown
            placeholder={displayText[DisplayTextKeys.PLEASE_SELECT_QUANTITY]}
            value={selectedQuantity > 0 ? selectedQuantity?.toString() : ''}
            containerClassName="w-full rounded "
            itemsContainerClassName="max-h-[250px] min-h-10 overflow-y-auto"
            className="flex-1 text-14 text-black border  h-12 p-4 rounded justify-between items-center"
            itemsWrapperClassName="w-full"
            items={qtyDropdown(generateQuantityDropdown, physicalQuantity, highestBid?.quantityOutstanding ?? 0)}
            onItemSelect={(item) => setSelectedQuantity(Number(item.value))}
          />
          <div className="flex justify-between divide-x divide-gray-300 mt-4">
            <div className="flex-1 flex flex-col">
              <span className="text-center text-sm">{`${displayText[DisplayTextKeys.ACCEPT_BID_MESSAGE]}`}</span>
              <span className="text-center text-sm">
                {`${selectedQuantity} x ${gbpToTargetCurrency.convertAndFormat(highestBid?.priceGbp ?? 0)} `}
              </span>
            </div>
          </div>

          <Button
            isDisable={!selectedQuantity}
            onClick={() => onClick(acceptBid, selectedQuantity, accountInfo)}
            type="button"
            className="text-14 font-normal rounded-full mt-8  btn  bg-orange text-black disabled:bg-[#cccccc] disabled:text-[#b3b3b3]"
          >
            {displayText[DisplayTextKeys.ACCEPT_BID]}
          </Button>
        </div>
      </WineInfoTemplate>
    </FeedbackTemplate>
  );
};

export default AcceptBidTemplate;
