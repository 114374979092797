import { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SlideOutPanelViews } from 'views/Accounts/types';
import { useProtectedPageContext } from 'components/ProtectedPageContainer';
import { useApolloClient } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useExecuteMutation } from 'views/hooks/useExecuteMutation';
import { UPDATE_USER_PREFERENCES } from 'views/Accounts/graphql/updateUserPreferences';
import { capitalizeFirstLetter } from 'utils';
import useCountdown from 'views/Authentication/hooks/useCountdown';
import { GET_USER_PREFERENCES } from 'views/Accounts/graphql/getUserPreferences';
import { BackArrow, ChevronDown, ChevronUp, HamburgerIcon, NotificationIcon } from '../../assets/icons';
import { Button, Dropdown } from '../../components';
import { logError } from '../../components/LogError';
import { AppContext } from '../../context/ContextProvider';
import { isLoggedInVar } from '../../graphql/cache';
import { signOut } from '../../services/auth';
import { AppEventTypes, ManagementFeePolicy, SettingsEventTypes, UserSettings } from '../../types/AppType';
import { CurrencyFormater, ObjectType } from '../../types/commonTypes';
import { NavigationPath, RefreshRegion } from '../../types/DomainTypes';
import { useExecuteQuery } from '../hooks/useExecuteQuery';
import { GET_NOTIFICATIONS } from '../Notifications/graphql/getNotifications';
import { NotificationResponse, NotificationType } from '../Notifications/types';
import { PortfolioCashBalance } from '../Portfolio/components/PortfolioCashBalance';

import SharedSideBar from './SharedSideBar';
import {
  BalanceSummary,
  CashBalanceResponse,
  EstimatedManagementFeeResponse,
  MgmtFeeTypeEnum,
  MgmtViewsEnum,
  SideBarItemType,
} from './types';
import MgmFeePolicyEnforcer from './MgmtFeePolicyEnforcer';
import HowToPayModal from './HowToPay/HowToPayModal';

import MgmtFeeBanner from './MgmtFeePolicyEnforcer/mgmtFeeBanner';
import LegacyFeeBanner from './MgmtFeePolicyEnforcer/legacyFeeBanner';
import { HowToPayEnum } from './HowToPay/type';
import { RadioBtnOption } from './MgmtFeePolicyEnforcer/mgmtPaymentTypeSelector';
import PreferenceConfirmation from './MgmtFeePolicyEnforcer/preferenceConfirmation';
import ChargeAndSaveCard, { ChargeAndSaveCardEnum } from './HowToPay/chargeAndSaveCard';
import { useEstimatedFeeAmounts } from './MgmtFeePolicyEnforcer/hooks/useEstimatedFeeAmounts';
import { useOutstandingFeeAmounts } from './MgmtFeePolicyEnforcer/hooks/useOutstandingFeeAmounts';
import { useFetchCashBalance } from './hooks/useFetchCashBalance';
import FeePolicyEnforcerTemplate from './MgmtFeePolicyEnforcer/FeePolicyEnforcerTemplate';

const TIMER_ADJUSTMENT = 0.3 * 60 * 1000;

const getPortfolioBalanceDropdownOptions = (portfolioBalances: BalanceSummary[], formatter: CurrencyFormater) => {
  return portfolioBalances
    .filter((x) => x.portfolioId !== '')
    .map((option) => {
      const { portfolioId, portfolioName, balance } = option;
      return {
        id: portfolioId,
        value: portfolioId,
        content: (
          <div key={`key-${portfolioId}`} className="flex justify-between text-xs sm:text-14">
            <div>{portfolioName}</div>
            <div>{`${formatter.format(balance)}`}</div>
          </div>
        ),
      };
    });
};
const notificationPullInterval = Number(process.env.REACT_APP_NOTIFICATION_POLL_INTERVAL || 1000 * 60 * 5); // default to 5 mins

interface AddCardRefType {
  timestamp: string;
  type: string;
  dbKey: string;
  token: string;
  pid?: string;
  howToPay: string;
}

enum BannerTypeEnum {
  COMBINE_FEES = 'combineFees',
  LEGACY = 'legacy',
  MGMT_FEE = 'mgmt_fee',
}
interface CombineFeeFlowsType {
  combine?: boolean | null;
  isLoading: boolean;
  isSuccess?: boolean;
}
interface Props {
  children?: ReactNode;
  title: string;
  showBackButton?: boolean;
  onBack: () => void;
  view: NavigationPath;
  containerClassName?: string;
  className?: string;
}

interface PortalClientPreferencesType {
  portalClientPreferences: {
    __typename: string;
    currency: string;
    language: string;
    firstLoginDate: string;
    managementFeePayment: null;
    annualManagementFeePayType: null;
    historicManagementFeePayType: null;
    bankTransferChaseEmailCount: number;
  };
}

const ProtectedPageLayout: FC<Props> = ({
  children,
  title,
  showBackButton = false,
  view,
  onBack,
  containerClassName,
  className,
}) => {
  const { openSlideout } = useProtectedPageContext();
  const client = useApolloClient();
  const queryClient = useQueryClient();
  const { managementFeeUpdates14758, enforceMgmtFeeRules, bankTransferMinAmount, accruedFeesExclusionList } =
    useFlags();
  const [showMenu, setShowMenu] = useState(false);
  const [showLegacyBannerHandle, setShowLegacyBannerHandle] = useState<NodeJS.Timeout | null>(null);
  const [combineFeeFlows, setcombineFeeFlows] = useState<CombineFeeFlowsType>({
    combine: true,
    isLoading: true,
    isSuccess: false,
  });
  const [canCollectPreference, setCanCollectPreference] = useState(true);
  const [isTimerEnabled, setIsTimerEnabled] = useState(false);
  const { data: countDownData, onTargetDateChange } = useCountdown(0);
  const [, , minutes, seconds] = countDownData;

  const navigate = useNavigate();

  const {
    data: cashBalanceResponse,
    isLoading: loadingCashBalances,
    refetch: refetchCashBalances,
    error,
  } = useFetchCashBalance();

  const { estimatedFeeAmounts: estimatedManagementFeeResponse, loading: isloadingEstimatedManagementFeeResponse } =
    useEstimatedFeeAmounts();
  // const { results: estimatedManagementFeeResponse, loading: isloadingEstimatedManagementFeeResponse } = useExecuteQuery(
  //   'portalEstimateManagementFee',
  //   GET_ESTIMATED_FEE_AMOUNT,
  // );

  const { feeAmounts: totalMgmtFeeResponse } = useOutstandingFeeAmounts({});

  const { data: portalClientPreferences, loading: isLoadingPortalClientPreferences } = useExecuteQuery(
    'portalClientPreferences',
    GET_USER_PREFERENCES,
    { fetchPolicy: 'network-only' },
  );

  const {
    executor: updatePreferenceMutationApi,
    data: userPreferenceResponse,
    error: updatePreferenceError,
  } = useExecuteMutation(UPDATE_USER_PREFERENCES);
  if (error instanceof Error) {
    logError((error as Error).message);
  }

  const updatePreferenceMutation = (
    request: Record<string, unknown>,
    options?: Record<string, unknown> | undefined,
  ) => {
    logError(JSON.stringify({ email, ...request }), { tags: { userFlow: 'pay_fee_preferences' } });
    updatePreferenceMutationApi(request, options);
  };

  const [isShowBalances, setIsShowBalances] = useState(false);
  const { t } = useTranslation();
  const [viewState, setViewState] = useState<{
    showBanner: boolean;
    forceAction: boolean;
    showMore: boolean;
    viewType: MgmtViewsEnum;
    viewData?: ObjectType;
    bannerType: BannerTypeEnum;
  }>({
    showBanner: false,
    forceAction: false,
    showMore: false,
    viewType: MgmtViewsEnum.MGMT_FEE_TYPE,
    bannerType: BannerTypeEnum.MGMT_FEE,
  });

  const {
    state: {
      app: {
        hasNotifications,
        refresh,
        showAddCardModal,
        hasCard,
        testMode,
        addCardRef,
        totalMgmtFees,
        remindToPayBankTransfer,
      },
      settings: { fullname, mgmtFeesPolicy, legacyFeesPolicy, email, accountInfo },
    },
    dispatch,
    formatter: currencyFormatter,
  } = useContext(AppContext);

  const disableAccruedFeeModal = useMemo(() => {
    const list = accruedFeesExclusionList as { exclusionList: [] };
    if (!list?.exclusionList?.length) return false;
    const bool = (list?.exclusionList || []).find(
      (userEmail) => String(userEmail).toLowerCase() === String(email).toLowerCase(),
    );
    return Boolean(bool);
  }, [accruedFeesExclusionList, email]);

  const [showBankTransferReminder, setShowBankTransferReminder] = useState(false);
  const formatter = useMemo(
    () => ({ format: (value: number) => currencyFormatter.format(value, true) }),
    [currencyFormatter],
  );
  const { exemptFromAllFees } = useFlags();
  const balances = useMemo(() => {
    const response = cashBalanceResponse as CashBalanceResponse;
    if (!response || !response.balances) return { total: null, secondary: [] };
    const allCashBalance = response?.balances.find((x) => !x.portfolioId || `${x.portfolioId}` === '');
    return {
      total: allCashBalance?.balance,
      secondary: response.balances.filter((x) => allCashBalance?.portfolioId !== x.portfolioId),
    };
  }, [cashBalanceResponse]);

  const [loginUserInitials, setLoginUserInitials] = useState('');

  const { results: fetchedNotifications } = useExecuteQuery('userNotifications', GET_NOTIFICATIONS, {
    variables: {
      from: 0,
      pageSize: 10000,
    },
    pollInterval: notificationPullInterval,
  });

  const portfolioDropdownOptions = useMemo(
    () => getPortfolioBalanceDropdownOptions(balances.secondary, formatter),
    [balances.secondary, formatter],
  );

  const onOpenMenu = () => {
    setShowMenu(true);
  };
  const onBackHandler = () => {
    if (onBack) onBack();
  };

  const onSideBarIconSelect = (item: SideBarItemType) => {
    navigate(`${item.value}`);
  };
  const openNotifications = () => {
    navigate(NavigationPath.NOTIFICATIONS);
  };

  const updateMgmFeeSetting = (payload: Partial<ManagementFeePolicy>) => {
    dispatch({
      type: SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS,
      payload,
    });
  };

  const enableLegacyFeeChecks = () => {
    dispatch({
      type: SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS,
      payload: {
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
      },
    });
  };

  const onOpenCloseAddCardModal = (value: boolean) => {
    dispatch({
      type: AppEventTypes.UPDATE_STATE,
      payload: {
        showAddCardModal: value,
      },
    });
  };

  const onConfirmCombineFeeType = (value: string) => {
    if (value)
      updateMgmFeeSetting({
        type: value,
        howToPay: HowToPayEnum.CARD,
      });
    setcombineFeeFlows({
      isLoading: false,
      combine: false,
      isSuccess: true,
    });

    setViewState((prevState) => ({
      ...prevState,
      viewType: MgmtViewsEnum.CONFIRMATION,
      showMore: true,
    }));
  };

  const onConfirmMgmtFeeType = (value: string, dbKey: string, howToPay: string) => {
    if (value && dbKey)
      updatePreferenceMutation({
        request: {
          [dbKey]: capitalizeFirstLetter(value),
          annualManagementFeePayType: howToPay ? capitalizeFirstLetter(howToPay) : undefined,
        },
      });

    if (value)
      updateMgmFeeSetting({
        type: value,
        howToPay: HowToPayEnum.CARD,
      });
    onOpenCloseAddCardModal(false);
    setViewState((prevState) => ({
      ...prevState,
      viewType: MgmtViewsEnum.CONFIRMATION,
      showMore: true,
    }));
  };

  const onConfirmLegacyFeeType = (value: string, dbKey: string) => {
    if (value && dbKey)
      updatePreferenceMutation({
        request: {
          [dbKey]: capitalizeFirstLetter(value),
        },
      });

    onOpenCloseAddCardModal(false);
    setViewState((prevState) => ({
      ...prevState,
      bannerType: BannerTypeEnum.LEGACY,
      viewType: MgmtViewsEnum.CONFIRMATION,
      showMore: true,
    }));
  };

  const onMgmtFeeTypeSubmit = (data: unknown) => {
    if (viewState.showMore) setViewState({ ...viewState, showBanner: false, showMore: false });
    const mgmtFeeType = data as MgmtFeeTypeEnum;
    switch (mgmtFeeType) {
      case MgmtFeeTypeEnum.YEARLY:
        dispatch({
          type: AppEventTypes.UPDATE_STATE,
          payload: {
            addCardRef: {
              dbKey: 'managementFeePayment',
              type: mgmtFeeType,
              token: MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE,
              timestamp: '',
              howToPay: '',
            },
          },
        });

        setViewState((prevState) => ({
          ...prevState,
          viewType: MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE,
          showBanner: true,
          showMore: true,
        }));
        break;
      case MgmtFeeTypeEnum.MONTHLY:
        if (hasCard) {
          dispatch({
            type: AppEventTypes.UPDATE_STATE,
            payload: {
              addCardRef: {
                dbKey: 'managementFeePayment',
                type: mgmtFeeType,
                token: MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE,
                pid: '',
                howToPay: HowToPayEnum.CARD,
              },
            },
          });

          onConfirmMgmtFeeType(mgmtFeeType, 'managementFeePayment', HowToPayEnum.CARD);
        } else {
          dispatch({
            type: AppEventTypes.UPDATE_STATE,
            payload: {
              addCardRef: {
                dbKey: 'managementFeePayment',
                type: mgmtFeeType,
                email,
                howToPay: HowToPayEnum.CARD,
                token: MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE,
                timestamp: '',
                pid: `${mgmtFeeType}_${new Date().getTime().toString()}`,
              },
            },
          });
          onOpenCloseAddCardModal(true);
        }
        break;
      default:
        break;
    }
  };

  const onLegacyFeeTypeSubmit = (data: unknown, amount?: number) => {
    dispatch({
      type: AppEventTypes.UPDATE_STATE,
      payload: {
        addCardRef: {
          dbKey: 'historicManagementFeePayType',
          type: '',
          token: MgmtViewsEnum.LEGACY_FEE_TYPE,
          timestamp: '',
        },
      },
    });

    setViewState({
      showBanner: true,
      forceAction: true,
      bannerType: BannerTypeEnum.LEGACY,
      showMore: true,
      viewType: MgmtViewsEnum.LEGACY_FEE_PAYMENT_TYPE,
    });
  };

  const onCombineFeeTypeSubmit = (data: unknown, amount?: number) => {
    if (viewState.showMore) setViewState({ ...viewState, showBanner: false, showMore: false });
    const mgmtFeeType = data as MgmtFeeTypeEnum;
    switch (mgmtFeeType) {
      case MgmtFeeTypeEnum.YEARLY:
        dispatch({
          type: AppEventTypes.UPDATE_STATE,
          payload: {
            addCardRef: {
              dbKey: 'CombineFeePayment',
              type: mgmtFeeType,
              token: MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE,
              timestamp: '',
              howToPay: '',
            },
          },
        });

        setViewState((prevState) => ({
          ...prevState,
          viewType: MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE,
          showBanner: true,
          showMore: true,
        }));
        break;
      case MgmtFeeTypeEnum.MONTHLY:
        dispatch({
          type: AppEventTypes.UPDATE_STATE,
          payload: {
            addCardRef: {
              dbKey: 'CombineFeePayment',
              type: mgmtFeeType,
              email,
              howToPay: HowToPayEnum.CARD,
              token: MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE,
              timestamp: '',
              pid: `${mgmtFeeType}_${new Date().getTime().toString()}`,
            },
          },
        });

        setViewState((prevState) => ({
          ...prevState,
          viewType: MgmtViewsEnum.CHARGE_AND_SAVE_CARD,
          showBanner: true,
          showMore: true,
          viewData: {
            amount: amount,
            onBackClick: MgmtViewsEnum.COMBINE_FEE_TYPE,
            viewType: MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE,
          },
        }));
        break;
      default:
        break;
    }
  };

  const onCloseConfirmation = () => {
    onTargetDateChange(new Date().getTime() + TIMER_ADJUSTMENT);
    setIsTimerEnabled(true);
    const { token } = addCardRef as AddCardRefType;
    setViewState((prevState) => ({
      ...prevState,
      showMore: false,
    }));

    if (token === MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE || token === MgmtViewsEnum.COMBINE_FEE_TYPE) {
      setcombineFeeFlows((prev) => ({ ...prev, combine: false, isSuccess: true }));
      updateMgmFeeSetting({
        endDate: '',
      });
    }

    if (token === MgmtViewsEnum.MGMT_FEE_TYPE || token === MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE) {
      updateMgmFeeSetting({
        endDate: '',
      });
      enableLegacyFeeChecks();
    }

    if (token === MgmtViewsEnum.LEGACY_FEE_TYPE) {
      dispatch({
        type: SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS,
        payload: {
          endDate: '',
        },
      });
    }

    dispatch({
      type: AppEventTypes.UPDATE_STATE,
      payload: {
        addCardRef: {
          ...(addCardRef as ObjectType),
          token: '',
          timestamp: '',
        },
      },
    });
  };

  const onCloseCardProcessError = () => {
    if (viewState.viewData?.viewType === MgmtViewsEnum.COMBINE_FEE_TYPE) {
      setViewState((prevState) => ({
        ...prevState,
        viewType: prevState.viewData?.viewType as MgmtViewsEnum,
        showMore: true,
      }));
      return;
    }
    setViewState((prevState) => ({
      ...prevState,
      viewType: prevState.viewData?.viewType as MgmtViewsEnum,
      showMore: false,
    }));
  };

  const onSubmitHowToPayCombineFee = (value: HowToPayEnum, data?: ObjectType) => {
    if (value === HowToPayEnum.CARD && hasCard) {
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: {
          addCardRef: {
            ...(addCardRef as ObjectType),
            howToPay: HowToPayEnum.CARD,
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          },
        },
      });

      setViewState((prevState) => ({
        ...prevState,
        viewType: MgmtViewsEnum.CONFIRMATION,
        showMore: true,
      }));
    }

    if (value === HowToPayEnum.BANK_TRANSFER) {
      const { type: feeType, dbKey } = addCardRef as { type: string; dbKey: string };
      if (feeType && dbKey)
        updatePreferenceMutation({
          request: {
            managementFeePayment: capitalizeFirstLetter(feeType),
            annualManagementFeePayType: capitalizeFirstLetter(HowToPayEnum.BANK_TRANSFER),
            historicManagementFeePayType: capitalizeFirstLetter(HowToPayEnum.BANK_TRANSFER),
          },
        });

      setViewState((prevState) => ({
        ...prevState,
        viewData: {
          howToPay: HowToPayEnum.BANK_TRANSFER,
        },
        viewType: MgmtViewsEnum.CONFIRMATION,
        showBanner: false,
        showMore: true,
      }));
    }
    if (value === HowToPayEnum.CHARGE_AND_SAVE_CARD) {
      setViewState((prevState) => ({
        ...prevState,
        viewType: MgmtViewsEnum.CHARGE_AND_SAVE_CARD,
        showBanner: true,
        viewData: { viewType: prevState.viewType, ...data },
        showMore: true,
      }));
    }
  };

  const onSubmitHowToPayMgmtFee = (value: HowToPayEnum, data?: ObjectType) => {
    if (value === HowToPayEnum.CARD && hasCard) {
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: {
          addCardRef: {
            ...(addCardRef as ObjectType),
            howToPay: HowToPayEnum.CARD,
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          },
        },
      });

      setViewState((prevState) => ({
        ...prevState,
        viewType: MgmtViewsEnum.CONFIRMATION,
        showMore: true,
      }));
    }

    if (value === HowToPayEnum.BANK_TRANSFER) {
      const { type: feeType, dbKey } = addCardRef as { type: string; dbKey: string };
      if (feeType && dbKey)
        updatePreferenceMutation({
          request: {
            [dbKey]: capitalizeFirstLetter(feeType),
            annualManagementFeePayType: capitalizeFirstLetter(HowToPayEnum.BANK_TRANSFER),
          },
        });

      setViewState((prevState) => ({
        ...prevState,
        viewData: {
          howToPay: HowToPayEnum.BANK_TRANSFER,
        },
        viewType: MgmtViewsEnum.CONFIRMATION,
        showBanner: false,
        showMore: true,
      }));
    }
    if (value === HowToPayEnum.CHARGE_AND_SAVE_CARD) {
      setViewState((prevState) => ({
        ...prevState,
        viewType: MgmtViewsEnum.CHARGE_AND_SAVE_CARD,
        showBanner: true,
        viewData: { viewType: prevState.viewType, ...data },
        showMore: true,
      }));
    }
  };

  const onSubmitHowToPayLegacyFee = (value: HowToPayEnum, data?: ObjectType) => {
    if (
      (hasCard && value === HowToPayEnum.CARD) ||
      value === HowToPayEnum.BANK_TRANSFER ||
      value === HowToPayEnum.CASH_ON_ACCOUNT ||
      value === HowToPayEnum.FUTURE_SALES
    ) {
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: {
          addCardRef: {
            ...(addCardRef as AddCardRefType),
            type: value,
            howToPay: value,
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
          },
        },
      });
    }

    if (value === HowToPayEnum.CHARGE_AND_SAVE_CARD) {
      setViewState((prevState) => ({
        ...prevState,
        viewType: MgmtViewsEnum.CHARGE_AND_SAVE_CARD,
        showBanner: true,
        viewData: { viewType: prevState.viewType, ...data },
        showMore: true,
      }));
    }
  };

  const onOpenCombineFeeSelector = () => {
    setViewState((prevState) => ({ ...prevState, viewType: MgmtViewsEnum.COMBINE_FEE_TYPE, showMore: true }));
    if (showAddCardModal) onOpenCloseAddCardModal(false);
  };

  const onOpenMgmtFeeSelector = () => {
    setViewState((prevState) => ({ ...prevState, showMore: true }));
    if (showAddCardModal) onOpenCloseAddCardModal(false);
  };

  const onOpenLegacyFeeSelector = () => {
    setViewState((prevState) => ({ ...prevState, viewType: MgmtViewsEnum.LEGACY_FEE_TYPE, showMore: true }));
    if (showAddCardModal) onOpenCloseAddCardModal(false);
  };

  const onSessionLogout = async () => {
    queryClient.clear();
    // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
    await client.clearStore();
    client.cache.gc();
    signOut();
    isLoggedInVar(false);
    navigate(NavigationPath.LOGIN);
  };

  const howToPayMgmtFeeOptions = useMemo(() => {
    if (!estimatedManagementFeeResponse) return [];
    const { monthlyFeeAmount, annualFeeAmount } = estimatedManagementFeeResponse as EstimatedManagementFeeResponse;

    if (monthlyFeeAmount <= 0 && canCollectPreference) {
      setCanCollectPreference(false);
      return [];
    }
    if (monthlyFeeAmount > 0 && !canCollectPreference) {
      setCanCollectPreference(true);
    }

    const payMgmtFeeOptions = [
      {
        id: HowToPayEnum.CARD,
        label: capitalizeFirstLetter(HowToPayEnum.CARD),
        value: HowToPayEnum.CARD,
        description: (
          <span className=" flex space-x-2">
            {/* <span className="">{`Save ${discountPct}%`}</span> */}
            {/* <span className="line-through  text-red"> {formatter.format(annualFeeAmount)}</span> */}
            <span className="font-medium ">{formatter.format(annualFeeAmount)}</span>
          </span>
        ),
        data: annualFeeAmount,

        // description: <span className="font-medium ">{formatter.format(annualFeeAmount)}</span>,
        // data: annualFeeAmount,
      },
    ] as RadioBtnOption[];

    if (annualFeeAmount > bankTransferMinAmount) {
      payMgmtFeeOptions.push({
        id: HowToPayEnum.BANK_TRANSFER,
        label: 'Bank transfer',
        value: HowToPayEnum.BANK_TRANSFER,
        data: annualFeeAmount,
        description: (
          <span className="flex space-x-2">
            {/* <span className="">{`Save ${discountPct}%`}</span> */}
            {/* <span className="line-through  text-red"> {formatter.format(annualFeeAmount)}</span> */}
            <span className="font-medium ">{formatter.format(annualFeeAmount)}</span>
          </span>
        ),

        // data: annualFeeAmount,
        // description: <span className="font-medium ">{formatter.format(annualFeeAmount)}</span>,
      });
    }

    return payMgmtFeeOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedManagementFeeResponse, bankTransferMinAmount]);

  const legacyFeeAmount: number = useMemo(() => {
    if (testMode) return totalMgmtFees as number;
    if (!totalMgmtFeeResponse) return 0;
    return (totalMgmtFeeResponse as { totalOutstandingFeesAmount: number }).totalOutstandingFeesAmount;
  }, [totalMgmtFeeResponse, testMode, totalMgmtFees]);

  const howToPayLegacyFeeOptions = useMemo(() => {
    const legacyFeeOptions = [
      {
        id: HowToPayEnum.CARD,
        label: capitalizeFirstLetter(HowToPayEnum.CARD),
        value: HowToPayEnum.CARD,
        description: <span>{formatter.format(legacyFeeAmount)}</span>,
        data: legacyFeeAmount,
      },
    ] as RadioBtnOption[];

    if (legacyFeeAmount > bankTransferMinAmount) {
      legacyFeeOptions.push({
        id: HowToPayEnum.BANK_TRANSFER,
        label: 'Bank transfer',
        value: HowToPayEnum.BANK_TRANSFER,
        description: <span>{formatter.format(legacyFeeAmount)}</span>,
        data: legacyFeeAmount,
      });
    }

    if ((balances?.total ?? 0) > legacyFeeAmount) {
      legacyFeeOptions.push({
        id: HowToPayEnum.CASH_ON_ACCOUNT,
        label: 'Use available balance',
        value: HowToPayEnum.CASH_ON_ACCOUNT,
        description: <span>{formatter.format(legacyFeeAmount)}</span>,
        data: legacyFeeAmount,
      });
    }

    // legacyFeeOptions.push({
    //   id: HowToPayEnum.FUTURE_SALES,
    //   label: 'Use future sales',
    //   value: HowToPayEnum.FUTURE_SALES,
    //   description: <span>{formatter.format(legacyFeeAmount)}</span>,
    //   data: legacyFeeAmount,
    // });

    return legacyFeeOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legacyFeeAmount, balances, bankTransferMinAmount]);

  const onCloseLegacyFeeBanner = () => {
    setViewState({ ...viewState, showMore: false });
    dispatch({
      type: SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS,
      payload: {
        isDismissed: true,
      },
    });
  };

  const onCloseChargeAndSave = () => {
    if (viewState.viewData?.onBackClick === MgmtViewsEnum.COMBINE_FEE_TYPE) {
      setViewState((prevState) => ({
        ...prevState,
        viewType: MgmtViewsEnum.COMBINE_FEE_TYPE,
        viewData: undefined,
        showMore: true,
      }));
      return;
    }
    setViewState({
      ...viewState,
      viewType: (viewState?.viewData as { viewType: MgmtViewsEnum }).viewType,
      viewData: undefined,
    });
  };
  useEffect(() => {
    if (fullname) {
      const name = fullname.split(' ');
      setLoginUserInitials(`${name[0].charAt(0).toUpperCase()}${name[name.length - 1].charAt(0).toUpperCase()}`);
    }
  }, [fullname]);

  useEffect(() => {
    if (fetchedNotifications) {
      const notificationResponse = fetchedNotifications as NotificationResponse;
      if (notificationResponse.results && notificationResponse.results?.length > 0) {
        const notifications = notificationResponse.results as NotificationType[];
        const unReadNotifications = notifications.find((x) => !x.isRead);
        const isNotifications = !!unReadNotifications;

        dispatch({
          type: AppEventTypes.UPDATE_STATE,
          payload: {
            hasNotifications: isNotifications,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedNotifications]);

  useEffect(() => {
    if (refresh && (refresh as string[]).length > 0 && (refresh as string[]).includes(RefreshRegion.HEADER)) {
      if (refetchCashBalances) refetchCashBalances();
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: { refresh: (refresh as string[]).filter((x) => x !== RefreshRegion.HEADER) },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (isTimerEnabled) {
      return;
    }

    if (isLoadingPortalClientPreferences || combineFeeFlows.isLoading) return;
    if (
      showBankTransferReminder ||
      viewState.viewType === MgmtViewsEnum.ADD_CARD_FAILED ||
      viewState.viewType === MgmtViewsEnum.CHARGE_AND_SAVE_CARD_FAILED
    ) {
      return;
    }

    // // here
    const preferences = portalClientPreferences as PortalClientPreferencesType;
    const accruedFeeBool = preferences?.portalClientPreferences.historicManagementFeePayType;
    const mgmtFeeBool = preferences?.portalClientPreferences.managementFeePayment;

    if (!mgmtFeeBool && !accruedFeeBool && combineFeeFlows.combine && legacyFeeAmount > 0) {
      // show combine fee model
      const policy = mgmtFeesPolicy as ManagementFeePolicy;
      const { endDate, type: mgmtFeeType } = policy;
      const forceAction = !mgmtFeeType && Boolean(!!endDate && moment(endDate).isBefore(moment()));
      const loadOnit = (!mgmtFeeType && mgmtFeesPolicy.loadOnInit) || forceAction;
      setViewState({
        showBanner: true,
        forceAction: forceAction,
        bannerType: BannerTypeEnum.COMBINE_FEES,
        showMore: loadOnit || forceAction,
        viewType: MgmtViewsEnum.COMBINE_FEE_TYPE,
      });
      if (mgmtFeesPolicy.loadOnInit) {
        updateMgmFeeSetting({
          loadOnInit: false,
        });
      }
      return;
    }

    if (
      mgmtFeesPolicy.timestamp &&
      viewState.viewType !== MgmtViewsEnum.LEGACY_FEE_TYPE &&
      viewState.viewType !== MgmtViewsEnum.COMBINE_FEE_TYPE &&
      viewState.viewType !== MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE &&
      !combineFeeFlows.isSuccess
    ) {
      const policy = mgmtFeesPolicy as ManagementFeePolicy;
      const { startDate, endDate, type: mgmtFeeType } = policy;
      const forceAction = !mgmtFeeType && Boolean(!!endDate && moment(endDate).isBefore(moment()));
      const showBanner =
        !exemptFromAllFees &&
        !mgmtFeeType &&
        (forceAction ||
          Boolean(
            !!startDate && !!endDate && moment(startDate).isSameOrBefore(moment()) && moment(endDate).isAfter(moment()),
          ));
      const loadOnit = (!mgmtFeeType && mgmtFeesPolicy.loadOnInit) || forceAction;
      setViewState({
        showBanner,
        forceAction,
        bannerType: BannerTypeEnum.MGMT_FEE,
        showMore: loadOnit || forceAction,
        viewType: MgmtViewsEnum.MGMT_FEE_TYPE,
      });

      if (mgmtFeesPolicy.loadOnInit) {
        updateMgmFeeSetting({
          loadOnInit: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mgmtFeesPolicy.endDate,
    mgmtFeesPolicy.startDate,
    mgmtFeesPolicy.timestamp,
    showAddCardModal,
    exemptFromAllFees,
    portalClientPreferences,
    isLoadingPortalClientPreferences,
    combineFeeFlows,
    legacyFeeAmount,
  ]);

  // here 2
  useEffect(() => {
    const { timestamp: acTimestamp, type: feeType, token, dbKey, howToPay } = addCardRef as AddCardRefType;

    if (token === MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE && acTimestamp && feeType && !!dbKey) {
      onConfirmMgmtFeeType(feeType, dbKey, howToPay);
      if (!hasCard) {
        dispatch({
          type: AppEventTypes.UPDATE_STATE,
          payload: { hasCard: true },
        });
      }
    }

    if (token === MgmtViewsEnum.LEGACY_FEE_TYPE && acTimestamp) {
      onConfirmLegacyFeeType(feeType, dbKey);
    }

    if (token === MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE && acTimestamp && feeType && !!dbKey) {
      onConfirmCombineFeeType(feeType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCardRef]);

  useEffect(() => {
    if (userPreferenceResponse) {
      const usrSetttings = (userPreferenceResponse as { portalUpdatePreferences: UserSettings })
        .portalUpdatePreferences;
      updateMgmFeeSetting({
        type: !usrSetttings.managementFeePayment ? '' : (usrSetttings.managementFeePayment as string).toLowerCase(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferenceResponse]);

  useEffect(() => {
    if (isTimerEnabled) {
      return;
    }

    if (showBankTransferReminder) {
      setViewState({
        showBanner: false,
        forceAction: false,
        bannerType: BannerTypeEnum.MGMT_FEE,
        showMore: true,
        viewType: MgmtViewsEnum.PAYMENT_REMINDER,
      });
      return;
    }

    if (
      viewState.viewType === MgmtViewsEnum.ADD_CARD_FAILED ||
      viewState.viewType === MgmtViewsEnum.CHARGE_AND_SAVE_CARD_FAILED
    ) {
      return;
    }

    if (viewState.viewType === MgmtViewsEnum.CONFIRMATION && viewState.showMore) {
      return;
    }

    const { endDate, timestamp, howToPay } = legacyFeesPolicy;
    const isExpired = !!endDate && moment(endDate).isBefore(moment());

    const showLegacyBanner =
      !exemptFromAllFees &&
      legacyFeeAmount > 0 &&
      !!mgmtFeesPolicy.type &&
      !!mgmtFeesPolicy.howToPay &&
      !howToPay &&
      !isExpired &&
      !!timestamp &&
      !showAddCardModal;

    if (showLegacyBannerHandle && !showLegacyBanner) {
      clearTimeout(showLegacyBannerHandle);
      setShowLegacyBannerHandle(null);
    }
    if (showLegacyBanner) {
      const forceAction = !howToPay && Boolean(!!endDate && moment(endDate).isBefore(moment()));
      // const showBanner =
      //   !!mgmtFeesPolicy.type && !howToPay && Boolean(!!endDate && moment(endDate).isSameOrAfter(moment()));
      const _showBannerHandle = setTimeout(() => {
        dispatch({
          type: AppEventTypes.UPDATE_STATE,
          payload: {
            addCardRef: {
              dbKey: 'historicManagementFeePayType',
              type: '',
              token: MgmtViewsEnum.LEGACY_FEE_TYPE,
              timestamp: '',
            },
          },
        });

        setViewState({
          showBanner: true,
          forceAction,
          bannerType: BannerTypeEnum.LEGACY,
          showMore: false,
          viewType: MgmtViewsEnum.LEGACY_FEE_TYPE,
        });
      }, 3000);

      setShowLegacyBannerHandle(_showBannerHandle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    legacyFeeAmount,
    legacyFeesPolicy.howToPay,
    legacyFeesPolicy.endDate,
    legacyFeesPolicy.timestamp,
    showAddCardModal,
    mgmtFeesPolicy.type,
    mgmtFeesPolicy.howToPay,
    exemptFromAllFees,
    showBankTransferReminder,
  ]);

  useEffect(() => {
    setShowBankTransferReminder(remindToPayBankTransfer as boolean);
  }, [remindToPayBankTransfer]);

  useEffect(() => {
    const { timestamp: acTimestamp, token } = addCardRef as AddCardRefType;
    if (token === MgmtViewsEnum.LEGACY_FEE_TYPE && !acTimestamp && !showAddCardModal) {
      setViewState((prevState) => ({
        ...prevState,
        showMore: false,
        showBanner: true,
        viewType: MgmtViewsEnum.LEGACY_FEE_TYPE,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAddCardModal]);

  const processAddCardResponse = (hashValue: string, isAddCardSuccess: boolean, isAddCardFail: boolean) => {
    const savedAddCardRefString = atob(
      decodeURIComponent(hashValue.replace(isAddCardSuccess ? '#success=' : '#failure=', '')),
    );
    if (savedAddCardRefString) {
      const savedAddCardRef = JSON.parse(savedAddCardRefString);
      window.history.replaceState({}, document.title, window.location.pathname);
      // window.history.replaceState(null, '', window.location.pathname);

      if (savedAddCardRef.token === MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE && isAddCardSuccess) {
        updatePreferenceMutation({
          request: {
            managementFeePayment: capitalizeFirstLetter(savedAddCardRef.type),
            annualManagementFeePayType: capitalizeFirstLetter(HowToPayEnum.CARD),
          },
        });
      }
      if (isAddCardSuccess) {
        setTimeout(
          () => {
            if (savedAddCardRef.token === MgmtViewsEnum.LEGACY_FEE_TYPE) {
              savedAddCardRef.type = HowToPayEnum.CARD;
              dispatch({
                type: SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS,
                payload: {
                  howToPay: HowToPayEnum.CARD,
                  timestamp: '',
                },
              });
            }
            dispatch({
              type: AppEventTypes.UPDATE_STATE,
              payload: {
                addCardRef: {
                  ...savedAddCardRef,
                  timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
                  pid: '',
                },
              },
            });
          },
          1000,
          savedAddCardRef,
        );
      }

      if (isAddCardFail) {
        logError(JSON.stringify({ message: 'Add Card Fail', error: { ...savedAddCardRef } }), {
          tags: { userFlow: 'pay_fee_preferences' },
        });

        setViewState({
          showBanner: true,
          forceAction: false,
          viewData: {
            viewType:
              savedAddCardRef.token === MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE
                ? MgmtViewsEnum.MGMT_FEE_TYPE
                : MgmtViewsEnum.LEGACY_FEE_TYPE,
          },
          bannerType:
            savedAddCardRef.token === MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE
              ? BannerTypeEnum.MGMT_FEE
              : BannerTypeEnum.LEGACY,
          viewType: MgmtViewsEnum.ADD_CARD_FAILED,
          showMore: true,
        });
      }
    }
  };

  // here 1
  const processChargeCardResponse = (hashValue: string, isSuccess: boolean, isFailure: boolean) => {
    const savedCardRefString = atob(
      decodeURIComponent(
        hashValue.replace(
          isSuccess
            ? `#${ChargeAndSaveCardEnum.onChargeCardSuccess}=`
            : `#${ChargeAndSaveCardEnum.onChargeCardFailure}=`,
          '',
        ),
      ),
    );
    if (savedCardRefString) {
      const savedAddCardRef = JSON.parse(savedCardRefString);
      window.history.replaceState({}, document.title, window.location.pathname);
      if (savedAddCardRef.token === MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE && isSuccess) {
        updatePreferenceMutation({
          request: {
            managementFeePayment: capitalizeFirstLetter(savedAddCardRef.type),
            annualManagementFeePayType: capitalizeFirstLetter(HowToPayEnum.CARD),
          },
        });
      }
      if (savedAddCardRef.token === MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE && isSuccess) {
        updatePreferenceMutation(
          {
            request: {
              managementFeePayment: capitalizeFirstLetter(savedAddCardRef.type),
              annualManagementFeePayType: capitalizeFirstLetter(HowToPayEnum.CARD),
              historicManagementFeePayType: capitalizeFirstLetter(HowToPayEnum.CARD),
            },
          },
          { fetchPolicy: 'network-only' },
        );
      }
      if (isSuccess) {
        setTimeout(
          () => {
            if (savedAddCardRef.token === MgmtViewsEnum.LEGACY_FEE_TYPE) {
              savedAddCardRef.type = HowToPayEnum.CARD;
              dispatch({
                type: SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS,
                payload: {
                  howToPay: HowToPayEnum.CARD,
                  timestamp: '',
                },
              });
            }

            if (savedAddCardRef.token === MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE) {
              savedAddCardRef.type = HowToPayEnum.CARD;
              dispatch({
                type: SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS,
                payload: {
                  howToPay: HowToPayEnum.CARD,
                  timestamp: '',
                },
              });
            }
            dispatch({
              type: AppEventTypes.UPDATE_STATE,
              payload: {
                addCardRef: {
                  ...savedAddCardRef,
                  timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
                  pid: '',
                },
              },
            });
          },
          1000,
          savedAddCardRef,
        );
      }

      if (isFailure) {
        logError(JSON.stringify({ message: 'Add Card Fail', error: { ...savedAddCardRef } }), {
          tags: { userFlow: 'pay_fee_preferences' },
        });

        const getViewAndBannerType = () => {
          switch (savedAddCardRef.token) {
            case MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE:
              return { viewType: MgmtViewsEnum.MGMT_FEE_TYPE, bannerType: BannerTypeEnum.MGMT_FEE };
            case MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE:
              setcombineFeeFlows((prev) => ({ ...prev, isLoading: false, combine: false }));
              return { viewType: MgmtViewsEnum.COMBINE_FEE_TYPE, bannerType: BannerTypeEnum.COMBINE_FEES };
            default:
              return { viewType: MgmtViewsEnum.LEGACY_FEE_TYPE, bannerType: BannerTypeEnum.LEGACY };
          }
        };
        const viewAndBannerType = getViewAndBannerType();

        setViewState({
          showBanner: true,
          forceAction: false,
          viewData: {
            viewType: viewAndBannerType.viewType,
          },
          bannerType: viewAndBannerType.bannerType,
          viewType: MgmtViewsEnum.CHARGE_AND_SAVE_CARD_FAILED,
          showMore: true,
        });
      }
    }
  };

  useEffect(() => {
    const hashValue = window.location.hash;
    const isAddCardSuccess = Boolean(hashValue && hashValue.startsWith('#success='));
    const isAddCardFail = Boolean(hashValue && hashValue.startsWith('#failure='));
    const isChargeCardSuccess = Boolean(
      hashValue && hashValue.startsWith(`#${ChargeAndSaveCardEnum.onChargeCardSuccess}=`),
    );
    const isChargeCardFailure = Boolean(
      hashValue && hashValue.startsWith(`#${ChargeAndSaveCardEnum.onChargeCardFailure}=`),
    );
    if (isAddCardSuccess || isAddCardFail) {
      processAddCardResponse(window.location.hash, isAddCardSuccess, isAddCardFail);
    }

    if (isChargeCardSuccess || isChargeCardFailure) {
      processChargeCardResponse(window.location.hash, isChargeCardSuccess, isChargeCardFailure);
    } else {
      setcombineFeeFlows((prev) => ({ ...prev, isLoading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  useEffect(() => {
    if (updatePreferenceError) {
      logError(updatePreferenceError, {
        tags: {
          userFlow: 'pay_fee_preferences',
          details: JSON.stringify({
            email: email,
            mgmtFeesPolicy,
            legacyFeesPolicy,
            notes: 'Update Preference GraphQL Error',
          }),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePreferenceError]);

  useEffect(() => {
    if (isTimerEnabled && minutes + seconds <= 0) {
      setIsTimerEnabled(false);
      setTimeout(() => {
        dispatch({
          type: AppEventTypes.UPDATE_STATE,
          payload: {
            refetchPreferences: true,
          },
        });
      }, 100);
    }
    if (!isTimerEnabled && minutes + seconds > 0) {
      setIsTimerEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, seconds]);

  const annualAndAccruedFee = useMemo(() => {
    if (isloadingEstimatedManagementFeeResponse) return 0;
    const { annualFeeAmount } = estimatedManagementFeeResponse as EstimatedManagementFeeResponse;
    return annualFeeAmount + legacyFeeAmount;
  }, [estimatedManagementFeeResponse, isloadingEstimatedManagementFeeResponse, legacyFeeAmount]);

  const mgmtFeeAndAccruedOptions = useMemo(() => {
    const { monthlyFeeAmount, annualFeeAmount } = estimatedManagementFeeResponse as EstimatedManagementFeeResponse;

    const feePaymentPlanOptions = [
      {
        id: MgmtFeeTypeEnum.MONTHLY,
        label: capitalizeFirstLetter(MgmtFeeTypeEnum.MONTHLY),
        value: MgmtFeeTypeEnum.MONTHLY,
        data: monthlyFeeAmount,
        description: (
          <span className=" text-sm space-y-2  flex flex-col">
            <span> {t('account:mgmtFeePreference.optionModal.payMonthtly')}</span>
            <span className="flex space-x-2">{`${currencyFormatter.format(monthlyFeeAmount)} per month`}</span>
            <span className="flex space-x-2 italic text-xs">
              {t('account:mgmtFeePreference.optionModal.payMonthtlyExplainer')}
            </span>
          </span>
        ),
      },
      {
        id: MgmtFeeTypeEnum.YEARLY,
        label: 'Annually',
        value: MgmtFeeTypeEnum.YEARLY,
        data: annualFeeAmount,
        description: (
          <span className="flex flex-col  space-y-2">
            <span className="mr-1">{t('account:feePolicyEnforcerTemplate.mgmtFee&AccruedFee.payAnnually')}</span>
            <span className="font-medium ">{currencyFormatter.format(annualFeeAmount, true)}</span>
            <span className="flex  italic text-xs">
              {t('account:mgmtFeePreference.optionModal.payAnnuallyExplainer')}
            </span>
          </span>
        ),
      },
    ] as RadioBtnOption[];

    return feePaymentPlanOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatedManagementFeeResponse, annualAndAccruedFee]);

  const howToPayMgmtFeeAndAccruedOptions = useMemo(() => {
    const options = [
      {
        id: HowToPayEnum.CARD,
        label: capitalizeFirstLetter(HowToPayEnum.CARD),
        value: HowToPayEnum.CARD,
        description: (
          <span className=" flex space-x-2">
            <span className="font-medium ">{formatter.format(annualAndAccruedFee)}</span>
          </span>
        ),
        data: annualAndAccruedFee,
      },
    ] as RadioBtnOption[];

    if (annualAndAccruedFee > bankTransferMinAmount) {
      options.push({
        id: HowToPayEnum.BANK_TRANSFER,
        label: 'Bank transfer',
        value: HowToPayEnum.BANK_TRANSFER,
        data: annualAndAccruedFee,
        description: (
          <span className="flex space-x-2">
            <span className="font-medium ">{formatter.format(annualAndAccruedFee)}</span>
          </span>
        ),
      });
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankTransferMinAmount, annualAndAccruedFee]);

  return (
    <>
      <div className="flex relative overflow-hidden">
        <SharedSideBar value={view} onClick={onSideBarIconSelect} isSmallScreen={false} />
        {showMenu && (
          <SharedSideBar
            value={view}
            onClick={onSideBarIconSelect}
            isSmallScreen={true}
            onClose={() => setShowMenu(false)}
          />
        )}
        <div
          className={`flex flex-col flex-1  h-screen rounded-tl-lg ml-0 sm:-ml-5 overflow-hidden pb-5 bg-white ${
            className || ''
          } `.trim()}
        >
          {!!testMode && testMode && (
            <div className="bg-white w-full flex justify-center text-red font-medium text-lg">Test Mode</div>
          )}

          {enforceMgmtFeeRules &&
            canCollectPreference &&
            viewState.showBanner &&
            (viewState.viewType === MgmtViewsEnum.COMBINE_FEE_TYPE ||
              viewState.viewType === MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE) && (
              <MgmtFeeBanner
                title={<h3 className="text-lg font-medium"> {t('account:feePolicyEnforcerTemplate.banner.title')}</h3>}
                onOpen={onOpenCombineFeeSelector}
                subTitle={null}
              />
            )}

          {enforceMgmtFeeRules &&
            canCollectPreference &&
            viewState.showBanner &&
            (viewState.viewType === MgmtViewsEnum.MGMT_FEE_TYPE ||
              viewState.viewType === MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE) && (
              <MgmtFeeBanner onOpen={onOpenMgmtFeeSelector} />
            )}

          {enforceMgmtFeeRules &&
            canCollectPreference &&
            legacyFeeAmount > 0 &&
            viewState.showBanner &&
            (viewState.viewType === MgmtViewsEnum.LEGACY_FEE_TYPE ||
              viewState.viewType === MgmtViewsEnum.LEGACY_FEE_PAYMENT_TYPE) &&
            !disableAccruedFeeModal &&
            accountInfo &&
            (accountInfo?.account?.totalCashOnAccount ?? 0) <= 0 && (
              <LegacyFeeBanner onOpen={onOpenLegacyFeeSelector} />
            )}
          <div className="flex items-center shadow-[0_8px_16px_0px_rgba(0,0,0,0.08)]">
            <div className="flex items-center  m-5">
              {showBackButton && <BackArrow className="mr-3 cursor-pointer" onClick={onBackHandler} />}
              <HamburgerIcon className="sm:hidden mr-3 cursor-pointer" onClick={() => onOpenMenu()} />

              <h5 className="sm:text-md">{title}</h5>
            </div>
            <div className="flex flex-1 items-center justify-end space-x-5 pr-5">
              {managementFeeUpdates14758 && (
                <Dropdown
                  itemsWrapperClassName="right-0 mt-2"
                  itemClassName="py-5 text-base"
                  className="flex-1 text-sm sm:text-base whitespace-nowrap p-0 justify-start h-8"
                  header={
                    <div className="flex flex-col w-fit min-w-[240px] p-3 space-y-2">
                      {portfolioDropdownOptions.map((balanceItem) => {
                        return balanceItem.content;
                      })}
                    </div>
                  }
                  footer={
                    <div className="flex justify-center items-center p-5">
                      <Button
                        className="btn text-14 align-bottom whitespace-nowrap font-normal bg-orange rounded-full mt-3 w-fit px-5 text-black"
                        onClick={() => openSlideout(SlideOutPanelViews.TOP_UP)}
                        props={{
                          name: 'acc_topup',
                        }}
                      >
                        {t('account:text.topup')}
                      </Button>
                    </div>
                  }
                >
                  <div className="flex items-center" onClick={() => setIsShowBalances(!isShowBalances)}>
                    <PortfolioCashBalance
                      formatter={formatter}
                      portfolioCashBalance={balances.total}
                      loading={loadingCashBalances}
                    >
                      <div className="ml-5">
                        {!isShowBalances && <ChevronDown className="" aria-hidden="true" />}
                        {isShowBalances && <ChevronUp className="" aria-hidden="true" />}
                      </div>
                    </PortfolioCashBalance>
                  </div>
                </Dropdown>
              )}
              <div onClick={openNotifications} className="relative cursor-pointer">
                {!!hasNotifications && <div className="rounded-full h-3 w-3 bg-orange absolute -right-1" />}
                <NotificationIcon />
              </div>
              <Dropdown
                itemsWrapperClassName="right-0 mt-2"
                itemClassName="py-5 text-base"
                className="flex-1 text-sm sm:text-base whitespace-nowrap p-0 justify-start h-8"
                header={
                  <div className="flex flex-col w-fit mt-1 p-3 space-y-2">
                    <div onClick={onSessionLogout} className="w-full flex cursor-pointer text-sm">
                      {t('auth:logout')}
                    </div>
                  </div>
                }
              >
                <div className="rounded-full flex items-center justify-center bg-orange w-[40px] h-[40px]">
                  {loginUserInitials}
                </div>
              </Dropdown>
            </div>
          </div>
          <div className={`flex-1 flex flex-col h-100  overflow-y-auto  ${containerClassName || ''}`.trim()}>
            {children || null}
          </div>
        </div>
      </div>

      {enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.COMBINE_FEE_TYPE && (
        <FeePolicyEnforcerTemplate
          eventType={MgmtViewsEnum.COMBINE_FEE_TYPE}
          accruedFee={legacyFeeAmount}
          options={mgmtFeeAndAccruedOptions}
          forceAction={viewState.forceAction}
          show={viewState.showMore}
          onSubmit={onCombineFeeTypeSubmit}
          onClose={() => setViewState({ ...viewState, showMore: false })}
        />
      )}

      {enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE && (
        <HowToPayModal
          options={howToPayMgmtFeeAndAccruedOptions}
          forceAction={true}
          eventType={SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS}
          isOpen={viewState.showMore}
          propKey="howToPay"
          dbKey=""
          onClose={() => {
            setViewState({ ...viewState, viewType: MgmtViewsEnum.COMBINE_FEE_PAYMENT_TYPE, showMore: false });
          }}
          onSubmit={onSubmitHowToPayCombineFee}
        />
      )}

      {enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.MGMT_FEE_TYPE && (
        <MgmFeePolicyEnforcer
          estimatedFeeAmount={estimatedManagementFeeResponse as EstimatedManagementFeeResponse}
          forceAction={viewState.forceAction}
          show={viewState.showMore}
          onSubmit={onMgmtFeeTypeSubmit}
          onClose={() => setViewState({ ...viewState, showMore: false })}
        />
      )}

      {enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.MGMT_FEE_PAYMENT_TYPE && (
        <HowToPayModal
          options={howToPayMgmtFeeOptions}
          forceAction={true}
          eventType={SettingsEventTypes.UPDATE_MGMT_FEE_SETTINGS}
          isOpen={viewState.showMore}
          propKey="howToPay"
          dbKey=""
          onClose={() => {
            setViewState({ ...viewState, viewType: MgmtViewsEnum.MGMT_FEE_TYPE, showMore: false });
          }}
          onSubmit={onSubmitHowToPayMgmtFee}
        />
      )}

      {enforceMgmtFeeRules &&
        viewState.viewType === MgmtViewsEnum.LEGACY_FEE_TYPE &&
        !disableAccruedFeeModal &&
        accountInfo &&
        (accountInfo?.account?.totalCashOnAccount ?? 0) <= 0 && (
          <FeePolicyEnforcerTemplate
            eventType={MgmtViewsEnum.LEGACY_FEE_TYPE}
            accruedFee={legacyFeeAmount}
            forceAction={viewState.forceAction}
            hasOptions={false}
            show={viewState.showMore}
            onSubmit={onLegacyFeeTypeSubmit}
            onClose={() => setViewState({ ...viewState, showMore: false })}
          />
        )}

      {enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.LEGACY_FEE_PAYMENT_TYPE && (
        <HowToPayModal
          options={howToPayLegacyFeeOptions}
          amount={legacyFeeAmount}
          forceAction={viewState.forceAction}
          eventType={SettingsEventTypes.UPDATE_LEGACY_FEE_SETTINGS}
          isOpen={viewState.showMore}
          propKey="howToPay"
          dbKey=""
          onClose={onCloseLegacyFeeBanner}
          onSubmit={onSubmitHowToPayLegacyFee}
        />
      )}

      {enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.CHARGE_AND_SAVE_CARD && (
        <ChargeAndSaveCard
          title="Make Payment"
          show={true}
          amount={Number(viewState.viewData?.amount ?? 0)}
          onClose={onCloseChargeAndSave}
          transactionType={viewState.viewData?.viewType as string}
        />
      )}

      {enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.CONFIRMATION && (
        <PreferenceConfirmation
          body={
            viewState.bannerType === BannerTypeEnum.MGMT_FEE ? (
              <div>{t('account:mgmtFeePreference.confirmText')}</div>
            ) : (
              <div>{t('account:accruedFeePreference.confirmText')}</div>
            )
          }
          show={viewState.showMore}
          footer={
            <div className="w-full flex justify-center mt-3">
              <Button className="btn-primary" onClick={() => onCloseConfirmation()}>
                {t('common:generalSuccess.buttonText')}
              </Button>
            </div>
          }
        />
      )}

      {((enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.ADD_CARD_FAILED) ||
        (enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.CHARGE_AND_SAVE_CARD_FAILED)) && (
        <PreferenceConfirmation
          body={<div>{t('account:payments.addCard.3DSecureFailure')}</div>}
          show={viewState.showMore}
          footer={
            <div className="w-full flex justify-center mt-3">
              <Button className="btn-primary" onClick={() => onCloseCardProcessError()}>
                {t('common:generalSuccess.buttonText')}
              </Button>
            </div>
          }
        />
      )}

      {enforceMgmtFeeRules && viewState.viewType === MgmtViewsEnum.PAYMENT_REMINDER && (
        <HowToPayModal
          options={howToPayMgmtFeeOptions}
          forceAction={true}
          eventType={SettingsEventTypes.PAYMENT_REMINDER}
          isOpen={viewState.showMore}
          propKey="howToPay"
          dbKey=""
          onClose={() => {
            setShowBankTransferReminder(false);
            setViewState({ ...viewState, viewType: MgmtViewsEnum.MGMT_FEE_TYPE, showMore: false });
          }}
          onSubmit={onSubmitHowToPayMgmtFee}
        />
      )}
    </>
  );
};

export default ProtectedPageLayout;
