import { Document, Font, Image, Link, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import pdfLogo from '../../assets/images/pdfLogo.jpg';
import { IProformaReport, ProformaReportHashValueEnum } from './types';

interface TextInfoType {
  rightText?: string;
  leftText?: string;
}

// interface UserInfoType {
//   customerNo: string;
//   documentDate: string;
//   customerRef: string;
//   contactPerson: string;
//   salesContact: string;
//   page: string;
// }
// Create styles

// Register font

Font.register({
  family: 'hi',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  fontWeight: 'bold',
});

const styles = StyleSheet.create({
  headingCell: { fontSize: 10, padding: 5, fontFamily: 'Times-Bold' },
  page: {
    backgroundColor: 'white',
    paddingHorizontal: 40,
    paddingVertical: 35,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  section: {},
  logo: {
    // border: '3px blue solid',

    width: '250px',
    objectFit: 'contain',
    marginBottom: '10px',
  },
  topInfoSection: {
    height: 252,
    // backgroundColor: 'red',
    justifyContent: 'center',
  },
  topInfoSectionText: {
    fontSize: 20,
    justifyContent: 'center',
  },
  infoContainer: {
    borderTop: '2px black solid',
    borderBottom: '2px black solid',
    flexDirection: 'column',
    width: '250px',
  },
  infoTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 14,
  },
  infoTextContainerBold: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  infoTextLeftBold: {
    marginRight: '5px',
    fontSize: 10,
  },

  infoTextLeft: {
    marginRight: '5px',
    fontSize: 10,
  },

  infoTextRight: {
    fontSize: 10,
  },
  text: {
    marginTop: '10px',
    fontSize: 20,
  },

  textBold: {
    fontSize: 10,
  },
  defaultText: {
    fontSize: 10,
  },
  nameText: {
    fontSize: 16,
  },
});

// interface DocumentTableProps {
//   ItemNo: string;
//   itemName: string;
//   qty: string;
//   price: string;
//   totalGBP: string;
// }

const TextInfoBold = ({ leftText, rightText }: TextInfoType) => {
  return (
    <View style={styles.infoTextContainerBold}>
      <Text style={styles.infoTextLeftBold}>{leftText} :</Text>
      <Text style={styles.infoTextRight}>{rightText}</Text>
    </View>
  );
};

const TextInfo = ({ leftText, rightText }: TextInfoType) => {
  return (
    <View style={styles.infoTextContainer}>
      <Text style={styles.infoTextLeft}>{leftText} :</Text>
      <Text style={styles.infoTextRight}>{rightText}</Text>
    </View>
  );
};
const DocumentInfo = ({ data }: { data: IProformaReport | undefined }) => {
  return (
    <View style={styles.infoContainer}>
      <TextInfo leftText="Document Date" rightText={data?.header.documentDate} />
      <TextInfo leftText="Customer Ref" rightText={data?.header.customerRef} />
      <TextInfo leftText="Contact Person" rightText={data?.header.contactPerson} />
      <TextInfo leftText="Sales Contact" rightText={data?.header.salesContact} />
      <TextInfo leftText="Page" rightText="1 of 1" />
    </View>
  );
};

const DocumentTable = ({ data }: { data: IProformaReport | undefined }) => {
  return (
    <View style={{ flexDirection: 'column', flex: 1 }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 10,
          paddingVertical: 5,
          marginBottom: 4,
          backgroundColor: '#d3d3d3',
          marginTop: '28px',
          borderRadius: 5,
        }}
      >
        <Text style={styles.defaultText}>Item no</Text>
        <Text style={styles.defaultText}> Item name </Text>
        <Text style={styles.defaultText}>Qty</Text>
        <Text style={styles.defaultText}>Price</Text>
        <Text style={styles.defaultText}>Total ({data?.header?.currency})</Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 10,
        }}
      >
        <Text style={styles.defaultText}>{data?.tableRow[0].itemNo}</Text>
        <Text style={styles.defaultText}> {data?.tableRow[0].itemName} </Text>
        <Text style={styles.defaultText}>{data?.tableRow[0].qty}</Text>
        <Text style={styles.defaultText}>{data?.tableRow[0].price}</Text>
        <Text style={styles.defaultText}>{data?.tableRow[0].totalGBP}</Text>
      </View>
    </View>
  );
};

const DetailBaseOnCurrency = ({ data }: { data?: IProformaReport }) => {
  const paymentDetail: IProformaReport['otherDetails'] | undefined = data?.otherDetails;
  const currency = data?.header?.currency;

  return (
    <>
      {currency === 'SGD' && (
        <>
          <TextInfoBold leftText="Account Name" rightText={paymentDetail?.accountName} />
          <TextInfoBold leftText="Bank Code" rightText={paymentDetail?.bankCode} />
          <TextInfoBold leftText="Swift BIK" rightText={paymentDetail?.swiftBik} />
          <TextInfoBold leftText="Account No." rightText={paymentDetail?.accountNo} />
          <TextInfoBold leftText="PayNow UED" rightText={paymentDetail?.payNowUED} />
        </>
      )}
      {currency === 'CAD' && (
        <>
          <TextInfoBold leftText="Account No." rightText={paymentDetail?.accountNo} />
          <TextInfoBold leftText="Branch" rightText={paymentDetail?.branch} />
          <TextInfoBold leftText="IBAN" rightText={paymentDetail?.IBAN} />
          <TextInfoBold leftText="Swift/BIC" rightText={paymentDetail?.swiftBic} />
          <TextInfoBold leftText="Account Name" rightText={paymentDetail?.accountName} />
        </>
      )}
      {currency === 'GBP' && (
        <>
          <TextInfoBold leftText="Account No." rightText={paymentDetail?.accountNo} />
          <TextInfoBold leftText="Branch" rightText={paymentDetail?.branch} />
          <TextInfoBold leftText="IBAN" rightText={paymentDetail?.IBAN} />
          <TextInfoBold leftText="Swift/BIC" rightText={paymentDetail?.swiftBic} />
          <TextInfoBold leftText="Account Name" rightText={paymentDetail?.accountName} />
        </>
      )}
      {currency === 'AED' ||
        (currency === 'EUR' && (
          <>
            <TextInfoBold leftText="Account No." rightText={paymentDetail?.accountNo} />
            <TextInfoBold leftText="IBAN" rightText={paymentDetail?.IBAN} />
            <TextInfoBold leftText="Swift/BIC" rightText={paymentDetail?.swiftBic} />
            <TextInfoBold leftText="Account Name" rightText={paymentDetail?.accountName} />
          </>
        ))}
      {currency === 'USD' && (
        <>
          <TextInfoBold leftText="Account Name" rightText={paymentDetail?.accountName} />
          <TextInfoBold leftText="Account No." rightText={paymentDetail?.accountNo} />
          <TextInfoBold leftText="Routing" rightText={paymentDetail?.routing} />
        </>
      )}
      {currency === 'HKD' && (
        <>
          <TextInfoBold leftText="Account Name" rightText={paymentDetail?.accountName} />
          <TextInfoBold leftText="Account No." rightText={paymentDetail?.accountNo} />
          <TextInfoBold leftText="Swift/BIC" rightText={paymentDetail?.swiftBic} />
        </>
      )}
    </>
  );
};

const BottomSection = ({ data }: { data?: IProformaReport }) => {
  const paymentDetail: IProformaReport['otherDetails'] | undefined = data?.otherDetails;
  const bankAddress = data?.otherDetails?.bankAddress?.split(',');
  const currency = data?.header?.currency;

  return (
    <View
      style={{
        borderTop: '2px black solid',
        borderBottom: '2px black solid',
        paddingVertical: 10,
        flexDirection: 'column',
      }}
    >
      <Text style={{ fontSize: 12, marginBottom: '10px' }}>Payment Details</Text>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flexDirection: 'column', width: '250px', gap: '8px' }}>
          <TextInfoBold leftText="Bank Name" rightText={paymentDetail?.bankName} />
          <View style={{ flexDirection: 'column', alignItems: 'flex-end', gap: 4, marginBottom: '8px' }}>
            {bankAddress?.map((x) => (
              <Text style={styles.defaultText}>{x}</Text>
            ))}
          </View>
          <DetailBaseOnCurrency data={data} />
        </View>

        <View style={{ flexDirection: 'column', width: '250px', gap: '8px' }}>
          <TextInfoBold leftText={`Total Before Tax (${currency})`} rightText={paymentDetail?.totalBeforeTaxGBP} />
          <TextInfoBold leftText={`Tax Amount (${currency})`} rightText="0.00" />
          <TextInfoBold leftText={`Document Total (${currency})`} rightText={paymentDetail?.documentTotalGBP} />
        </View>
      </View>
    </View>
  );
};

const MyDocument = () => {
  const [pdfDataUri, setPdfDataUri] = useState('');
  const [proformaData, setProformaData] = useState<IProformaReport>();

  useEffect(() => {
    const hashValue = window.location.hash;
    if (hashValue.startsWith(ProformaReportHashValueEnum.reportData)) {
      const response = JSON.parse(
        atob(decodeURIComponent(hashValue.replace('/login', '').replace(ProformaReportHashValueEnum.reportData, ''))),
      );
      setProformaData(response);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.hash]);

  return (
    <Document>
      <Page size="B4" style={styles.page}>
        <View style={styles.topContainer}>
          <View style={styles.section}>
            <Text style={styles.text}>Proforma</Text>
            <View style={styles.topInfoSection}>
              <Text style={styles.nameText}>{proformaData?.header.contactPerson}</Text>
            </View>
          </View>

          <View style={styles.section}>
            <Image src={pdfLogo} style={styles.logo} />
            <DocumentInfo data={proformaData} />
          </View>
        </View>
        <DocumentTable data={proformaData} />
        <BottomSection data={proformaData} />

        <View style={{ flexDirection: 'column', gap: 2, alignItems: 'center', marginVertical: 10 }}>
          <Text style={styles.defaultText}>
            Cult Wines Ltd - The Clockwork Building - 45 Beavor Lane - London - W6 9AR - United Kingdom
          </Text>
          <Text style={styles.defaultText}>
            VAT: GB129951484 Company Reg No: 06350591 Tel: +44 (0)2071000950
            <Link
              style={{ color: 'black', fontSize: 10, textDecoration: 'none' }}
              href="accountsreceivable@cultwines.com"
            >
              Email: accountsreceivable@cultwines.com
            </Link>
          </Text>
          <Link style={{ color: 'black', fontSize: 10, textDecoration: 'none' }} href="https://www.wineinvestment.com/">
            Web: https://www.wineinvestment.com/
          </Link>
        </View>
      </Page>
    </Document>
  );
};

function RenderApp() {
  return (
    <PDFViewer width="100%" height="100%">
      <MyDocument />
    </PDFViewer>

    // <div className="App">
    //   <CultReport />
    // </div>
  );
}

export default RenderApp;
