import {
  DisplayTextKeys,
  OffersType,
  QUERY_KEYS,
  SetSlideoutConfigType,
  SlideoutConfigType,
  TradePurchaseRefType,
  onTradeTopUpEnum,
} from 'views/Trade/types';
import { Button, Dropdown } from 'components';
import { generateQuantityDropdown } from 'views/Trade/helpers';
import { useContext, useEffect, useMemo, useState } from 'react';
import { StatusTypes, useHandleBuy } from 'views/shared/hooks/useHandleBuy';
import { BuysType } from 'views/shared/services/executeBuy';
import { useFeeModelInfo } from 'views/shared/hooks/useFeeModelInfo';
import { AppContext } from 'context/ContextProvider';
import { ViewStateType } from 'components/ProductTemplates/types';
import SelectPortfolioView from 'views/shared/SlideOutViews/SelectPortfolioView';
import TopUpView from 'views/shared/SlideOutViews/TopUpView';
import RequestWineOnFailedPurchase from 'views/shared/SlideOutViews/RequestWineOnFailedPurchase';
import { FeedbackTemplate } from 'views/shared/FeedbackTemplate';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from 'types/DomainTypes';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_REFECH_TIMEOUT } from 'views/Trade/constants';
import { buildDisplayText } from 'utils';
import { fetchOffers } from 'views/Trade/services/fetchOffers';
import { useFetch } from 'views/Trade/hooks/useFetch';
import WineInfoTemplate from './WineInfoTemplate';
import { PORTAL_DESCRIPTIONS } from 'types/commonTypes';

interface ICreateOfferSlideOutProps {
  slideOutConfig: SlideoutConfigType;
  setSlideOutConfig: SetSlideoutConfigType;
}

const PurchaseView = ({ setSlideOutConfig, slideOutConfig }: ICreateOfferSlideOutProps) => {
  const {
    state: {
      settings: { accountInfo },
    },
    formatter,
    gbpToTargetCurrency,
  } = useContext(AppContext);

  const nav = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);
  const fxRate = accountInfo?.account?.fxRate || 1;
  const prevAcctBalance = accountInfo?.account.totalCashOnAccount;
  const { handlePurchase: executeBuy, mutateStatus: status, resetStatus } = useHandleBuy();
  const [quantitySelected, setQuantitySelected] = useState<number>(0);
  const [topupAmount, setTopAmount] = useState(0);
  const [purchaseInfo, setPurchaseInfo] = useState<TradePurchaseRefType>();
  const [extraConfig, setExtraConfig] = useState<SlideoutConfigType['extraConfig']>();

  const { isLoading: isLoadingMarketOffers, responce: marketOffers } = useFetch({
    refetchOnWindowFocus: false,
    shouldCache: false,
    queryFn: () => fetchOffers({ searchText: slideOutConfig.offer?.lwin18, page: 0, pageSize: 50 }),
    queryKey: [QUERY_KEYS.FETCH_OFFERS_SEARCH, slideOutConfig.offer?.lwin18 || ''],
  });

  useEffect(() => {
    if (slideOutConfig?.extraConfig) {
      const config = { ...slideOutConfig.extraConfig };
      setExtraConfig(config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const feeModelInfo = useFeeModelInfo({
    price: slideOutConfig.offer?.priceInTargetCurrency || 0,
    qty: quantitySelected,
  });

  const purchaseInfoRef = useMemo(() => {
    return encodeURIComponent(btoa(JSON.stringify(purchaseInfo)));
  }, [purchaseInfo]);

  const availableMarketOffers = useMemo(() => {
    const lowestOfferGbp = slideOutConfig.offer?.lowestOffer?.price ?? 0;
    // const test = (slideOutConfig.marketOffers || []).filter((x) => x.lwin18 === slideOutConfig.offer?.lwin18);
    // console.log('test ', test);
    const allSelectedLwin18 = (marketOffers?.data || []).filter(
      (x) => (x.price || 0).toFixed(2) === lowestOfferGbp.toFixed(2),
    );
    // console.log('allSelectedLwin18 ', allSelectedLwin18);
    allSelectedLwin18.sort((a, b) => a.quantityOutstanding - b.quantityOutstanding);

    return {
      offers: allSelectedLwin18,
      quantity: allSelectedLwin18.reduce((prev, current) => prev + current.quantityOutstanding, 0),
      lowestOfferGbp,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketOffers?.data]);

  const generateBody = (config: SlideoutConfigType, quantity: number, offers: OffersType[]) => {
    const buys: BuysType[] = [];

    let targetedQty = quantity;
    offers.forEach((x) => {
      const { lwin18, id, price: priceGbp, quantityOutstanding } = x;
      if (targetedQty === 0) return;
      if (quantityOutstanding <= targetedQty && quantityOutstanding > 0) {
        targetedQty -= quantityOutstanding;
        buys.push({
          lwin18,
          offerId: id,
          price: priceGbp,
          quantity: quantityOutstanding,
        });
      } else if (quantityOutstanding - targetedQty >= 0 && quantityOutstanding > 0) {
        buys.push({
          lwin18,
          offerId: id,
          price: priceGbp,
          quantity: targetedQty,
        });
      }
    });

    // buys.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0);
    // console.log('buys  ', buys);

    const params: TradePurchaseRefType = {
      name: config.offer?.wineName,
      subtitle: `${config?.offer?.vintage} @ ${formatter.format(config?.offer?.priceGbp || 0)} per ${
        config.offer?.unitSize
      }`,
      priceInTargetCurrency: config.offer?.priceInTargetCurrency,
      prevAcctBalance: prevAcctBalance,
      body: {
        portfolioId: Number(feeModelInfo.selectedPortfolio?.value) || 0,
        description: slideOutConfig?.portalDescriptions ?? PORTAL_DESCRIPTIONS.PORTAL_BUY_TRADE,
        buys,
      },
    };
    return params;
  };

  const configBackButton = (nextView: ViewStateType) => {
    if (nextView === ViewStateType.TOP_UP && feeModelInfo.ddlOptions.length === 1) {
      setSlideOutConfig((prev) => ({
        ...prev,
        showBackButton: true,
        wineInfoTemplateConfig: { ...prev.wineInfoTemplateConfig, showTemplate: false },
        innerViewState: ViewStateType.TOP_UP,
        onBack: () => {
          setSlideOutConfig((previous) => ({
            ...previous,
            showBackButton: !!extraConfig?.initOnBack,
            wineInfoTemplateConfig: { ...previous.wineInfoTemplateConfig, showTemplate: true },
            innerViewState: ViewStateType.DEFAULT,
            onBack: () => {
              if (extraConfig?.initOnBack) return extraConfig?.initOnBack?.();
            },
          }));
        },
      }));

      return;
    }

    if (nextView === ViewStateType.TOP_UP && feeModelInfo.ddlOptions.length > 1) {
      setSlideOutConfig((prev) => ({
        ...prev,
        showBackButton: true,
        wineInfoTemplateConfig: { ...prev.wineInfoTemplateConfig, showTemplate: false },
        innerViewState: ViewStateType.TOP_UP,
        onBack: () => {
          setSlideOutConfig((previous) => ({
            ...previous,
            showBackButton: true,
            wineInfoTemplateConfig: { ...prev.wineInfoTemplateConfig, showTemplate: false },
            innerViewState: ViewStateType.SELECT_PORTFOLIO,
            onBack: () => {
              setSlideOutConfig({
                ...previous,
                showBackButton: !!extraConfig?.initOnBack,
                wineInfoTemplateConfig: { ...prev.wineInfoTemplateConfig, showTemplate: true },
                innerViewState: ViewStateType.DEFAULT,
                onBack: () => {
                  if (extraConfig?.initOnBack) return extraConfig?.initOnBack?.();
                },
              });
              feeModelInfo.resetSelectedPortfolio();
            },
          }));
        },
      }));

      return;
    }
    if (nextView === ViewStateType.SELECT_PORTFOLIO) {
      setSlideOutConfig((prev) => ({
        ...prev,
        showBackButton: true,
        wineInfoTemplateConfig: { ...prev.wineInfoTemplateConfig, showTemplate: false },
        innerViewState: ViewStateType.SELECT_PORTFOLIO,
        onBack: () => {
          setSlideOutConfig((previous) => ({
            ...previous,
            showBackButton: !!extraConfig?.initOnBack,
            wineInfoTemplateConfig: { ...prev.wineInfoTemplateConfig, showTemplate: true },
            innerViewState: ViewStateType.DEFAULT,
            onBack: () => {
              if (extraConfig?.initOnBack) return extraConfig?.initOnBack?.();
            },
          }));
          feeModelInfo.resetSelectedPortfolio();
        },
      }));
    }
  };

  const onStepNext = () => {
    const purchasePrice = (slideOutConfig.offer?.priceInTargetCurrency || 0) * quantitySelected;
    const _topupAmount = purchasePrice - (accountInfo?.account.totalCashOnAccount ?? 0);
    const params = generateBody(slideOutConfig, quantitySelected, availableMarketOffers.offers);
    setPurchaseInfo(params);
    if (!feeModelInfo.selectedPortfolio) {
      configBackButton(ViewStateType.SELECT_PORTFOLIO);
      return;
    }

    if (_topupAmount > 0) {
      if (!feeModelInfo.canTopUp) {
        // select portfolio
        configBackButton(ViewStateType.SELECT_PORTFOLIO);
        return;
      }

      // top up
      setTopAmount(_topupAmount / (fxRate ?? 1));
      configBackButton(ViewStateType.TOP_UP);
      return;
    }

    executeBuy({ body: params.body });
  };

  useEffect(() => {
    if (status === StatusTypes.isLoading) {
      setSlideOutConfig((prev) => ({
        ...prev,
        wineInfoTemplateConfig: { ...prev.wineInfoTemplateConfig, showTemplate: false },
        showBackButton: false,
        view: ViewStateType.LOADING,
      }));
    }

    if (status === StatusTypes.success) {
      setSlideOutConfig((prev) => ({
        ...prev,
        wineInfoTemplateConfig: { ...prev.wineInfoTemplateConfig, showTemplate: false },
        showBackButton: false,
        view: ViewStateType.CAMPAIGN_PURCHASE,
        onCTA: () => {
          nav(`${NavigationPath.MY_PORTFOLIO}#find=${purchaseInfoRef}`);
          setSlideOutConfig({ isOpen: false });
          extraConfig?.success?.cta?.();
        },
      }));
      extraConfig?.success?.onSuccess?.();

      setTimeout(() => {
        // only works if they are separately called
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_OFFERS] });
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS] });
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_STOCK] });

        if (slideOutConfig?.searchText) {
          queryClient.resetQueries({
            queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS_SEARCH, slideOutConfig.searchText],
          });
          queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_OFFERS_SEARCH, slideOutConfig.searchText] });
        }

        accountInfo?.account?.refetchCashBalances?.();
      }, QUERY_REFECH_TIMEOUT);
    }

    if (status === StatusTypes.error) {
      setSlideOutConfig((prev) => ({
        ...prev,
        wineInfoTemplateConfig: { ...prev.wineInfoTemplateConfig, showTemplate: false },
        showBackButton: false,
        view: ViewStateType.DEFAULT,
        innerViewState: ViewStateType.PURCASE_ERROR,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, setSlideOutConfig, nav, purchaseInfoRef, queryClient, accountInfo?.account, extraConfig]);

  const onStatusChange = (viewState: ViewStateType) => {
    setSlideOutConfig((prev) => ({ ...prev, view: viewState }));
  };

  return (
    <div className="flex bg-white flex-col flex-1 ">
      <FeedbackTemplate
        viewState={slideOutConfig?.view || ViewStateType.DEFAULT}
        templateConfig={slideOutConfig.templateConfig}
        onCTA={slideOutConfig.onCTA}
        onClose={() => {
          setSlideOutConfig({ isOpen: false });
        }}
      >
        <WineInfoTemplate
          config={{
            ...slideOutConfig.wineInfoTemplateConfig,
            displayText: {
              leftDisplayText: DisplayTextKeys.NUMBER_OF_UNIT_AVAILABLE,
              rightDisplayText: DisplayTextKeys.LOWEST_OFFER,
            },
            marketValue: gbpToTargetCurrency.convert(availableMarketOffers.lowestOfferGbp),
            quantity: availableMarketOffers.quantity,
          }}
        >
          {slideOutConfig?.innerViewState === ViewStateType.PURCASE_ERROR && (
            <RequestWineOnFailedPurchase
              offer={{
                expiryDate: '',
                price: slideOutConfig?.offer?.priceInTargetCurrency || 0,
                subtitle: `${slideOutConfig?.offer?.vintage} @ ${formatter.format(
                  slideOutConfig?.offer?.priceGbp || 0,
                )} per ${slideOutConfig.offer?.unitSize}`,
                name: `${slideOutConfig.offer?.wineName}`,
              }}
              resetStatus={resetStatus}
              onStatusChange={onStatusChange}
              qtyBought={quantitySelected}
              onClose={() => setSlideOutConfig({ isOpen: false })}
            />
          )}

          {slideOutConfig?.innerViewState === ViewStateType.TOP_UP && (
            <TopUpView
              fxRate={fxRate}
              topupAmount={topupAmount}
              portfolioId={Number(feeModelInfo.selectedPortfolio?.value) || -1}
              onSuccessUrl={`#${onTradeTopUpEnum.onTradeToupSuccess}=${purchaseInfoRef}`}
              onFailureUrl={`#${onTradeTopUpEnum.onTradeToupFailure}=${purchaseInfoRef}`}
            />
          )}

          {slideOutConfig?.innerViewState === ViewStateType.SELECT_PORTFOLIO && (
            <SelectPortfolioView
              feeModelInfo={feeModelInfo}
              onClick={() => {
                onStepNext();
              }}
            />
          )}
          {slideOutConfig?.innerViewState === ViewStateType.DEFAULT && (
            <>
              <div className="px-5 py-3 flex flex-col gap-1 mt-5">
                <div className="flex flex-col gap-2">
                  {isLoadingMarketOffers && (
                    <>
                      <div className=" text-black border  min-h-[12px] rounded bg-gray-300 animate-pulse w-[80%] " />
                      <div className="flex-1 text-14 text-black border  min-h-[48px] p-4 rounded justify-between items-center bg-gray-300 animate-pulse " />
                    </>
                  )}

                  {!isLoadingMarketOffers && (
                    <>
                      <span className="text-sm">{displayText[DisplayTextKeys.SELECT_QUANTITY_LABEL]}</span>
                      <Dropdown
                        placeholder={displayText[DisplayTextKeys.PLEASE_SELECT_QUANTITY]}
                        value={quantitySelected > 0 ? quantitySelected?.toString() : ''}
                        containerClassName="w-full rounded "
                        itemsContainerClassName="max-h-[250px] min-h-10 overflow-y-auto"
                        className="flex-1 text-sm text-black border  h-12 p-4 rounded justify-between items-center"
                        itemsWrapperClassName="w-full"
                        items={generateQuantityDropdown(availableMarketOffers.quantity)}
                        onItemSelect={(item) => setQuantitySelected(Number(item.value))}
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="flex flex-col py-10 px-5 drop-shadow-sm ">
                {/* <div className="flex justify-between divide-x divide-gray-300">
                  <div className="flex-1 flex flex-col">
                    <span className="text-center text-sm">Total market value</span>
                    <span className="text-center text-20">
                      {formatter.format(
                        (slideOutConfig?.offer?.quantityOutstanding || 0) *
                          (slideOutConfig?.offer?.marketValueInTargetCurrency || 0),
                      )}
                    </span>
                  </div>
                </div> */}

                <div className="flex justify-between divide-x divide-gray-300 mb-[55px]">
                  <div className="flex-1 flex flex-col">
                    <span className="text-center text-sm">{t('product:wineDetails.investMore.currentBalance')}</span>
                    <span className="text-center text-20">
                      {formatter.format(accountInfo?.account?.totalCashOnAccount as number, true)}
                    </span>
                  </div>

                  <div className="flex-1 flex flex-col">
                    <span className="text-center text-sm">{t('product:wineDetails.investMore.balanceRemaining')}</span>
                    <span className="text-center text-20">
                      {formatter.format(feeModelInfo?.getRemaingBalAndBtnText().remainingBal as number, true)}
                    </span>
                  </div>
                </div>

                {isLoadingMarketOffers && <div className="btn rounded-full text-black bg-gray-300 animate-pulse" />}
                {!isLoadingMarketOffers && (
                  <Button
                    isDisable={!(quantitySelected > 0) || status === StatusTypes.isLoading}
                    onClick={() => {
                      onStepNext();
                    }}
                    type="button"
                    className="text-14 font-normal rounded-full mt-8  btn  bg-orange text-black disabled:bg-[#cccccc] disabled:text-[#b3b3b3]"
                  >
                    {feeModelInfo.getRemaingBalAndBtnText().btnText}
                  </Button>
                )}
              </div>
            </>
          )}
        </WineInfoTemplate>
      </FeedbackTemplate>
    </div>
  );
};

export default PurchaseView;
