import { AppContext } from 'context/ContextProvider';
import { Button, Dropdown } from 'components';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FeedbackTemplate } from 'views/shared/FeedbackTemplate';
import { DisplayTextKeys, OffersType, QUERY_KEYS, SetSlideoutConfigType, SlideoutConfigType } from 'views/Trade/types';
import { useQueryClient } from '@tanstack/react-query';
import { IAcceptHighestBidBody } from 'views/Trade/services/acceptHighestBid';
import { QUERY_REFECH_TIMEOUT, STOP_LIMIT_TYPE } from 'views/Trade/constants';
import { ViewStateType } from 'components/ProductTemplates/types';
import { useFetch } from 'views/Trade/hooks/useFetch';
import Loading from 'components/Loading/loading';
import { fetchPortfolioStocks } from 'views/Trade/services/fetchPortfolioStocks';
import { buildDisplayText } from 'utils';
import { useTranslation } from 'react-i18next';
import { fetchBid } from 'views/Trade/services/fetchBids';
import { generateQuantityDropdown } from 'views/Trade/helpers';
import { DropdownItem } from 'components/Dropdown';
import { useHandleAcceptBid } from 'views/shared/hooks/useHandleAcceptBid';
import WineInfoTemplate from './WineInfoTemplate';
import { PORTAL_DESCRIPTIONS } from 'types/commonTypes';

interface AcceptBidViewProps {
  slideOutConfig: SlideoutConfigType;
  setSlideOutConfig: SetSlideoutConfigType;
}

const AcceptBidView = ({ slideOutConfig, setSlideOutConfig }: AcceptBidViewProps) => {
  const {
    state: {
      settings: { accountInfo },
    },

    gbpToTargetCurrency,
  } = useContext(AppContext);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);

  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const { handleAcceptBid } = useHandleAcceptBid();
  const { responce: portfolioStocksResponce } = useFetch({
    queryFn: () => fetchPortfolioStocks({ search: slideOutConfig.offer?.lwin18, page: 0, pageSize: 10 }),
    queryKey: [`${QUERY_KEYS.FETCH_PORTFOLIO_STOCK}${slideOutConfig.offer?.lwin18}`],
  });

  // const { responce: bidResponce } = useFetch({
  //   queryFn: () => fetchBid({ lwin18: slideOutConfig.offer?.lwin18 }),
  //   queryKey: [`${QUERY_KEYS.FETCH_BID}${slideOutConfig.offer?.lwin18}`],
  // });

  const bidQty = useMemo(() => {
    return slideOutConfig.offer?.highestBid?.quantityOutstanding ?? 0;
  }, [slideOutConfig.offer]);

  const stock = useMemo(() => {
    if (!portfolioStocksResponce?.data[0]) return null;
    return portfolioStocksResponce?.data[0];
  }, [portfolioStocksResponce?.data]);

  const acceptBid = async (
    offer: OffersType,
    refetchCashBalances?: () => void,
    accountId?: string,
    quantity?: number,
  ) => {
    const requestBody: IAcceptHighestBidBody = {
      description: PORTAL_DESCRIPTIONS.PORTAL_ACCEPTBID_TRADE,
      accountId: accountId || '',
      items: [
        {
          lwin18: offer.lwin18,
          stopLimitPerUnit: offer.highestBid?.price as number,
          stopLimitType: STOP_LIMIT_TYPE.customPrice,
          priceType: 'AcceptHighestBid',
          quantity: quantity ?? 0,
        },
      ],
    };

    await handleAcceptBid(requestBody, {
      success: () => {
        setSlideOutConfig((prev) => ({
          ...prev,
          view: ViewStateType.THANK_YOU,
          templateConfig: { translationKey: 'common:generalSuccess' },
          isOpen: true,
        }));
      },
      isLoading: () => {
        setSlideOutConfig((prev) => ({
          ...prev,
          view: ViewStateType.LOADING,
        }));
      },
      error: () => {
        setSlideOutConfig((prev) => ({
          ...prev,
          view: ViewStateType.ACCEPT_BID_ERROR,
          onCTA: () =>
            setSlideOutConfig(() => ({
              ...prev,
              view: ViewStateType.DEFAULT,
            })),
        }));
      },
      finally: () => {
        setTimeout(() => {
          // only works if they are separately called
          queryClient.resetQueries({
            queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS],
          });
          queryClient.resetQueries({
            queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_STOCK],
          });
          queryClient.resetQueries({
            queryKey: [QUERY_KEYS.FETCH_OFFERS],
          });

          if (slideOutConfig?.searchText) {
            queryClient.resetQueries({
              queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS_SEARCH, slideOutConfig.searchText],
            });
            queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_OFFERS_SEARCH, slideOutConfig.searchText] });
          }

          refetchCashBalances?.();
        }, QUERY_REFECH_TIMEOUT);
      },
    });
  };

  const qtyDropdown = (generateDropdown: (quantity?: number) => DropdownItem[], stockQuantity: number) => {
    let qtyToGenerate = 0;
    if (bidQty > stockQuantity) {
      qtyToGenerate = stockQuantity;
    } else {
      qtyToGenerate = bidQty ?? 0;
    }
    return generateDropdown(qtyToGenerate);
  };
  // dd
  useEffect(() => {
    if (stock?.physicalQuantity === 1) setSelectedQuantity(1);
  }, [stock?.physicalQuantity]);

  return (
    <FeedbackTemplate
      viewState={slideOutConfig?.view || ViewStateType.DEFAULT}
      templateConfig={slideOutConfig.templateConfig}
      onCTA={slideOutConfig.onCTA}
      onClose={() => {
        setSlideOutConfig({ isOpen: false });
      }}
    >
      <WineInfoTemplate config={{ ...slideOutConfig.wineInfoTemplateConfig, unitSize: stock?.unitSize }}>
        <div className="flex flex-col py-10 px-5 drop-shadow-sm ">
          {!stock?.assetAccountId ? (
            <Loading />
          ) : (
            <>
              <Dropdown
                placeholder={displayText[DisplayTextKeys.PLEASE_SELECT_QUANTITY]}
                value={selectedQuantity > 0 ? selectedQuantity?.toString() : ''}
                containerClassName="w-full rounded "
                itemsContainerClassName="max-h-[250px] min-h-10 overflow-y-auto"
                className="flex-1 text-14 text-black border  h-12 p-4 rounded justify-between items-center"
                itemsWrapperClassName="w-full"
                items={qtyDropdown(generateQuantityDropdown, stock?.physicalQuantity ?? 0)}
                onItemSelect={(item) => setSelectedQuantity(Number(item.value))}
              />

              <div className="flex justify-between divide-x divide-gray-300 mt-4">
                <div className="flex-1 flex flex-col">
                  <span className="text-center text-sm">{`${displayText[DisplayTextKeys.ACCEPT_BID_MESSAGE]}`}</span>
                  <span className="text-center text-sm">
                    {`${selectedQuantity} x ${gbpToTargetCurrency.convertAndFormat(
                      slideOutConfig?.offer?.highestBid?.price ?? 0,
                    )} `}
                  </span>
                </div>
              </div>
              <Button
                isDisable={!selectedQuantity}
                onClick={() =>
                  acceptBid(
                    slideOutConfig?.offer as OffersType,
                    accountInfo?.account.refetchCashBalances,
                    stock?.assetAccountId || '',
                    selectedQuantity,
                  )
                }
                type="button"
                className="text-14 font-normal rounded-full mt-8  btn  bg-orange text-black disabled:bg-[#cccccc] disabled:text-[#b3b3b3]"
              >
                {displayText[DisplayTextKeys.ACCEPT_BID]}
              </Button>
            </>
          )}
        </div>
      </WineInfoTemplate>
    </FeedbackTemplate>
  );
};

export default AcceptBidView;
