import { Button } from 'components';
import {
  Dispatch,
  forwardRef,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';
import CheckBox from 'components/CheckBox';
import InfiniteScrollDropdown from 'components/Dropdown/InfiniteScrollDropdown';

interface IFilterViewViewtProps1<T> {
  sourceData: T[];
  customFilters: FiltersType<T>;
  setFilteredSource?: (isFiltered: boolean, filters: FiltersType<T> | null) => void;
}

export type FiltersType<T> = Record<
  keyof T | string,
  {
    label: string;
    selected: string[];
    previewSelected: string[];
    itemsContainerClassName?: string;
    isNumerical?: boolean;
    customFilterFunction?: (customFilters: FiltersType<T>, sourceData: T[]) => T[];
    customItems?: string[];
    isAsc?: boolean;
    customSortOrder?: string[];
    selectAllText?: string;
  }
>;

export interface FilterViewRef {
  resetFilters: () => void;
}

const FilterView = forwardRef(
  <T,>({ sourceData, customFilters, setFilteredSource }: IFilterViewViewtProps1<T>, ref: Ref<FilterViewRef>) => {
    const { t } = useTranslation();

    const [filters, setFilters] = useState<FiltersType<T> | null>(customFilters);

    const generateDropDownItems = useCallback(
      (
        source: T[],
        filterKey: keyof T,
        filterData: FiltersType<T>,
        setFilterData: Dispatch<SetStateAction<FiltersType<T> | null>>,
      ) => {
        const currentFilter = filterData[filterKey];
        const selectAll = currentFilter.selectAllText ?? `${t('trade:common.all')} ${currentFilter.label}`;

        let uniqueItems: string[] = [];
        if (!currentFilter.customItems?.length) {
          uniqueItems = [
            ...new Set([
              selectAll,
              ...source
                .map((x) => (x[filterKey] ? String(x[filterKey]) : ''))
                .sort((a, b) => {
                  if (currentFilter.customSortOrder) {
                    return (
                      currentFilter.customSortOrder.indexOf(String(a)) -
                      currentFilter.customSortOrder.indexOf(String(b))
                    );
                  }

                  if (currentFilter.isNumerical) {
                    if (a === '') return 1;
                    if (b === '') return -1;
                    return currentFilter.isAsc
                      ? parseFloat(String(a)) - parseFloat(String(b))
                      : parseFloat(String(b)) - parseFloat(String(a));
                  }

                  return currentFilter.isAsc
                    ? b.toLowerCase().localeCompare(a.toLowerCase())
                    : a.toLowerCase().localeCompare(b.toLowerCase());
                }),
            ]),
          ].filter((x) => x);
        } else {
          uniqueItems = [selectAll, ...currentFilter.customItems];
        }

        const selectValue = (value: string) => {
          if (value === selectAll && currentFilter.previewSelected.includes(selectAll)) {
            // add all values
            setFilterData((prev) => {
              return prev
                ? {
                    ...prev,
                    [filterKey]: {
                      ...prev[filterKey],
                      label: currentFilter.label,
                      previewSelected: [],
                    },
                  }
                : null;
            });

            return;
          }

          if (value === selectAll && !currentFilter.previewSelected.includes(selectAll)) {
            // add all values
            setFilterData((prev) => {
              return prev
                ? {
                    ...prev,
                    [filterKey]: {
                      ...prev[filterKey],
                      label: currentFilter.label,
                      previewSelected: uniqueItems,
                    },
                  }
                : null;
            });

            return;
          }

          if (
            currentFilter.previewSelected.length + 1 !== uniqueItems.length - 1 &&
            currentFilter.previewSelected.includes(selectAll) &&
            currentFilter.previewSelected.includes(value)
          ) {
            // remove select all
            setFilterData((prev) => {
              return prev
                ? {
                    ...prev,
                    [filterKey]: {
                      ...prev[filterKey],
                      label: currentFilter.label,
                      previewSelected: currentFilter.previewSelected
                        .filter((x) => x !== selectAll)
                        .filter((x) => x !== value),
                    },
                  }
                : null;
            });
            return;
          }

          if (
            currentFilter.previewSelected.length + 1 === uniqueItems.length - 1 &&
            !currentFilter.previewSelected.includes(selectAll) &&
            !currentFilter.previewSelected.includes(value)
          ) {
            // select all
            setFilterData((prev) => {
              return prev
                ? {
                    ...prev,
                    [filterKey]: {
                      ...prev[filterKey],
                      label: currentFilter.label,
                      previewSelected: [...prev[filterKey].previewSelected, selectAll, value],
                    },
                  }
                : null;
            });
            return;
          }

          if (currentFilter.previewSelected.includes(value)) {
            // remove value
            setFilterData((prev) => {
              return prev
                ? {
                    ...prev,
                    [filterKey]: {
                      ...prev[filterKey],
                      label: currentFilter.label,
                      previewSelected: currentFilter.previewSelected.filter((x) => x !== value),
                    },
                  }
                : null;
            });

            return;
          }

          if (!currentFilter.previewSelected.includes(value)) {
            // add value
            setFilterData((prev) => {
              return prev
                ? {
                    ...prev,
                    [filterKey]: {
                      ...prev[filterKey],
                      label: currentFilter.label,
                      previewSelected: [...prev[filterKey].previewSelected, value],
                    },
                  }
                : null;
            });
          }
        };

        return uniqueItems.length > 1
          ? uniqueItems.map((value, index) => {
              const values = String(value || '');
              return {
                id: `${index}${value}`,
                content: (
                  <button
                    className=" w-full py-3 px-4 text-base flex "
                    onClick={(e) => {
                      e.stopPropagation();
                      selectValue(values);
                    }}
                  >
                    <CheckBox
                      inputClassName="max-h-[18px] max-w-[18px] min-h-[18px] min-w-[18px]"
                      isChecked={currentFilter.previewSelected.includes(values)}
                      id={`${value}`}
                    >
                      <span className="ml-3 text-left text-black text-14 ">{capitalizeFirstLetter(values)}</span>
                    </CheckBox>
                  </button>
                ),
                value: `${values}`,
              };
            })
          : [];
      },
      [t],
    );

    const isSetFiltered = (filterConfig: FiltersType<T> | null) => {
      let isFiltered = false;
      if (!filterConfig) return false;
      Object.keys(filterConfig).forEach((key) => {
        if (filterConfig[key].previewSelected.length > 0 && !isFiltered) isFiltered = true;
      });

      return isFiltered;
    };

    const applyFilters = (filterConfig: FiltersType<T> | null, isClearFilterKey?: keyof T | null) => {
      if (!filterConfig) return;

      const filterChange = { ...filterConfig } as unknown as Record<string, Record<string, unknown>>;
      const clearKey = isClearFilterKey as string;
      const isFiltered = isSetFiltered(filterConfig);

      if (clearKey) {
        filterChange[clearKey] = {
          ...filterChange[clearKey],
          label: filterChange[clearKey]?.label,
          selected: [],
          previewSelected: [],
        };

        setFilters(filterChange as FiltersType<T>);
        setFilteredSource?.(isFiltered, filterChange as FiltersType<T>);
        return;
      }

      Object.keys(filterConfig).forEach((key) => {
        filterChange[key] = {
          ...filterChange[key],
          label: filterChange[key]?.label,
          selected: filterChange[key]?.previewSelected,
        };
        setFilters(filterChange as FiltersType<T>);
      });

      setFilteredSource?.(isFiltered, filterChange as FiltersType<T>);
    };

    const previewFilteredResult = useMemo(() => {
      let source = [...sourceData] as Record<string, string>[];
      const filterConfig = filters as FiltersType<T>;

      let isFiltered = false;
      Object.keys(filterConfig).forEach((key) => {
        if (filterConfig[key].previewSelected.length > 0 && !isFiltered) isFiltered = true;

        if (filterConfig[key].previewSelected.length > 0 && filterConfig[key].customFilterFunction) {
          source = filterConfig[key].customFilterFunction?.(filterConfig, source as T[]) as Record<string, string>[];
        } else if (filterConfig[key].previewSelected.length > 0) {
          source = source.filter((x) => filterConfig[key].previewSelected.includes(String(x[key])));
        }
      });

      return { resultLength: source.length, isFiltered };
    }, [sourceData, filters]);

    const dropDowns = useMemo(() => {
      if (!filters) return [];

      const dropDownkeys = Object.keys(filters) as (keyof T)[];

      return dropDownkeys.map((key) => {
        const config = filters[key];
        const select = `${t('trade:common.all')} ${config.label}`;

        return (
          <div className="" key={config.label}>
            <InfiniteScrollDropdown
              isActive={config.selected.length > 0}
              id={config.label}
              autoClose={false}
              placeholder={select}
              value={
                config.label
                  ? config.label
                  : config.previewSelected.length > 1
                  ? `${t('trade:common.multipleSelection')}`
                  : config.previewSelected.toString()
              }
              iconClassName={'min-w-[10px] w-[10px]  '}
              containerClassName="rounded "
              itemsContainerClassName={`overflow-y-auto ${
                config?.itemsContainerClassName ?? 'max-h-[280px] min-w-[280px]'
              }`}
              className="flex flex-row text-sm  text-black font-bold  border-[1.5px] border-black rounded-2xl w-[100px] px-3 min-h-[25px] whitespace-nowrap justify-center items-center "
              itemsWrapperClassName="min-w-[280px]"
              items={generateDropDownItems(sourceData, key, filters, setFilters)}
              itemClassName="text-base flex pl-0 pr-0 pt-0 pb-0"
              footer={
                <div className="flex flex-col p-2 gap-2">
                  <Button
                    isDisable={!config.previewSelected.length}
                    className="btn text-14  font-normal bg-orange rounded-full disabled:bg-gray-600 "
                    onClick={() => applyFilters(filters)}
                  >
                    {t('portfolio:filters.applyButtonText').replace(
                      /\{\{.*\}\}/g,
                      `${previewFilteredResult.resultLength}`,
                    )}
                  </Button>

                  <Button
                    isDisable={!config.selected.length}
                    className="text-14 text-gray-500 h-8"
                    onClick={() => applyFilters(filters, key)}
                  >
                    {capitalizeFirstLetter(t('portfolio:filters.clearFilters'))}
                  </Button>
                </div>
              }
            />
          </div>
        );
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, sourceData, t]);

    const clearAllFilters = (filterConfig: FiltersType<T> | null) => {
      if (!filterConfig) return;
      const filterChange = { ...filterConfig } as unknown as Record<string, Record<string, unknown>>;
      Object.keys(filterConfig).forEach((key) => {
        filterChange[key] = {
          ...filterChange[key],
          label: filterChange[key]?.label,
          selected: [],
          previewSelected: [],
        };
        setFilters({ ...filterChange } as FiltersType<T>);
      });

      setFilteredSource?.(false, filterChange as FiltersType<T>);
    };

    useImperativeHandle(
      ref,
      () => ({
        resetFilters: () => {
          clearAllFilters(filters);
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [filters],
    );

    return (
      <div className="flex flex-row gap-2 flex-1 flex-wrap">
        {dropDowns.map((x) => x)}{' '}
        <button className="underline text-sm" onClick={() => clearAllFilters(filters)}>
          {capitalizeFirstLetter(t('trade:common.clear_all_filters'))}
        </button>
      </div>
    );
  },
);
export default FilterView;
