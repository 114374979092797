export enum DisplayTextKeys {
  INVEST_MORE_BUTTON_TEXT = 'invest_more_button_text',
  SELL_HOLDINGS_BUTTON_TEXT = 'sell_holdings_button_text',
  HIGHEST_BID = 'highestBid',
  LOWEST_OFFER = 'lowestOffer',
  ACCEPT_BID = 'acceptBid',
  ACCEPT_OFFER = 'acceptOffer',
  INVEST_MORE_TITLE_TEXT = 'investMore.title',
  SELL_HOLDINGS_TITLE_TEXT = 'sellHolding.title',
  UINTS_OWNED = 'units_owned',
  VALUE_PER_UNIT = 'value_per_unit',
  AVG_VALUE_PER_UNIT = 'avgValuePerUnit',
  TOTAL_VALUE = 'total_value',
  INCLUDE_FEES = 'include_fees',
  DATA_AND_VALUATION = 'data_and_valuations',
  ARRANGE_DELIVERY_BUTTON_TEXT = 'arrangeDelivery.buttonText',
  ARRANGE_DELIVERY_TITLE = 'arrangeDelivery.title',
  ARRANGE_DELIVERY_SUBTITLE = 'arrangeDelivery.subTitle',
  LIVE_EX_PERFORMANCE_CHART_DESCRIPTION = 'live_ex-performance_chart_description',
  LIVE_EX_CHART_TITLE = 'live_ex_chart_title',

  TOTAL_PURCHASE_PRICE = 'valuations.total-purchase-price',
  TOTAL_UPFRONT_FEES = 'valuations.total-upfront-fees',
  MARKET_PRICE = 'valuations.market-price',
  PROFIT_LOSS = 'valuations.profit-loss',

  AVG_PROFIT_LOSS = 'valuations.avgProfit-loss',
  AVG_PURCHASE_PRICE = 'valuations.avgPurchasePrice',

  PER_UNIT = 'valuations.per-unit',
  TOTAL_COST = 'valuations.total-cost',
  PROFIT_LOSS_INC_FEES = 'valuations.profit-loss-including-fees',

  SELL = 'sell',
  BUY = 'buy',
  MAKE_OFFER = 'makeOffer',
}

export enum PricingType {
  MARKET = 'market_price',
  CUSTOM = 'custom_price',
}

export enum SellHoldingReasons {
  FOR_REFUND = 'forRefund',
  NEW_PURCHASE = 'newPurchase',
  RE_BALANCE = 'reBalance',
  LOCK_IN_PROFITS = 'lockInProfits',
}

export enum InvestMoreTemplateViewEnum {
  CAMPAIGNS = 'Campaigns',
}

export interface BuySellHoldingModel {
  price: number;
  units: number;
  reason: string;
  pricingType: PricingType;
  investMoreTemplateView?: InvestMoreTemplateViewEnum | null;
}

export enum ViewStateType {
  CAMPAIGN_PURCHASE = 'campaignPurchase',
  TOP_UP_LOADING = 'topupLoading',
  TOP_UP_SUCCESS = 'topupSuccess',
  TOP_UP_ERROR = 'topupError',
  SELECT_PORTFOLIO = 'select_portfolio',
  TOP_UP = 'topup',
  PURCASE_ERROR = 'purchaseError',
  ACCEPT_BID = 'acceptBid',
  ACCEPT_BID_ERROR = 'acceptBidError',

  DEFAULT = 'default',
  THANK_YOU = 'thank_you',
  NO_CONNECTION = 'noConnection',
  SOMETHING_WENT_WRONG = 'somethingWentWrong',
  CUSTOM = 'custom',
  FULL_CUSTOM = 'fullCustom',
  ERROR_RESULT = 'error',
  INVEST_MORE = 'invest_more',
  SELL_HOLDINGS = 'sellHoldings',
  ARRANGE_DELIVERY = 'arrange_delivery',
  LOADING = 'loading',
  MESSAGE = 'Message',
  CONTACT_US = 'contactUs',
  PAYMENT_SUCCESS = 'payment_success',
  SUCCESS = 'success',
}

export enum ProductEventType {
  SHOW_PRODUCT_DETAILS = 'details',
  SHOW_THANK_YOU = 'thank_you',
  SHOW_NO_CONNECTION = 'noConnection',
  SHOW_INVEST_MORE_VIEW = 'invest_more',
  SHOW_SELL_HOLDINGS_VIEW = 'sellHoldings',
  EXECUTE_SELL_PRODUCT_REQUEST = 'execute_sell_product_request',
  EXECUTE_BUY_PRODUCT_REQUEST = 'execute_buy_product_request',
  ARRANGE_DELIVERY = 'arrange_delivery',
  SET_TITLE = 'set_title',
  DEFAULT = 'default',
  // ACCEPT_BID = 'acceptBid',
  // PURCHASE = 'purchase',

  SELL = 'sell',
  BUY = 'buy',
  MAKE_OFFER = 'makeOffer',
}

interface IsSuccessResponseType {
  isSuccess: boolean;
  errorMessage: string;
}

export interface ExecuteResponse {
  portalSellWine?: IsSuccessResponseType;
  portalBuyWine?: IsSuccessResponseType;
  portalDeliverWine?: IsSuccessResponseType;
  data?: IsSuccessResponseType;
}
