import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationPath } from 'types/DomainTypes';
import ProtectedPageLayout from 'views/shared/ProtectedPageLayout';
import { Tabs } from 'components';
import { TabType } from 'components/Tabs';
import { ViewStateType } from 'components/ProductTemplates/types';
import SlideOutPanel from 'components/SlideOutPanel';
import VerifyTopUpAndContinueToPurchase from 'views/shared/SlideOutViews/VerifyTopUpAndContinueToPurchase ';
import PurchaseView from './components/SlideOutView/PurchaseView';
import CreateOfferView from './components/SlideOutView/CreateOfferView';
import BidsAndOffers from './components/BidsAndOffers';
import { SlideoutConfigType, TABLE_ACTIONS, TabTypes } from './types';
import EditOfferView from './components/SlideOutView/EditOfferView';
import AcceptBidView from './components/SlideOutView/AcceptBidView';
import AcceptBidForMyOfferView from './components/SlideOutView/AcceptBidOfMyOfferView';
import TradeHistory from './components/TradeHistory';
import CancelOfferView from './components/SlideOutView/CancelOfferView';

const Trade = () => {
  const { t } = useTranslation();
  const displayText = useMemo(() => {
    return t('trade:');
  }, [t]);

  const [selectedTab, setSelectedTab] = useState<string>(TabTypes.BIDS_AND_OFFERS);
  const [slideOutConfig, setSlideOutConfig] = useState<SlideoutConfigType>({
    showBackButton: false,
    isOpen: false,
    view: ViewStateType.DEFAULT,
    title: '',
    isDetailsFetched: false,
  });

  const onTabSelect = (item: TabType) => {
    setSelectedTab(item?.value);
  };

  const tabs = useMemo(() => {
    const options = [
      {
        id: TabTypes.BIDS_AND_OFFERS,
        value: TabTypes.BIDS_AND_OFFERS,
        title: <span className="text-14">{t('trade:tabs.bidsAndOffers')}</span>,
        content: () => <BidsAndOffers setSlideOutConfig={setSlideOutConfig} slideOutConfig={slideOutConfig} />,
      },
      {
        id: TabTypes.TRADE_HISTORY,
        value: TabTypes.TRADE_HISTORY,
        title: <span className="text-14">{t('trade:tabs.tradeHistory')}</span>,
        content: () => <TradeHistory />,
      },
    ];

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayText, slideOutConfig, setSlideOutConfig]);

  return (
    <ProtectedPageLayout view={NavigationPath.TRADE} title="Trade" onBack={() => null} showBackButton={false}>
      <Tabs className="pb-5" items={tabs} onItemSelect={onTabSelect} value={selectedTab} />
      <VerifyTopUpAndContinueToPurchase />
      <SlideOutPanel
        headClassName="bg-vine"
        onBack={slideOutConfig.onBack}
        showBackButton={slideOutConfig.showBackButton}
        title={slideOutConfig.title}
        isBackgroundDark={true}
        isOpen={slideOutConfig?.isOpen as boolean}
        onClose={() => {
          //  if (slideOutConfig.view === ViewStateType.LOADING) return;
          setSlideOutConfig({ isOpen: false });
        }}
      >
        <div className="flex flex-col flex-1 bg-gradient-to-b from-vine to-gray-500 pb-5 px-3 w-screen relative overflow-x-hidden sm:w-[390px]">
          <div className="flex bg-white overflow-y-auto flex-col flex-1">
            {slideOutConfig.slidoutView === TABLE_ACTIONS.CREATE_OFFER && (
              <CreateOfferView setSlideOutConfig={setSlideOutConfig} slideOutConfig={slideOutConfig} />
            )}

            {slideOutConfig.slidoutView === TABLE_ACTIONS.PURCHASE && (
              <PurchaseView setSlideOutConfig={setSlideOutConfig} slideOutConfig={slideOutConfig} />
            )}

            {slideOutConfig.slidoutView === TABLE_ACTIONS.EDIT_OFFER && (
              <EditOfferView setSlideOutConfig={setSlideOutConfig} slideOutConfig={slideOutConfig} />
            )}

            {slideOutConfig.slidoutView === TABLE_ACTIONS.ACCEPT_HIGHEST_BID && (
              <AcceptBidView setSlideOutConfig={setSlideOutConfig} slideOutConfig={slideOutConfig} />
            )}
            {slideOutConfig.slidoutView === TABLE_ACTIONS.MY_OFFERS_ACCEPT_HIGHEST_BID && (
              <AcceptBidForMyOfferView setSlideOutConfig={setSlideOutConfig} slideOutConfig={slideOutConfig} />
            )}

            {slideOutConfig.slidoutView === TABLE_ACTIONS.CANCEL_OFFER && (
              <CancelOfferView setSlideOutConfig={setSlideOutConfig} slideOutConfig={slideOutConfig} />
            )}
            {/* {slideOutConfig.slidoutView === TABLE_ACTIONS.FILTER && (
              <FilterView setSlideOutConfig={setSlideOutConfig} slideOutConfig={slideOutConfig} />
            )} */}
          </div>
        </div>
      </SlideOutPanel>
    </ProtectedPageLayout>
  );
};

export default Trade;
