import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { formatToObject } from 'views/shared/helpers';
import { fetchOfferDocuments } from 'services';
import { AppContext } from 'context/ContextProvider';
import { AppEventTypes } from 'types/AppType';
import useFetchInvestOffers from '../hooks/useFetchInvestOffers';
import { CampaignOffersQueryKeyEnum, CampaignsOffersType, InvestOffer } from '../types';
import InvestPromotion, { InvestOfferProps } from './InvestPromotion';
import { getCampaignConfig, mockCampaignOffers } from '../helpers';
import { campaignsListType } from '../services/fetchCampaignOffers';

interface CurrentInvestProps {
  onItemSelect: InvestOfferProps['onItemSelect'];
}

export const CurrentInvest: FC<CurrentInvestProps> = ({ onItemSelect }) => {
  const {
    state: {
      app: { campaignPurchaseRef },
    },
    formatter,
    dispatch,
  } = useContext(AppContext);

  const queryClient = useQueryClient();
  const { portalcampaignids, runCampaignOffers } = useFlags();
  // console.log('portalcampaignids ', portalcampaignids);
  const [listOfCampaigns, setListOfCampaigns] = useState<campaignsListType[]>([]);

  const { data: prismicData, isLoading: prismicIsLoading } = useQuery({
    queryFn: () => fetchOfferDocuments({ docStatus: 'current' }),
    queryKey: ['fetchOfferDocuments', 'current'],
  });

  const { data, isLoading } = useFetchInvestOffers({
    listOfCampaigns,
    page: 0,
    pageSize: 500,
  });

  useEffect(() => {
    const list = portalcampaignids as campaignsListType[];
    // mockCampaignOffers as campaignsListType[];
    if (list?.length) {
      setListOfCampaigns([...list]);
    } else {
      setListOfCampaigns([]);
    }
  }, [portalcampaignids, setListOfCampaigns]);

  const isLoadingOffers = useMemo(() => {
    if (!runCampaignOffers) return false;
    return isLoading;
  }, [isLoading, runCampaignOffers]);

  const [campaignOffers, setCampaignOffers] = useState<CampaignsOffersType[]>([]);

  useEffect(() => {
    if (!campaignPurchaseRef) return;
    const updatedOffers: CampaignsOffersType[] = [];
    (data || []).forEach((x) => {
      if (x.id === campaignPurchaseRef.id) {
        const qtyRemainer = x.quantityOutstanding - campaignPurchaseRef.quantity;
        if (qtyRemainer <= 0) return;
        updatedOffers.push({ ...x, quantityOutstanding: qtyRemainer });
        return;
      }
      updatedOffers.push(x);
    });
    dispatch({
      type: AppEventTypes.UPDATE_STATE,
      payload: { campaignPurchaseRef: null },
    });

    setCampaignOffers([...updatedOffers]);
  }, [campaignPurchaseRef, data, dispatch]);

  useEffect(() => {
    if (!runCampaignOffers) return setCampaignOffers([]);
    if (!data) return;
    setCampaignOffers([...data]);
  }, [data, runCampaignOffers]);

  useEffect(() => {
    queryClient.refetchQueries([String(CampaignOffersQueryKeyEnum.fetchCampaignOffers)]);
    // return () => {
    //   queryClient.cancelQueries([String(CampaignOffersQueryKeyEnum.fetchCampaignOffers)]);
    // };
  }, [queryClient]);
  // 2020 @ £295 per 6x75cl
  const currentOffers = useMemo(() => {
    if (prismicIsLoading || isLoadingOffers) return [];
    const offers: InvestOffer[] = campaignOffers?.map((x) => {
      const formatedPrice = formatter.format(Number(x.price?.toFixed(2) || 0));
      return {
        ...x,
        id: x.id,
        type: '',
        name: x.wineName,
        subtitle: `${x.vintage} @ ${formatedPrice} per ${x.unitSize}`,
        priceGbp: x.price,
        price: x?.priceInTargetCurrency ?? x.price,
        unitSize: x.unitSize,
        expiryDate: x.createdDateTime,
        region: x?.region || '',
        status: 'current',
        offerSource: 'LD',
        mainImage: `https://cwcdst2prdctimgimprtfnct.blob.core.windows.net/height/400/${x?.lwin18.substring(0, 7)}.png`,
        sections: [],
        disclaimer: '',
      };
    });

    return [
      ...offers,
      ...(prismicData?.results || []).map((x) => ({ ...x, status: x.type, offerSource: 'prismic', price: x.priceGbp })),
    ] as InvestOffer[];
  }, [campaignOffers, prismicData, prismicIsLoading, isLoadingOffers, formatter]);

  return (
    <InvestPromotion
      offers={currentOffers
        .filter((x) => x.status === 'current')
        .sort((a, b) => new Date(b.orderCreatedDateTime).getTime() - new Date(a.orderCreatedDateTime).getTime())}
      onItemSelect={onItemSelect}
      loading={isLoadingOffers || prismicIsLoading}
    />
  );
};
