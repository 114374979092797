import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchCurrentHoldingVT2 } from 'views/Portfolio/components/Details/services/fetchCurrentHoldings';
import VerifyTopUpAndContinueToPurchase from 'views/shared/SlideOutViews/VerifyTopUpAndContinueToPurchase ';
import { Tabs } from '../../../../components';
import { TabState } from '../../../../components/Layout/SortAndFilterLayout';
import { TabType } from '../../../../components/Tabs';
import { DATA_REFS, Product } from '../../../../types/productType';
import { capitalizeFirstLetter, sumBy, toInternalId, uniqueItems } from '../../../../utils';
import { DEFAULT_LATEST_DEAL_DATE, initialiseCurrentTabVT2, initialiseSoldTab } from '../../helpers';
import { SortByOption } from '../../types';
import StockTab from '../../../../components/StockTab';
import { FilterTypes } from '../../../../components/Filters';
import { AppContext } from '../../../../context/ContextProvider';
import { fetchSoldHoldings } from './services/fetchSoldHoldings';
import { fetchExternalHoldings } from './services/fetchExternalHoldings';

enum TabTypes {
  CURRENT = 'current',
  SOLD = 'sold',
  EXTERNAL = 'external',
}
const HOLDING_TIME_OUT = 1500;
export const Details = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    formatter: currencyFormatter,
    state: {
      settings: { currency },
    },
    gbpToTargetCurrency,
  } = useContext(AppContext);

  const formatter = useMemo(
    () => ({ format: (value: number) => currencyFormatter.format(value, true) }),
    [currencyFormatter],
  );

  const [tabStates, setTabStates] = useState({
    [TabTypes.CURRENT]: null,
    [TabTypes.SOLD]: null,
    [TabTypes.EXTERNAL]: null,
  });
  const currentTabState = useRef<TabState | null>(null);
  const [selectedTab, setSelectedTab] = useState(TabTypes.CURRENT);
  const [searchQuery, setSearchQuery] = useState('');
  const {
    columns,
    sortByOptions,
    defaultSortBy: currentDefaultSortBy,
  } = useMemo(() => {
    return initialiseCurrentTabVT2(t, formatter);
  }, [t, formatter]);

  const {
    columns: soldStockColumns,
    sortByOptions: soldSortByOptions,
    defaultSortBy: soldDefaultSortBy,
  } = useMemo(() => initialiseSoldTab(t, formatter), [t, formatter]);

  const onStateChange = (state: TabState | null) => {
    currentTabState.current = state;
  };

  const onTabSelect = (tab: TabType) => {
    setSelectedTab(tab.value as TabTypes);
    setTabStates({ ...tabStates, [currentTabState.current?.id as TabTypes]: currentTabState.current });
  };

  const {
    data: currentHoldingsVT2,
    isFetching: loadingCurrent,
    error: currentError,
  } = useQuery({
    queryFn: () => fetchCurrentHoldingVT2(),
    queryKey: ['fetchCurrentHoldingVT2', { currency }],
    refetchOnWindowFocus: false,
  });

  const currentHoldings = useMemo(() => {
    return currentHoldingsVT2?.data;
  }, [currentHoldingsVT2?.data]);

  const {
    data: soldStocksVT2,
    isFetching: loadingSoldStocks,
    error: soldStockError,
  } = useQuery({
    queryFn: () => fetchSoldHoldings(),
    queryKey: ['fetchSoldHoldings', { currency }],
    refetchOnWindowFocus: false,
  });

  const soldStocks = useMemo(() => {
    return soldStocksVT2?.data;
  }, [soldStocksVT2?.data]);

  const refreshCurrentStocksTotals = (tableData: Product[]) => {
    const totalHoldings = sumBy(tableData, 'totalValue');
    const totalPurchase = sumBy(tableData, 'totalCost');
    //  const totalMgmtFee = sumBy(tableData, 'totalMgmtFee');
    const totalCostWithMgmtFee = sumBy(tableData, 'totalCostWithMgmtFee');
    const totalProfitAndLoss = totalHoldings - totalPurchase;
    const totalPAndLPercent = (totalProfitAndLoss / totalPurchase) * 100;
    const totalPAndLWithFees = totalHoldings - totalCostWithMgmtFee;
    const totalPAndLWithFeesPAndL = (totalPAndLWithFees / totalPurchase) * 100;
    return [
      {
        title: t('portfolio:summary.texts.portfolioValuation'),
        value: totalHoldings,
        text: `${formatter.format(totalHoldings)}`,
      },
      {
        title: t('portfolio:titles.details_totals.total-purchase-price'),
        value: totalPurchase,
        text: `${formatter.format(totalPurchase)}`,
      },
      {
        title: t('portfolio:titles.details_totals.total-profit-loss'),
        value: totalProfitAndLoss,
        text: `${formatter.format(totalProfitAndLoss)}`,
        additionalText: ` (${totalPAndLPercent.toFixed(2)}%)`,
        color: totalProfitAndLoss > 0 ? '#68CA5D' : '#FF5C5C',
      },
    ];
  };

  const refreshSoldStocksTotals = (tableData: Product[]) => {
    const totalHoldings = sumBy(tableData, 'totalValue');
    const totalPurchase = sumBy(tableData, 'totalCost');
    const totalCostWithMgmtFee = sumBy(tableData, 'totalCostWithMgmtFee');
    const totalProfitAndLoss = totalHoldings - totalPurchase;
    const totalPAndLPercent = (totalProfitAndLoss / totalPurchase) * 100;
    const totalPAndLWithFees = totalHoldings - totalCostWithMgmtFee;
    const totalPAndLWithFeesPAndL = (totalPAndLWithFees / totalPurchase) * 100;
    return [
      {
        title: t('portfolio:titles.details_totals.total-sold-value'),
        value: totalHoldings,
        text: `${formatter.format(totalHoldings)}`,
      },
      {
        title: t('portfolio:titles.details_totals.total-purchase-price'),
        value: totalPurchase,
        text: `${formatter.format(totalPurchase)}`,
      },
      {
        title: t('portfolio:titles.details_totals.total-profit-loss'),
        value: totalProfitAndLoss,
        text: `${formatter.format(totalProfitAndLoss)}`,
        additionalText: ` (${totalPAndLPercent.toFixed(2)}%)`,
        color: totalProfitAndLoss > 0 ? '#68CA5D' : '#FF5C5C',
      },
    ];
  };

  // useEffect(() => {
  //   const hashValue = window.location.hash;
  //   const isFindEvent = Boolean(hashValue && hashValue.startsWith(`#${MyPortfolioEvent.FIND}=`));

  //   if (!isFindEvent) return;

  //   const ref = atob(decodeURIComponent(hashValue.replace(`#${MyPortfolioEvent.FIND}=`, '')));
  //   if (ref) {
  //     const wineInfo = JSON.parse(ref) as campaignPurchaseRefType;
  //     console.log('wineInfo ', wineInfo);
  //     setSearchQuery(wineInfo.name || '');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [window.location.hash, setSearchQuery]);

  const tabs = useMemo(
    () => [
      {
        id: TabTypes.CURRENT,
        value: TabTypes.CURRENT,
        title: <span className="text-14">{capitalizeFirstLetter(t(`portfolio:${TabTypes.CURRENT}`))}</span>,
        content: () => (
          <StockTab
            defaultSortKey={DATA_REFS.NAME}
            disableSort={true}
            searchQueryIn={searchQuery}
            refreshTotals={refreshCurrentStocksTotals}
            showCompactSwitch={true}
            columns={columns}
            sortByOptions={sortByOptions as SortByOption[]}
            defaultSortBy={currentDefaultSortBy}
            tabState={tabStates[selectedTab]}
            filterPanelContainerClassName="px-5"
            id={TabTypes.CURRENT}
            datasource={((currentHoldings || []) as Product[]).map((x) => {
              let modifiedCurrentHoldings: Product = x;
              if (x?.highestBid?.price) {
                modifiedCurrentHoldings = {
                  ...modifiedCurrentHoldings,
                  highestBid: {
                    ...x.highestBid,
                    priceInTargetCurrency: gbpToTargetCurrency.convert(x.highestBid?.price ?? 0),
                    priceGbp: x.highestBid.price,
                  },
                };
              }

              if (x?.lowestOffer?.price) {
                modifiedCurrentHoldings = {
                  ...modifiedCurrentHoldings,
                  lowestOffer: {
                    ...x.lowestOffer,
                    priceInTargetCurrency: gbpToTargetCurrency.convert(x.lowestOffer?.price ?? 0),
                    priceGbp: x.lowestOffer.price,
                  },
                };
              }

              return {
                ...modifiedCurrentHoldings,
                latestDealDate: x.latestDealDate ? new Date(x.latestDealDate) : DEFAULT_LATEST_DEAL_DATE,
                highestBidPrice:
                  modifiedCurrentHoldings?.physicalQuantity && modifiedCurrentHoldings.physicalQuantity > 0
                    ? modifiedCurrentHoldings?.highestBid?.priceInTargetCurrency ?? 0
                    : null,
                lowestOfferPrice: modifiedCurrentHoldings?.lowestOffer?.priceInTargetCurrency ?? 0,
                [DATA_REFS.SANITIZED_WINE_NAME]: x[DATA_REFS.NAME].normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
              };
            })}
            onStateChange={onStateChange}
            loading={loadingCurrent}
            error={currentError ? new Error(JSON.stringify(currentError)) : null}
          />
        ),
      },
      {
        id: TabTypes.SOLD,
        value: TabTypes.SOLD,
        title: <span className="text-14">{capitalizeFirstLetter(t(`portfolio:${TabTypes.SOLD}`))}</span>,
        content: () => (
          <StockTab
            disableSort={true}
            refreshTotals={refreshSoldStocksTotals}
            columns={soldStockColumns}
            sortByOptions={soldSortByOptions}
            defaultSortBy={soldDefaultSortBy}
            disableTableClick={true}
            tabState={tabStates[selectedTab]}
            id={TabTypes.SOLD}
            filterOverrides={{
              [FilterTypes.STATUES]: {
                source: [t('portfolio:filters.processing'), t('portfolio:filters.sold')],
                filterFn: (data: Product[], ids: string[]) => {
                  let statusesFilterResult: Product[] = [];
                  uniqueItems(data.map((x) => x.status)).forEach((status) => {
                    if (ids.includes(toInternalId(status!))) {
                      statusesFilterResult = [
                        ...statusesFilterResult,
                        ...data.filter((x) => x.status?.toLowerCase() === status?.toLowerCase()),
                      ];
                    }
                  });
                  return statusesFilterResult;
                },
              },
            }}
            filterPanelContainerClassName="px-5"
            datasource={((soldStocks || []) as Product[]).map((x, i) => ({
              ...x,
              lwin18: `${i + 1}`,
              [DATA_REFS.SANITIZED_WINE_NAME]: x[DATA_REFS.NAME].normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
            }))}
            onStateChange={onStateChange}
            loading={loadingSoldStocks}
            error={soldStockError ? new Error(JSON.stringify(soldStockError)) : null}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, currentHoldings, loadingCurrent, currentError, soldStocks, loadingSoldStocks, soldStockError, selectedTab],
  );
  useEffect(() => {
    // reFetchCurrentHoldings();
    // reFetchSoldStock();
    // reFetchExternalStock();
    if (0) {
      fetchExternalHoldings();
    }
  }, [currency]);

  return (
    <div className="h-100 flex-1 bg-gray-100 flex overflow-y-auto flex-col">
      <Tabs className="pb-5" items={tabs} onItemSelect={onTabSelect} value={selectedTab} />
      <VerifyTopUpAndContinueToPurchase
        config={{
          success: {
            onSuccess: () => {
              setTimeout(() => {
                queryClient.resetQueries({ queryKey: ['fetchCurrentHoldingVT2', { currency }] });
              }, HOLDING_TIME_OUT);
            },
          },
        }}
      />
    </div>
  );
};
