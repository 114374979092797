import { httpGet } from 'utils';

interface IBookGroupsResponse {
  bookGroupId: string;
  userId: string;
  assetAccountId: string;
  cashAccountId: string;
  operatedBy: string;
  operationSource: string;
  buyOrSell: string;
  status: 'Pending' | 'Completed' | 'Failed' | 'PartiallyFailed' | 'Created';
  referentId: number;
  packageId: number;
  createdDateTime: string;
  updatedDateTime: string;
}

export const fetchBookGroups = (orderGroupId: string | number) => {
  // localhost:7071/api/BookGroups/882db7b9-342e-45a7-90a2-4ace973f5859
  const path = `Portal/BookGroups/${orderGroupId}`;
  return httpGet<IBookGroupsResponse>(path);
};

// export const fetchOffer = (): Promise<BidsResponse> =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(mockBids);
//     }, 2000);
//   });
