import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button } from 'components';
import { ViewStateType } from 'components/ProductTemplates/types';
import Loading from '../../../components/Loading/loading';
import ImageCard from '../../../components/ProductTemplates/components/ImageCard';
import Spacer from '../../../components/Spacer';
import { AppContext } from '../../../context/ContextProvider';
import { getRegions, handleImageError } from '../../../helpers';
import { capitalizeFirstLetter, toInternalId } from '../../../utils';
import useFadeInOnScroll from '../../hooks/useFadeInOnScroll';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { InvestOffer } from '../types';
import SharedTemplate from '../../../components/ProductTemplates/components/MessageTemplate';
import noResultImage from '../../../assets/images/no_results_image.png';
import { isAllowedCampaignPurchase } from '../helpers';

enum NoResultsTextKeys {
  TITLE = 'title',
  SUBTITLE = 'subTitle',
}
export interface InvestOfferProps {
  offers: InvestOffer[];
  onItemSelect: (offer: InvestOffer, viewIn?: ViewStateType) => void;
  loading?: boolean;
}
const lowStockQty = 5;
const InvestPromotion: FC<InvestOfferProps> = ({ offers, onItemSelect, loading = false }) => {
  const {
    state: {
      settings: { accountInfo },
    },
  } = useContext(AppContext);

  const { t } = useTranslation();
  const regions = useMemo(() => getRegions(t), [t]);
  const { formatter: currencyFormatter } = useContext(AppContext);
  const { isLoading, results, lastItemRef } = useInfiniteScroll(offers);
  const processIntersectionObserverEntry = useCallback((entry: IntersectionObserverEntry) => {
    entry.target.classList.toggle('flex', entry.isIntersecting);
  }, []);

  const { isItemVisible, fadeOnScrollClassName } = useFadeInOnScroll({ isLoading, processIntersectionObserverEntry });

  return (
    <div className="flex flex-wrap justify-center  h-full w-full gap-[15px] flex-1 overflow-x-hidden bg-gray-100 p-5 sm:p-7 overflow-y-auto">
      {loading && <Loading />}
      {!loading && results.length === 0 && (
        <div className="animate-[fade-in_5s_ease-out] w-full h-full">
          <SharedTemplate
            showButton={false}
            displayTextKeys={NoResultsTextKeys}
            translationKey="common:noResult"
            imageSrc={noResultImage}
            title={
              <div className="flex items-center gap-3">
                <span className="text-20 font-medium ">{t('common:noResult.title')}</span>
              </div>
            }
          />
        </div>
      )}
      {!loading &&
        results.length > 0 &&
        results.map((item, index) => {
          const {
            id,
            name: title,
            subtitle: subTitle,
            price,
            region,
            unitSize: unit,
            mainImage,
            expiryDate,
            initialQuantity,
            quantityOutstanding,
            offerSource,
            lwin18,
            numberOfUnitsAllowed,
            campaignId,
          } = item;

          const { qty: qtyRemaining, isAllowed } = isAllowedCampaignPurchase({
            campaignId,
            lwin18: lwin18,
            quantity: quantityOutstanding,
            numberOfUnitsAllowed,
            id: accountInfo?.portfolios?.map((x) => x.portfolioName).toString(),
          });

          let lastElemRefOption = { ref: isItemVisible };
          if (results.length === index + 1) {
            lastElemRefOption = { ref: lastItemRef };
          }
          const expDate = `${moment(expiryDate).format('YYYY-MM-DD')}`;
          const selectedRegion = regions.find((x) => x.id === toInternalId(region.toLowerCase()));
          const regionColor = selectedRegion?.color;
          const textColor = `text-${selectedRegion?.textColor || 'black'}`;

          const image = (
            <div className="w-[100px] ">
              <img
                className={`img ${offerSource !== 'prismic' ? 'h-[133px] object-contain' : ''} `}
                alt={title}
                src={mainImage}
                onError={handleImageError}
              />
            </div>
          );

          //  274.67 142.33  w-[274.67px] h-[142.33px]
          return (
            <ImageCard
              {...lastElemRefOption}
              key={`${id}`}
              id={`${id}`}
              image={image}
              onClick={() =>
                offerSource === 'prismic' ? onItemSelect(item) : onItemSelect(item, ViewStateType.INVEST_MORE)
              }
              className={` w-full sm:w-[300px] text-left !h-[405px] border border-[#1D4854] ${fadeOnScrollClassName}`}
              imageClassName="w-full !max-h-[142.33px]"
            >
              <>
                <div className="relative space-y-[4px] flex flex-col border-none w-full">
                  <div className="line-clamp-2 break-words text-[24px] w-full sm:w-[300px] pr-5">{title}</div>
                  <p className="text-14 whitespace-nowrap truncate">{subTitle}</p>
                </div>
                <div className="relative flex flex-col border-none w-full flex-1">
                  <h1 className="text-md whitespace-nowrap truncate">
                    {currencyFormatter.format(Number(price), true)}
                  </h1>
                  <div className="flex flex-row justify-between items-center w-full">
                    <div className="text-sm whitespace-nowrap truncate">{unit}</div>
                    <div
                      className={`text-xs whitespace-nowrap truncate ${
                        quantityOutstanding <= lowStockQty && offerSource !== 'prismic'
                          ? '  font-bold text-[#D46666]'
                          : ''
                      }`}
                    >
                      {offerSource !== 'prismic'
                        ? quantityOutstanding <= lowStockQty
                          ? t('invest:lowStock')
                          : t('invest:campaign_stock_date')
                        : expDate}
                    </div>
                  </div>
                </div>
                {offerSource === 'prismic' && (
                  <div className="flex flex-row items-center justify-between  w-full">
                    <div
                      style={{ background: regionColor! }}
                      className="flex  justify-center items-center border w-fit px-3 py-[2px] rounded-full h-auto mt-2"
                    >
                      <div className={`w-fit whitespace-nowrap text-center font-semibold text-sm ${textColor}`.trim()}>
                        {capitalizeFirstLetter(region)}
                      </div>
                    </div>

                    <Button
                      isDisable={!isAllowed}
                      className={` text-14 font-normal rounded-full btn  !h-[30px] flex justify-center bg-orange  items-center  text-black disabled:bg-gray-500 `.trim()}
                      onClick={() => {
                        onItemSelect(item);
                      }}
                      props={{
                        name: 'requestTab',
                      }}
                    >
                      Invest now
                    </Button>
                  </div>
                )}

                {offerSource !== 'prismic' && (
                  <div
                    className="flex flex-row items-center  w-full justify-between"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className={`flex  justify-center items-center border w-fit px-3 py-[2px] rounded-full !h-[30px] btn ${
                        quantityOutstanding <= lowStockQty ? ' border-2  border-[#D46666] p-1' : ''
                      }`}
                    >
                      <div className="w-fit whitespace-nowrap text-center text-sm">
                        Quantity: {quantityOutstanding} / {initialQuantity}
                      </div>
                    </div>

                    <Button
                      isDisable={!isAllowed}
                      className={` text-14 font-normal rounded-full btn  !h-[30px] flex justify-center bg-orange  items-center  text-black disabled:bg-gray-500 `.trim()}
                      onClick={() => {
                        onItemSelect(item, ViewStateType.INVEST_MORE);
                      }}
                      props={{
                        name: 'requestTab',
                      }}
                    >
                      Invest now
                    </Button>
                  </div>
                )}
              </>
            </ImageCard>
          );
        })}
      <Spacer h="100px" />
    </div>
  );
};

export default InvestPromotion;
