import { httpPost } from 'utils';

export interface IAcceptHighestBidBody {
  accountId: string;
  description: string;
  items: [
    {
      lwin18: string;
      stopLimitPerUnit: number;
      stopLimitType: string | undefined | number;
      priceType?: 'AcceptHighestBid';
      quantity: number;
    },
  ];
}

interface IResult {
  orderGroupId: string;
  buyOrSell: 'S';
  accountId: string;
  orders: {
    offerId: string | null;
    clientOrderId: string;
    lwin18: string;
    price: number;
    quantity: number;
  };
}

interface IAcceptHighestBidResponse {
  result: IResult;
  status: 'Succeed' | 'Failed';
  errorMessage: null | string;
}

export const acceptHighestBid = (body?: IAcceptHighestBidBody) => {
  const path = `portal/Vintrade/Orders/Sell`;
  return httpPost<IAcceptHighestBidResponse, IAcceptHighestBidBody>(path, body);
};
