import { httpGet } from 'utils';

export interface NotificationPreferencesType {
  categoryId: string;
  email: boolean;
  categoryTypeName: string;
}

interface NotificationPreferencesResult {
  preferences: NotificationPreferencesType[];
}

export const getNotificationPreferencesApi = () =>
  httpGet<NotificationPreferencesResult>('portal/NotificationPreferences');
