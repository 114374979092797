import { TFunction } from 'i18next';
import { TableCell, TableColumnType, TableRow } from 'components/Table';
import { DropdownItem } from 'components/Dropdown';
import { ViewStateType } from 'components/ProductTemplates/types';
import { GbpToTargetCurrency } from 'types/commonTypes';
import { getRegions } from 'helpers';
import { classNames, toInternalId } from 'utils';
import moment from 'moment';
import { forwardRef } from 'react';
import { SnackbarContent } from 'notistack';
import {
  DisplayTextKeys,
  IGenerateRowsFormat,
  IGenerateTableActions,
  IRowData,
  MARKET_REFS,
  MY_OFFERS_REFS,
  OffersType,
  PortfolioOffersType,
  SUB_TABS_ENUMS,
  SetSlideoutConfigType,
  SlideoutConfigType,
  TABLE_ACTIONS,
  TRADE_HISTORY_REFS,
  TabsPops,
  TradeHistoryType,
} from '../types';
import tradeBottle1 from '../../../assets/images/tradeBottle1.png';
import tradeBottle2 from '../../../assets/images/tradeBottle2.png';
import Loading from 'components/Loading/loading';
import { FiltersType } from '../components/SlideOutView/FilterView';

const cellClassName = 'text-center text-sm text-black cursor-pointer ';
const cellClassName2 = 'text-center text-sm text-black cursor-pointer whitespace-nowrap py-5';
const colSmall = 'w-[60px] max-w-[60px] min-w-[60px]';
const colMedium = 'w-[90px] max-w-[90px] min-w-[90px]';
const getActionButtonText = (actionText: TABLE_ACTIONS, translation: TFunction) => {
  return translation(`trade:common.actions.${actionText}`);
  // switch (actionText) {
  //   case TABLE_ACTIONS.ACCEPT_HIGHEST_BID:
  //     return 'Accept Bid';
  //   case TABLE_ACTIONS.MY_OFFERS_ACCEPT_HIGHEST_BID:
  //     return 'Accept Bid';
  //   case TABLE_ACTIONS.CREATE_OFFER:
  //     return 'Create Offer';
  //   case TABLE_ACTIONS.EDIT_OFFER:
  //     return 'Edit Offer';
  //   case TABLE_ACTIONS.PURCHASE:
  //     return 'Purchase';
  //   default:
  //     return '';
  // }
};

const onClickButton = (
  translation: TFunction,
  row: IRowData,
  action: TABLE_ACTIONS,
  slideOutConfig: SlideoutConfigType,
  setSlideOutConfig: SetSlideoutConfigType,
  searchText: string,
) => {
  const {
    lwin18,
    wineName,
    quantityOutstanding: quantity,
    vintage,
    marketValueInTargetCurrency: marketValue,
    unitSize,
  } = row;
  setSlideOutConfig({
    searchText: searchText,
    innerViewState: ViewStateType.DEFAULT,
    view: ViewStateType.DEFAULT,
    isOpen: true,
    slidoutView: action,
    offer: row,
    title: getActionButtonText(action, translation),
    wineInfoTemplateConfig: {
      lwin18,
      marketValue,
      quantity,
      unitSize,
      vintage,
      wineName,
      cultWinesAllocationRegion: row?.cultWinesAllocationRegion ?? '',
      region: row?.region,
    },
  });
};

export const InitialiseMarketTable = (
  translation: TFunction,
  slideOutConfig: SlideoutConfigType,
  setSlideOutConfig: SetSlideoutConfigType,
  gbpToTargetCurrency: GbpToTargetCurrency,
  searchText: string,
) => {
  const MARKET_TABLE_TEXTS = {
    [MARKET_REFS.ID]: 'id',
    [MARKET_REFS.REGION]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.REGION}`),
    [MARKET_REFS.WINENAME]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.WINENAME}`),
    [MARKET_REFS.VINTAGE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.VINTAGE}`),
    [MARKET_REFS.UNITSIZE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.UNITSIZE}`),
    [MARKET_REFS.LWIN18]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.LWIN18}`),
    [MARKET_REFS.QUANTITY]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.QUANTITY}`),
    [MARKET_REFS.MARKET_VALUE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.MARKET_VALUE}`),
    [MARKET_REFS.TOTAL_MARKET_VALUE]: translation(
      `trade:offersAndBids.table.headers.${MARKET_REFS.TOTAL_MARKET_VALUE}`,
    ),
    [MARKET_REFS.HIGHEST_BID]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.HIGHEST_BID}`),
    [MARKET_REFS.LOWEST_OFFER]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.LOWEST_OFFER}`),
    [MARKET_REFS.OFFER]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.OFFER}`),
    [MARKET_REFS.LAST_TRADE_PRICE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.LAST_TRADE_PRICE}`),
    [MARKET_REFS.PRODUCER]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.PRODUCER}`),
    [MARKET_REFS.VINOUS_SCORE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.VINOUS_SCORE}`),
    [MARKET_REFS.WA_SCORE]: translation(`trade:offersAndBids.table.headers.${MARKET_REFS.WA_SCORE}`),
    [MARKET_REFS.PRICE_RANGE]: translation(`trade:common.${MARKET_REFS.PRICE_RANGE}`),
    [MARKET_REFS.SIZE]: translation(`trade:common.${MARKET_REFS.SIZE}`),
  };

  const marketColumns: TableColumnType[] = [
    {
      dataRef: MARKET_REFS.REGION,
      text: MARKET_TABLE_TEXTS[MARKET_REFS.REGION],
      cellClassName: 'text-left text-sm text-black cursor-pointer',
      className: 'w-[110px] min-w-[110px] max-w-[110px] border border-gray-200 px-2',
      cellContentTemplate: (cell: TableCell, t: TFunction, rowData: TableRow['rowData']) => {
        const row = rowData as unknown as OffersType;
        const regionColors = getRegions();
        return (
          <div className="flex items-center w-[110px] min-w-[110px] max-w-[110px]">
            <div
              style={{
                backgroundColor: regionColors.find((x) => x.id === toInternalId(`${row?.cultWinesAllocationRegion}`))
                  ?.color,
              }}
              className="min-w-[5px] max-w-[5px] h-[80px] mr-3"
            />
            <span className="mr-3">{`${row.region}`}</span>
          </div>
        );
      },
    },

    {
      dataRef: MARKET_REFS.VINTAGE,
      text: MARKET_TABLE_TEXTS[MARKET_REFS.VINTAGE],
      cellClassName: `text-sm text-black text-center cursor-pointer px-2  `,
      className: 'max-w-[75px] w-[75px] min-w-[75px] border border-gray-200 text-center',
      contentTemplate: (col) => <span className="text-center w-full">{col.text}</span>,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as OffersType;
        return <span className="max-w-[75px] w-[75px] min-w-[75px]">{`${row.vintage}`}</span>;
      },
    },

    {
      dataRef: MARKET_REFS.WINENAME,
      text: MARKET_TABLE_TEXTS[MARKET_REFS.WINENAME],
      cellClassName: `text-sm text-black cursor-pointer px-2 flex-1`,
      className: 'flex flex-1 max-w-[320px] w-[320px] min-w-[320px] px-2 ',
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as OffersType;
        return (
          <div className="flex flex-row items-center justify-between flex-1">
            <span>{`${row.wineName} `}</span>
          </div>
        );
      },
    },

    {
      dataRef: MARKET_REFS.WA_SCORE,
      text: MARKET_TABLE_TEXTS[MARKET_REFS.WA_SCORE],
      cellClassName,
      contentTemplate: (col) => <span className="text-center w-full">{col.text}</span>,
      className: 'min-w-[65px] w-[65px] max-w-[65px] border border-gray-200 text-center',
      cellContentTemplate: (cell: TableCell) => (
        <span>{cell.text && cell.text !== 'nan' ? Math.ceil(Number(cell.text)) : '--'}</span>
      ),
    },
    {
      dataRef: MARKET_REFS.VINOUS_SCORE,
      text: MARKET_TABLE_TEXTS[MARKET_REFS.VINOUS_SCORE],
      cellClassName,
      contentTemplate: (col) => <span className="text-center w-full">{col.text}</span>,
      className: 'min-w-[65px] w-[65px] max-w-[65px]  border border-gray-200 text-center',
      cellContentTemplate: (cell: TableCell) => (
        <span>{cell.text && cell.text !== 'nan' ? Math.ceil(Number(cell.text)) : '--'}</span>
      ),
    },

    {
      dataRef: MARKET_REFS.UNITSIZE,
      text: MARKET_TABLE_TEXTS[MARKET_REFS.UNITSIZE],
      cellClassName,
      className: 'min-w-[80px] w-[80px] max-w-[80px] border border-gray-200 ',
      contentTemplate: (col) => <span className="text-center w-full whitespace-nowrap">{col.text}</span>,
      cellContentTemplate: (cell: TableCell) => <span>{cell.text}</span>,
    },
    {
      dataRef: MARKET_REFS.HIGHEST_BID_PRICE,
      text: MARKET_TABLE_TEXTS[MARKET_REFS.HIGHEST_BID],
      cellClassName: `${cellClassName} !text-center`,
      contentTemplate: (col) => <span className="text-center w-full">{col.text}</span>,
      className: 'w-[90px] max-w-[90px] min-w-[90px] text-center border border-gray-200 px-1',
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as IRowData;
        return row.highestBid?.price && row.actions.includes(TABLE_ACTIONS.ACCEPT_HIGHEST_BID) ? (
          <div className="flex flex-col px-2 bg-[#B1DED8CC] rounded-[10px] m-1 h-[75px] items-center justify-center gap-[2px]">
            <span>
              {row.highestBid?.price
                ? gbpToTargetCurrency.convertAndFormat(Number(row.highestBid?.price || 0))?.split('.')?.[0]
                : ''}
            </span>

            <p className="text-xs">Qty: {row.highestBid?.quantityOutstanding}</p>
            {row.actions.includes(TABLE_ACTIONS.ACCEPT_HIGHEST_BID) && (
              <button
                className={` text-black font-bold bg-orange p-1 text-xs whitespace-nowrap rounded-[10px] w-full`}
                onClick={() =>
                  onClickButton(
                    translation,
                    row,
                    TABLE_ACTIONS.ACCEPT_HIGHEST_BID,
                    slideOutConfig,
                    setSlideOutConfig,
                    searchText,
                  )
                }
              >
                {getActionButtonText(TABLE_ACTIONS.ACCEPT_HIGHEST_BID, translation)}
              </button>
            )}
          </div>
        ) : null;
      },
    },
    {
      dataRef: MARKET_REFS.LOWEST_OFFER_PRICE,
      text: MARKET_TABLE_TEXTS[MARKET_REFS.OFFER],
      cellClassName,
      className: `${colMedium} border border-gray-200`,
      contentTemplate: (col) => <span className="text-center w-full">{col.text}</span>,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as IRowData;
        return (
          <div className="flex flex-col px-2 bg-[#F6D46BCC] rounded-[10px] m-1 h-[75px] items-center justify-center gap-[2px]">
            <span>
              {row.lowestOffer?.price
                ? gbpToTargetCurrency.convertAndFormat(Number(row.lowestOffer?.price || 0))?.split('.')?.[0]
                : ''}
            </span>

            <p className="text-xs">Qty: {row.quantityOutstanding}</p>

            {row.actions.includes(TABLE_ACTIONS.PURCHASE) && (
              <button
                className={`text-black font-bold bg-orange p-1 text-xs whitespace-nowrap rounded-[10px] w-full`}
                onClick={() =>
                  onClickButton(translation, row, TABLE_ACTIONS.PURCHASE, slideOutConfig, setSlideOutConfig, searchText)
                }
              >
                {getActionButtonText(TABLE_ACTIONS.PURCHASE, translation)}
              </button>
            )}
          </div>
        );
      },
    },
    {
      dataRef: MARKET_REFS.LAST_TRADE_PRICE,
      text: MARKET_TABLE_TEXTS[MARKET_REFS.LAST_TRADE_PRICE],
      cellClassName,
      className: `${colMedium} border border-gray-200`,
      contentTemplate: (col) => <span className="text-center w-full">{col.text}</span>,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as OffersType;
        return (
          <div className="flex flex-col">
            <span className="px-2">
              {row?.lastTradePrice
                ? gbpToTargetCurrency.convertAndFormat(Number(row.lastTradePrice || 0))?.split('.')?.[0]
                : ''}
            </span>
            <span className="text-orange text-xs">
              {row?.tradeDateTime ? moment(row?.tradeDateTime).format('YYYY/MM/DD') : ''}
            </span>
          </div>
        );
      },
    },

    {
      dataRef: MARKET_REFS.ID,
      text: MARKET_REFS.ID,
      isVisible: false,
    },
  ];

  //  highestBid: data.highestBid?.price,
  //               quantity: data.initialQuantity,
  //               totalMarketValue: data.quantityOutstanding * data.marketValue,
  // const sortByOptions = columns
  //   .filter((x) => x.isVisible === true || x.isVisible === undefined)
  //   .map((col) => ({
  //     id: col.dataRef,
  //     value: col.dataRef,
  //     text: col.text,
  //     content: <span>{col.text}</span>,
  //   }));

  // const defaultSortBy = () => {
  //   const { id, text } = sortByOptions.find((x) => x.id === MARKET_REFS.ID)!;
  //   return {
  //     id,
  //     text,
  //     direction: SortDirection.DESCENDING,
  //   };
  // };

  return {
    marketColumns,
    MARKET_TABLE_TEXTS,
  };
};

export const InitialiseMyOffersTable = (
  translation: TFunction,
  slideOutConfig: SlideoutConfigType,
  setSlideOutConfig: SetSlideoutConfigType,
  gbpToTargetCurrency: GbpToTargetCurrency,
  searchText: string,
) => {
  const MY_OFFERS_TABLE_TEXTS = {
    [MY_OFFERS_REFS.ID]: 'id',
    [MY_OFFERS_REFS.ACTIONS]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.ACTIONS}`),
    [MY_OFFERS_REFS.WINENAME]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.WINENAME}`),
    [MY_OFFERS_REFS.LWIN18]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.LWIN18}`),
    [MY_OFFERS_REFS.VINTAGE]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.VINTAGE}`),
    [MY_OFFERS_REFS.UNITSIZE]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.UNITSIZE}`),

    [MY_OFFERS_REFS.OFFER_PRICE]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.OFFER_PRICE}`),
    [MY_OFFERS_REFS.OFFER_QUANTITY]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.OFFER_QUANTITY}`),
    [MY_OFFERS_REFS.QUANTITY_TO_SELL]: translation(
      `trade:offersAndBids.table.headers.${MY_OFFERS_REFS.QUANTITY_TO_SELL}`,
    ),
    [MY_OFFERS_REFS.HIGHEST_BID]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.HIGHEST_BID}`),
    [MY_OFFERS_REFS.STOP_LIMIT]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.STOP_LIMIT}`),
    [MY_OFFERS_REFS.WAC]: translation(`trade:offersAndBids.table.headers.${MY_OFFERS_REFS.WAC}`),
    [MY_OFFERS_REFS.PHYSICAL_QUANTITY]: translation(
      `trade:offersAndBids.table.headers.${MY_OFFERS_REFS.PHYSICAL_QUANTITY}`,
    ),
    [MY_OFFERS_REFS.CREATED_DATE_TIME]: translation(
      `trade:offersAndBids.table.headers.${MY_OFFERS_REFS.CREATED_DATE_TIME}`,
    ),
  };

  const myOffersColumns: TableColumnType[] = [
    {
      dataRef: MY_OFFERS_REFS.ACTIONS,
      className: 'text-center px-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.ACTIONS],
      cellClassName,
      cellContentTemplate: (cell: TableCell, _, rowData) => {
        const row = rowData as unknown as IRowData;
        return (
          <div className="flex justify-center gap-2 px-2">
            {row.actions.map((action: TABLE_ACTIONS) => {
              let style;
              switch (action) {
                case TABLE_ACTIONS.MY_OFFERS_ACCEPT_HIGHEST_BID:
                  style = 'bg-vine text-white';
                  break;
                case TABLE_ACTIONS.CANCEL_OFFER:
                  style = 'text-white  bg-trenddown';
                  break;

                default:
                  style = '';
                  break;
              }
              return (
                <button
                  key={action}
                  className={`border p-1 rounded-lg ${style}`}
                  onClick={() => onClickButton(translation, row, action, slideOutConfig, setSlideOutConfig, searchText)}
                >
                  {getActionButtonText(action, translation)}
                </button>
              );
            })}
          </div>
        );
      },
    },
    {
      dataRef: MY_OFFERS_REFS.WINENAME,
      className: 'pr-5 ',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.WINENAME],
      cellClassName: 'text-sm text-black cursor-pointer whitespace-nowrap px-2 py-5',
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as PortfolioOffersType;
        return <span>{`${row.vintage} ${row.wineName} `}</span>;
      },
    },

    {
      dataRef: MY_OFFERS_REFS.UNITSIZE,
      className: 'text-center pl-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.UNITSIZE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{cell.text}</span>,
    },
    {
      dataRef: MY_OFFERS_REFS.OFFER_PRICE,
      className: 'text-center pl-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.OFFER_PRICE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => {
        return <span>{gbpToTargetCurrency.convertAndFormat(Number(cell.text))}</span>;
      },
    },
    {
      dataRef: MY_OFFERS_REFS.OFFER_QUANTITY,
      className: 'text-center pl-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.OFFER_QUANTITY],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span>{cell.text}</span>,
    },
    {
      dataRef: MY_OFFERS_REFS.HIGHEST_BID,
      className: 'text-center pl-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.HIGHEST_BID],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as OffersType;
        return (
          <span>
            {row.highestBid?.price ? gbpToTargetCurrency.convertAndFormat(Number(row.highestBid?.price || 0)) : ''}
          </span>
        );
      },
    },
    {
      dataRef: MY_OFFERS_REFS.STOP_LIMIT,
      className: 'text-center px-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.STOP_LIMIT],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span>{gbpToTargetCurrency.convertAndFormat(Number(cell.text))}</span>,
    },
    {
      dataRef: MY_OFFERS_REFS.CREATED_DATE_TIME,
      className: 'text-center px-5',
      text: MY_OFFERS_TABLE_TEXTS[MY_OFFERS_REFS.CREATED_DATE_TIME],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{cell.text?.split('T')[0]}</span>,
    },

    {
      dataRef: MY_OFFERS_REFS.ID,
      text: MY_OFFERS_REFS.ID,
      isVisible: false,
    },
  ];

  return {
    myOffersColumns,
  };
};

const dateTimeSplitFunc = (dateTime: string) => {
  const date = new Date(dateTime).toLocaleDateString('en', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  });

  try {
    return { date: date.split(',')[0], time: date.split(',')[1] };
  } catch (error) {
    return { date: '', time: '' };
  }
};
export const InitialiseTradeHistoryTable = (translation: TFunction, gbpToTargetCurrency: GbpToTargetCurrency) => {
  const TRADE_HISTORY_TEXTS = {
    [TRADE_HISTORY_REFS.DATE]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.DATE}`),
    [TRADE_HISTORY_REFS.TIME]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.TIME}`),
    [TRADE_HISTORY_REFS.WINENAME]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.WINENAME}`),
    [TRADE_HISTORY_REFS.REGION]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.REGION}`),
    [TRADE_HISTORY_REFS.PRODUCER]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.PRODUCER}`),
    [TRADE_HISTORY_REFS.VINTAGE]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.VINTAGE}`),
    [TRADE_HISTORY_REFS.UNITSIZE]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.UNITSIZE}`),
    [TRADE_HISTORY_REFS.LWIN18]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.LWIN18}`),
    [TRADE_HISTORY_REFS.QUANTITY]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.QUANTITY}`),
    [TRADE_HISTORY_REFS.TRANSACTIONTYPE]: translation(
      `trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.TRANSACTIONTYPE}`,
    ),
    [TRADE_HISTORY_REFS.PRICE]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.PRICE}`),
    [TRADE_HISTORY_REFS.TOTAL]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.TOTAL}`),
    [TRADE_HISTORY_REFS.BUYER]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.BUYER}`),
    [TRADE_HISTORY_REFS.SELLER]: translation(`trade:offersAndBids.table.headers.${TRADE_HISTORY_REFS.SELLER}`),
  };

  const tradeHistoryColumns: TableColumnType[] = [
    {
      dataRef: TRADE_HISTORY_REFS.DATE,
      className: 'pr-5 ',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.DATE],
      cellClassName: cellClassName2,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return <span className="px-2">{`${moment(row?.dateTime).format('YYYY/MM/DD')}`}</span>;
      },
    },
    {
      dataRef: TRADE_HISTORY_REFS.TIME,
      className: 'pr-5 ',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.TIME],
      cellClassName: cellClassName2,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return <span className="px-2">{`${dateTimeSplitFunc(row.dateTime).time}`}</span>;
      },
    },
    {
      dataRef: TRADE_HISTORY_REFS.WINENAME,
      className: 'pr-5 ',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.WINENAME],
      cellClassName: 'text-sm text-black cursor-pointer whitespace-nowrap px-2 ',
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return <span>{`${row.vintage} ${row.wineName} `}</span>;
      },
    },

    {
      dataRef: TRADE_HISTORY_REFS.UNITSIZE,
      className: 'text-center pl-5',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.UNITSIZE],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span>{cell.text}</span>,
    },

    {
      dataRef: TRADE_HISTORY_REFS.QUANTITY,
      className: 'text-center pl-5',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.QUANTITY],
      cellClassName,
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{cell.text}</span>,
    },

    {
      dataRef: TRADE_HISTORY_REFS.PRICE,
      className: 'text-center pl-5',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.PRICE],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return (
          <span>
            {row.transactionType === 'Sell'
              ? gbpToTargetCurrency.convertAndFormat(Number(row.executionPrice))
              : gbpToTargetCurrency.convertAndFormat(Number(row.price))}
          </span>
        );
      },
    },
    {
      dataRef: TRADE_HISTORY_REFS.TOTAL,
      className: 'text-center pl-5',
      text: TRADE_HISTORY_TEXTS[TRADE_HISTORY_REFS.TOTAL],
      cellClassName,
      cellContentTemplate: (cell: TableCell, t, rowData) => {
        const row = rowData as unknown as TradeHistoryType;
        return (
          <span>
            {row.transactionType === 'Sell'
              ? gbpToTargetCurrency.convertAndFormat(Number(row.executionPrice * row.quantity))
              : gbpToTargetCurrency.convertAndFormat(Number(row.total))}
          </span>
        );
      },
    },
  ];

  //  highestBid: data.highestBid?.price,
  //               quantity: data.initialQuantity,
  //               totalMarketValue: data.quantityOutstanding * data.marketValue,
  // const sortByOptions = columns
  //   .filter((x) => x.isVisible === true || x.isVisible === undefined)
  //   .map((col) => ({
  //     id: col.dataRef,
  //     value: col.dataRef,
  //     text: col.text,
  //     content: <span>{col.text}</span>,
  //   }));

  // const defaultSortBy = () => {
  //   const { id, text } = sortByOptions.find((x) => x.id === MARKET_REFS.ID)!;
  //   return {
  //     id,
  //     text,
  //     direction: SortDirection.DESCENDING,
  //   };
  // };

  return {
    tradeHistoryColumns,
  };
};

const generateTableActions = ({ row, portfolioStocks, tableType }: IGenerateTableActions): TABLE_ACTIONS[] => {
  const actions: TABLE_ACTIONS[] = [];
  switch (tableType) {
    case SUB_TABS_ENUMS.MARKET: {
      actions.push(TABLE_ACTIONS.PURCHASE);
      const stock = portfolioStocks?.find((x) => x.lwin18 === row.lwin18);
      if (row.highestBid?.price && stock && stock.physicalQuantity > 0) actions.push(TABLE_ACTIONS.ACCEPT_HIGHEST_BID);
      return actions;
    }
    case SUB_TABS_ENUMS.MY_OFFERS: {
      if (row.highestBid?.price) actions.push(TABLE_ACTIONS.MY_OFFERS_ACCEPT_HIGHEST_BID);
      actions.push(TABLE_ACTIONS.EDIT_OFFER);
      return actions;
    }

    default: {
      return [];
    }
  }
};

const getPriceRange = (price: number) => {
  if (price < 500) return 'Under £500';
  if (price < 1000) return 'Under £1000';
  if (price >= 1000 && price < 2000) return '£1k-£2k';
  if (price >= 2000 && price < 5000) return '£2k-£5k';
  if (price >= 5000 && price < 10000) return '£5k-£10k';
  if (price >= 10000) return '£10k+';
  return null;
};

export const generateRowsFormat = ({
  currentSubTab,
  offers,
  portfolioStocks,
  portfolioOffers,
  fxRate,
  activeOfferPriceListMap,
}: IGenerateRowsFormat) => {
  switch (currentSubTab) {
    case SUB_TABS_ENUMS.MARKET: {
      let numberOfBidAgainstStock = 0;
      const source: IRowData[] = [];
      offers
        .filter((i) => !i.campaignId)
        .filter((x) => !x.isLiquidating)
        .forEach((row) => {
          const index = source.findIndex((x) => x.lwin18 === row.lwin18);
          if (index > -1) {
            // add to exsisting
            const tableActions = [
              ...new Set([
                ...generateTableActions({
                  row: row,
                  portfolioStocks,
                  tableType: SUB_TABS_ENUMS.MARKET,
                }),
                ...source[index].actions,
              ]),
            ];

            source[index] = {
              ...source[index],
              actions: tableActions,
              quantityOutstanding: source[index].quantityOutstanding + row.quantityOutstanding,
            };
          } else {
            // add to as new
            const stock = portfolioStocks?.find((x) => x.lwin18 === row.lwin18);
            if (row.highestBid?.price && stock && stock.physicalQuantity > 0) numberOfBidAgainstStock += 1;

            const activeOfferPriceList = activeOfferPriceListMap.find((x) => x.lwin18 === row.lwin18);

            source.push({
              ...row,
              priceGbp: row.price,
              priceInTargetCurrency: row.price * fxRate,

              marketValueGbp: row.marketValue,
              marketValueInTargetCurrency: row.marketValue * fxRate,

              stopLimitPriceGbp: row.stopLimitPrice,
              stopLimitPriceInTargetCurrency: row.stopLimitPrice * fxRate,

              highestBidPrice: row.highestBid?.price ?? 0,
              lowestOfferPrice: row.lowestOffer?.price ?? 0,

              WAscore: Math.ceil(Number(activeOfferPriceList?.WA_score ?? 0)),
              vinousScore: Math.ceil(Number(activeOfferPriceList?.Vinous_score ?? 0)),

              priceRange: getPriceRange(row.lowestOffer?.price ?? 0),

              actions: generateTableActions({
                row: row,
                portfolioStocks,
                tableType: SUB_TABS_ENUMS.MARKET,
              }),
            });
          }
        });
      return { dataSource: source, numberOfBidAgainstStock };
    }
    case SUB_TABS_ENUMS.MY_OFFERS: {
      const source = portfolioOffers.map((row) => {
        return {
          ...row,
          priceGbp: row.price,
          priceInTargetCurrency: Number((row.price * fxRate).toFixed(2)),

          marketValueGbp: row.marketValue,
          marketValueInTargetCurrency: Number(((row?.marketValue || 0) * fxRate).toFixed(2)),

          stopLimitPriceGbp: row.stopLimitPrice,
          stopLimitPriceInTargetCurrency: Number((row.stopLimitPrice * fxRate).toFixed(2)),

          actions: generateTableActions({ row: row, tableType: SUB_TABS_ENUMS.MY_OFFERS }),
        };
      });
      return { dataSource: source };
    }

    default:
      return { dataSource: [] };
  }
};

export const subTabsBidsAndOffers: TabsPops[] = [
  { id: 1, tabName: SUB_TABS_ENUMS.MY_OFFERS },
  { id: 2, tabName: SUB_TABS_ENUMS.MARKET },
];

export const stopLimitsItems: DropdownItem[] = [
  { id: '1', content: <div>5% below market value</div>, text: '5% below market value', value: '0.95' },
  { id: '1', content: <div>10% below market value</div>, text: '10% below market value', value: '0.90' },
  { id: '2', content: <div>15% below market value</div>, text: '15% below market value', value: '0.85' },
  { id: '3', content: <div>20% below market value</div>, text: '20% below market value', value: '0.80' },
  { id: '4', content: <div>40% below market value</div>, text: '40% below market value', value: '0.60' },
];

export const generateQuantityDropdown = (quantity = 0) => {
  const arr = Array(quantity).fill(1, 0);
  const items: DropdownItem[] = [];
  arr.forEach((_, i) => {
    items.push({
      id: `${i + 1}`,
      content: (
        <div key={i}>
          <span>{`${i + 1}`}</span>
        </div>
      ),
      value: `${i + 1}`,
    });
  });
  return items;
};

interface CustomContentProps {
  id: string | number;
  message: string;
}

interface SortBidsNotificationProps extends CustomContentProps {
  sortFunction: () => void;
}

export const SortBidsNotification = forwardRef<HTMLDivElement, SortBidsNotificationProps>((props, ref) => {
  const { id, message, sortFunction, ...other } = props;

  return (
    <SnackbarContent ref={ref} role="alert" {...other} className="bg-white shadow-lg border flex  p-2 rounded-md">
      <div className="flex justify-between items-center flex-row gap-2 text-[14px]">
        <span> {message}</span>
        <button className="text-orange" onClick={sortFunction}>
          Sort
        </button>
      </div>
    </SnackbarContent>
  );
});

export const DisableSortBid = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const { id, message, ...other } = props;

  return (
    <SnackbarContent ref={ref} role="alert" {...other} className="bg-white shadow-lg border flex  p-2 rounded-md">
      <div className="flex justify-between items-center flex-row gap-2 text-[14px]">
        <span> {message}</span>
      </div>
    </SnackbarContent>
  );
});

interface LoadingComponentProps {
  currentSubTab: string;
  isLoading: boolean | string;
  loadingText: string;
  liveText: string;
  resultLength: number;
  className: string;
  resultText: string;
}

export const LoadingComponent = ({
  currentSubTab,
  isLoading,
  loadingText,
  liveText,
  resultLength,
  className,
  resultText,
}: LoadingComponentProps) => {
  return (
    <div className={`text-sm  flex items-center gap-1 ${className}`}>
      {currentSubTab === SUB_TABS_ENUMS.MARKET ? (
        <>
          {isLoading ? (
            <div className="flex items-center gap-2">
              <p>{loadingText}</p>
              <Loading />
            </div>
          ) : (
            <>
              <p>{liveText}</p>
              <div className="w-3 h-3 bg-white border border-[#D46666] rounded-full flex justify-center items-center">
                <div className="w-[6px] h-[6px] bg-[#D46666] rounded-full" />
              </div>
              <p>{`${resultLength.toLocaleString()} ${resultText?.toLowerCase()}`}</p>
            </>
          )}
        </>
      ) : (
        <div className="flex flex-row items-center">
          <p>{`${resultLength?.toLocaleString()} ${resultText?.toLowerCase()} `}</p>
        </div>
      )}
    </div>
  );
};

export const applyFilters = <T,>(dataSource: T[], filterConfig: FiltersType<T> | null) => {
  if (!filterConfig) return;

  let source = [...dataSource] as Record<string, string>[];

  let isFiltered = false;

  Object.keys(filterConfig).forEach((key) => {
    if (filterConfig[key].previewSelected.length > 0 && !isFiltered) isFiltered = true;

    if (filterConfig[key].previewSelected.length > 0 && filterConfig[key].customFilterFunction) {
      source = filterConfig[key].customFilterFunction?.(filterConfig, source as T[]) as Record<string, string>[];
    } else if (filterConfig[key].previewSelected.length > 0) {
      source = source.filter((x) => filterConfig[key].previewSelected.includes(String(x[key])));
    }
  });

  return { isFiltered, filteredDataSource: source as T[] };
};
