import { Button } from 'components';
import CustomInput from 'components/CustomInput';
import { useContext, useMemo, useState } from 'react';
import { DisplayFieldType } from 'components/DisplayForms';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { DisplayTextKeys, OffersType, QUERY_KEYS, SetSlideoutConfigType, SlideoutConfigType } from 'views/Trade/types';
import { FeedbackTemplate } from 'views/shared/FeedbackTemplate';
import { ViewStateType } from 'components/ProductTemplates/types';
import useMutate from 'views/Trade/hooks/useMutate';
import { IEditOffersParams, editOffer } from 'views/Trade/services/editOffer';
import { QUERY_REFECH_TIMEOUT } from 'views/Trade/constants';
import { AppContext } from 'context/ContextProvider';
import { CurrencyFormater } from 'types/commonTypes';
import { buildDisplayText } from 'utils';
import { IDeleteOffersParams, deleteOffer } from 'views/Trade/services/deleteOffer';
import WineInfoTemplate from './WineInfoTemplate';

interface IEditOfferViewProps {
  slideOutConfig: SlideoutConfigType;
  setSlideOutConfig: SetSlideoutConfigType;
}

const EditOfferView = ({ setSlideOutConfig, slideOutConfig }: IEditOfferViewProps) => {
  const {
    state: {
      settings: { accountInfo },
    },
    formatter,
    gbpToTargetCurrency,
  } = useContext(AppContext);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'trade:common', t), [t]);

  const [stopLimitText, setStopLimitText] = useState<number>(
    slideOutConfig?.offer?.stopLimitPriceInTargetCurrency || 0,
  );

  const { isLoading: editOfferIsLoading, mutateAsync: mutateOffer } = useMutate({
    mutationFn: editOffer,
  });

  const { mutateAsync: mutateDeleteOffer } = useMutate({ mutationFn: deleteOffer });

  const updateOffer = async (offer: OffersType, fxRate: number) => {
    const params: IEditOffersParams = {
      body: {
        stopLimitPerUnit: Number((stopLimitText / fxRate || 1).toFixed(2)),
        quantity: offer.quantityOutstanding,
      },
      operationType: 'UpdateSellOrder',
      orderId: offer.id,
    };

    try {
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.LOADING,
      }));
      await mutateOffer(params);
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.THANK_YOU,
        templateConfig: { translationKey: 'common:generalSuccess' },
        isOpen: true,
      }));
    } catch (error) {
      const response = error as unknown as { response: { data: { message: string } } };
      const errorMessage = response?.response?.data?.message || '';
      if (
        errorMessage.includes('StopLimitPerUnit') &&
        errorMessage.includes('less') &&
        errorMessage.includes('marketValue')
      ) {
        const messageArray = errorMessage.split(' ');

        const price = messageArray[messageArray.length - 1];
        const formatedPrice = gbpToTargetCurrency.convertAndFormat(Number(price ?? 0));
        // console.log('Price ', price);
        // console.log('formatedPrice ', formatedPrice);

        let displayMessage = `${[...messageArray].slice(0, messageArray.length - 1).join(' ')} ${formatedPrice}`;
        displayMessage = displayMessage.replace('StopLimitPerUnit', 'Stop limit');
        displayMessage = displayMessage.replace('marketValue', 'market value');
        setSlideOutConfig((prev) => ({
          ...prev,
          view: ViewStateType.ERROR_RESULT,
          templateConfig: { subTitle: displayMessage },
          onCTA: () =>
            setSlideOutConfig(() => ({
              ...prev,
              view: ViewStateType.DEFAULT,
            })),
        }));
      } else {
        setSlideOutConfig((prev) => ({
          ...prev,
          view: ViewStateType.ERROR_RESULT,
          onCTA: () =>
            setSlideOutConfig(() => ({
              ...prev,
              view: ViewStateType.DEFAULT,
            })),
        }));
      }
    } finally {
      setTimeout(() => {
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_OFFERS] });
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS] });
        if (slideOutConfig?.searchText) {
          queryClient.resetQueries({
            queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS_SEARCH, slideOutConfig.searchText],
          });
          queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_OFFERS_SEARCH, slideOutConfig.searchText] });
        }
        // only works if they are separately called
      }, QUERY_REFECH_TIMEOUT);
    }
  };

  const cancelOffer = async (offer?: OffersType) => {
    const deleteOfferParams: IDeleteOffersParams = {
      headers: {
        emailAddress: 'email',
        operationType: 'DeleteSellOrder',
      },
      orderId: offer?.id as string,
    };

    try {
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.LOADING,
      }));
      await mutateDeleteOffer(deleteOfferParams);
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.THANK_YOU,
        templateConfig: { translationKey: 'common:offerCanceled' },
        isOpen: true,
      }));
    } catch (error) {
      setSlideOutConfig((prev) => ({
        ...prev,
        view: ViewStateType.ERROR_RESULT,
        onCTA: () =>
          setSlideOutConfig(() => ({
            ...prev,
            view: ViewStateType.DEFAULT,
          })),
      }));
    }

    setTimeout(() => {
      // only works if they are separately called
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_OFFERS] });
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS] });
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_STOCK] });
      if (slideOutConfig?.searchText) {
        queryClient.resetQueries({
          queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_OFFERS_SEARCH, slideOutConfig.searchText],
        });
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.FETCH_OFFERS_SEARCH, slideOutConfig.searchText] });
      }
    }, QUERY_REFECH_TIMEOUT);
  };

  const disableButton = () => {
    return (
      slideOutConfig?.offer?.stopLimitPriceInTargetCurrency === stopLimitText ||
      stopLimitText < 1 ||
      stopLimitText > (slideOutConfig?.offer?.marketValueInTargetCurrency || 0) ||
      !stopLimitText
    );
  };

  const formatterToTargetCurrency = useMemo((): CurrencyFormater => {
    return {
      format: (value: number, isNew = true) => {
        return formatter.format(value, isNew);
      },
    };
  }, [formatter]);

  return (
    <FeedbackTemplate
      viewState={slideOutConfig?.view || ViewStateType.DEFAULT}
      templateConfig={slideOutConfig.templateConfig}
      onCTA={slideOutConfig.onCTA}
      onClose={() => {
        setSlideOutConfig({ isOpen: false });
      }}
    >
      <WineInfoTemplate config={slideOutConfig.wineInfoTemplateConfig}>
        <div className="px-5 py-3 flex flex-col gap-3 mt-5">
          <span className="text-gray-700 text-sm">{displayText[DisplayTextKeys.ENTER_NEW_STOP_LIMIT]}</span>
          <div className="flex flex-col">
            <div className="flex flex-col w-full  border-gray-200 items-center">
              <CustomInput
                type={DisplayFieldType.CURRENCY}
                formatter={formatterToTargetCurrency}
                value={stopLimitText}
                onChange={(e) => {
                  setStopLimitText(Number(e.target.value));
                }}
                placeholder="0.00"
                name="Stop limit"
                className="flex w-full  border-gray-200 items-center"
              />
              {stopLimitText > (slideOutConfig?.offer?.marketValueInTargetCurrency || 0) ? (
                <p className="text-sm text-red py-2">{displayText[DisplayTextKeys.STOP_LIMIT_MESSAGE]}</p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="flex flex-col py-10 px-5 drop-shadow-sm ">
          <div className="flex justify-between divide-x divide-gray-300">
            <div className="flex-1 flex flex-col">
              <span className="text-center text-sm">{displayText[DisplayTextKeys.TOTAL_MARKET_VALUE]}</span>
              <span className="text-center text-20">
                {formatter.format(slideOutConfig?.offer?.marketValueInTargetCurrency || 0, true)}
              </span>
            </div>
            <div className="flex-1 flex flex-col">
              <span className="text-center text-sm">{displayText[DisplayTextKeys.STOP_LIMIT]}</span>
              <span className="text-center text-20">{formatter.format(stopLimitText || 0, true)}</span>
            </div>
          </div>

          <Button
            isProcessing={editOfferIsLoading}
            isDisable={disableButton() || editOfferIsLoading}
            onClick={() => updateOffer(slideOutConfig?.offer as OffersType, accountInfo?.account.fxRate || 1)}
            type="button"
            className="text-14 font-normal rounded-full mt-8  btn  bg-orange text-black disabled:bg-[#cccccc] disabled:text-[#b3b3b3]"
          >
            {displayText[DisplayTextKeys.UPDATE]}
          </Button>
          <button className="text-red my-2 text-center text-sm" onClick={() => cancelOffer(slideOutConfig.offer)}>
            {displayText[DisplayTextKeys.CANCEL]}
          </button>
        </div>
      </WineInfoTemplate>
    </FeedbackTemplate>
  );
}; // Price exceed market price amount of £{maximumAmount} which is 5% below market price.

export default EditOfferView;
