import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import useApiMutation from 'hooks/useApiMutation';
import { OffersType, QUERY_KEYS, SlideoutConfigType, StocksType, TABLE_ACTIONS } from 'views/Trade/types';
import { useFetch } from 'views/Trade/hooks/useFetch';
import { fetchOffers } from 'views/Trade/services/fetchOffers';
import { AppContext } from 'context/ContextProvider';
import PurchaseView from 'views/Trade/components/SlideOutView/PurchaseView';
import { SortAndFilterLayoutContext } from 'components/Layout/SortAndFilterLayout';
import { useQueryClient } from '@tanstack/react-query';
import { fetchPortfolioStocks } from 'views/Trade/services/fetchPortfolioStocks';
import CreateOfferView from 'views/Trade/components/SlideOutView/CreateOfferView';
import { Product } from '../../../types/productType';
import { capitalizeFirstLetter, toInternalId } from '../../../utils';
import ProductImage from './ProductImage';
import SellOrInvestProductTemplate from './SellOrInvestProductTemplate';
import { WarningIcon } from '../../../assets/icons';
import { getRegions } from '../../../helpers';
import { ExecuteResponse, ProductEventType, ViewStateType } from '../types';
import { logError } from '../../LogError';
import { FeedbackTemplate } from '../../../views/shared/FeedbackTemplate';
import { MessageProductTemplateProp } from './MessageTemplate';
import useExecutor from '../../../views/hooks/useExecutor';
import { arrangeDeliveryApi } from '../services/arrangeDeliveryApi';
import { sellRequestApi } from '../services/sellRequestApi';
import { buyRequestApi } from '../services/buyRequestApi';
import AcceptBidTemplate from './AcceptBidTemplate';
import { PORTAL_DESCRIPTIONS } from 'types/commonTypes';

type MessageType = Pick<MessageProductTemplateProp, 'title' | 'subTitle' | 'onClick' | 'buttonText'>;

interface ProcessorType {
  id: ViewStateType;
  error: ApolloError | undefined;
  loading: boolean;
  data: unknown;
  isFlush: boolean;
  onSuccess: (response: unknown) => void;
  onError: (error: ApolloError | undefined) => void;
  onFlush?: () => void;
}
interface ProductSlideoutProps {
  product?: Product;
  timestamp?: number;
  onClose?: () => void;
  setTitle?: (title: string) => void;
  isDetailsFetched?: boolean;
}
const HOLDING_TIME_OUT = 1500;
const ProductSlideout: FC<ProductSlideoutProps> = ({ product, timestamp, onClose, setTitle, isDetailsFetched }) => {
  const { t } = useTranslation();
  const {
    state: {
      settings: { currency, accountInfo },
    },
    gbpToTargetCurrency,
  } = useContext(AppContext);
  const queryClient = useQueryClient();
  const slideoutContext = useContext(SortAndFilterLayoutContext);

  const regions = useMemo(() => getRegions(t), [t]);
  const [currentTimestamp, setCurrentTimestamp] = useState<number | undefined>(timestamp);
  const regionColor = useMemo(() => {
    return regions.find(
      (x) =>
        x.id ===
        toInternalId(
          (product && !!product.cultWinesAllocationRegion
            ? product
            : { cultWinesAllocationRegion: '' }
          ).cultWinesAllocationRegion!.toLowerCase(),
        ),
    )?.color;
  }, [product, regions]);

  const [slideOutConfig, setSlideOutConfig] = useState<SlideoutConfigType>({
    showBackButton: false,
    isOpen: false,
    view: ViewStateType.DEFAULT,
    title: '',
    isDetailsFetched: false,
  });
  const [feedbackState, setFeedbackState] = useState(ViewStateType.DEFAULT);
  const [feedbackTemplateConfig, setFeedbackTemplateConfig] = useState<MessageType>({
    onClick: () => null,
  });

  const investMoreExecutor = useExecutor({
    mutation: useApiMutation(buyRequestApi),
    viewRef: ViewStateType.INVEST_MORE,
    onStatusChange: (vState: ViewStateType, feedbackConfig?: MessageType) => {
      if (feedbackConfig) setFeedbackTemplateConfig(feedbackConfig);
      setFeedbackState(vState);
    },
    onClose: onClose || (() => null),
  });

  const sellHoldingExecutor = useExecutor({
    mutation: useApiMutation(sellRequestApi),
    viewRef: ViewStateType.SELL_HOLDINGS,
    onStatusChange: (vState: ViewStateType, feedbackConfig?: MessageType) => {
      if (feedbackConfig) setFeedbackTemplateConfig(feedbackConfig);
      setFeedbackState(vState);
    },
    onClose: onClose || (() => null),
  });

  const arrangeDeliveryExecutor = useExecutor({
    mutation: useApiMutation(arrangeDeliveryApi),
    viewRef: ViewStateType.SELL_HOLDINGS,
    onStatusChange: (vState: ViewStateType, feedbackConfig?: MessageType) => {
      if (feedbackConfig) setFeedbackTemplateConfig(feedbackConfig);
      setFeedbackState(vState);
    },
    onClose: onClose || (() => null),
    processorConfig: {
      onSuccess: (response: unknown) => {
        const result = (response as ExecuteResponse).data;
        if (result?.isSuccess) setFeedbackState(ViewStateType.THANK_YOU);
        else {
          handleFailRequest(result, 'arrangeDelivery');
        }
      },
    },
  });

  const { isLoading: isLoadingMarket, responce: marketSearchData } = useFetch({
    refetchOnWindowFocus: false,
    shouldCache: false,
    queryFn: () => fetchOffers({ searchText: product?.lwin18, page: 0, pageSize: 50 }),
    queryKey: [QUERY_KEYS.FETCH_OFFERS_SEARCH, product?.lwin18 || ''],
  });

  const { isLoading: isLoadingPortfolioStock, responce: portfolioStockSearchData } = useFetch({
    refetchOnWindowFocus: false,
    shouldCache: false,
    queryFn: () => fetchPortfolioStocks({ search: product?.lwin18, page: 0, pageSize: 1 }),
    queryKey: [QUERY_KEYS.FETCH_PORTFOLIO_STOCK, product?.lwin18 || ''],
  });

  const portfolioStock = useMemo((): StocksType | null => {
    if (!portfolioStockSearchData?.count) return null;
    return portfolioStockSearchData.data?.[0];
  }, [portfolioStockSearchData?.data, portfolioStockSearchData?.count]);

  const marketOffers = useMemo((): OffersType[] | null => {
    if (!marketSearchData?.count) return null;
    return marketSearchData.data;
  }, [marketSearchData?.data, marketSearchData?.count]);

  const [processor, setProcessor] = useState<ProcessorType | null>(null);

  const handleFailRequest = (
    result: { isSuccess: boolean; errorMessage: string } | undefined,
    translationKey: string,
  ) => {
    if (!result) {
      setFeedbackState(ViewStateType.SOMETHING_WENT_WRONG);
    } else {
      setFeedbackTemplateConfig({
        title: (
          <div className="flex items-center gap-3">
            <WarningIcon />
            <span className="text-20 font-medium ">{t('common:somethingWentWrong.title')}</span>
          </div>
        ),
        subTitle: <div className="text-14 mt-2">{t`product:wineDetails.${translationKey}.fail_send-request`}</div>,
        onClick: () => {
          if (onClose) onClose();
        },
        buttonText: capitalizeFirstLetter(t`common:done`),
      });
      setFeedbackState(ViewStateType.MESSAGE);
    }

    logError(result?.errorMessage);
  };

  useEffect(() => {
    slideoutContext?.updateSlideoutConfig({
      showBackButton: slideOutConfig.showBackButton,
      onBack: slideOutConfig.onBack,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideOutConfig]);

  const onCTA = <T,>(eventType: ProductEventType, eventData?: T) => {
    switch (eventType) {
      case ProductEventType.EXECUTE_SELL_PRODUCT_REQUEST:
        sellHoldingExecutor({ ...eventData });
        break;

      case ProductEventType.EXECUTE_BUY_PRODUCT_REQUEST:
        investMoreExecutor({ ...eventData });
        break;

      case ProductEventType.ARRANGE_DELIVERY:
        arrangeDeliveryExecutor({ ...eventData });
        break;
      case ProductEventType.SET_TITLE:
        if (setTitle) setTitle(eventData as string);

        break;

      case ProductEventType.BUY: {
        const offers = marketSearchData?.data?.[0] as OffersType;
        setSlideOutConfig({
          portalDescriptions: PORTAL_DESCRIPTIONS.PORTAL_BUY_PORTFOLIO,
          showBackButton: true,
          innerViewState: ViewStateType.DEFAULT,
          view: ViewStateType.DEFAULT,
          slidoutView: TABLE_ACTIONS.PURCHASE,
          isOpen: true,
          offer: {
            ...offers,
            priceInTargetCurrency: gbpToTargetCurrency.convert(offers?.price ?? 0),
            priceGbp: offers?.price ?? 0,
            quantityOutstanding:
              marketSearchData?.data?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.quantityOutstanding,
                0,
              ) ?? 0,
          },
          onBack: () => setSlideOutConfig((prev) => ({ ...prev, isOpen: false, showBackButton: false })),
          extraConfig: {
            initOnBack: () => {
              setSlideOutConfig((prev) => ({ ...prev, isOpen: false, showBackButton: false }));
            },
            success: {
              cta: () => {
                slideoutContext?.updateSlideoutConfig({ open: false });
              },
              onSuccess: () => {
                setTimeout(() => {
                  queryClient.resetQueries({ queryKey: ['fetchCurrentHoldingVT2', { currency }] });
                  queryClient.refetchQueries({ queryKey: ['fetchMyCellar'] });
                }, HOLDING_TIME_OUT);
              },
            },
          },
          title: 'Purchase',
          wineInfoTemplateConfig: {
            lwin18: offers?.lwin18,
            marketValue: offers?.marketValue,
            region: offers?.region,
            cultWinesAllocationRegion: product?.cultWinesAllocationRegion,
            quantity:
              marketSearchData?.data?.reduce(
                (accumulator, currentValue) => accumulator + currentValue.quantityOutstanding,
                0,
              ) ?? 0,
            unitSize: offers?.unitSize,
            vintage: offers?.vintage,
            wineName: offers?.wineName,
          },
        });
        return;
      }

      case ProductEventType.MAKE_OFFER: {
        setSlideOutConfig({
          showBackButton: true,
          innerViewState: ViewStateType.DEFAULT,
          view: ViewStateType.DEFAULT,
          slidoutView: TABLE_ACTIONS.CREATE_OFFER,
          isOpen: true,
          onBack: () => setSlideOutConfig((prev) => ({ ...prev, isOpen: false, showBackButton: false })),
          extraConfig: {
            initSelectedWine: portfolioStock,
            success: {
              cta: () => {
                slideoutContext?.updateSlideoutConfig({ open: false });
              },
              onSuccess: () => {
                setTimeout(() => {
                  queryClient.resetQueries({ queryKey: ['fetchCurrentHoldingVT2', { currency }] });
                }, HOLDING_TIME_OUT);
              },
            },
          },
          wineInfoTemplateConfig: {
            lwin18: portfolioStock?.lwin18,
            marketValue: portfolioStock?.marketValue,
            region: product?.region,
            cultWinesAllocationRegion: product?.cultWinesAllocationRegion,
            quantity: portfolioStock?.quantity,
            unitSize: portfolioStock?.unitSize,
            vintage: portfolioStock?.vintage,
            wineName: portfolioStock?.wineName,
          },
        });
        return;
      }

      case ProductEventType.SELL: {
        setSlideOutConfig({
          showBackButton: true,
          innerViewState: ViewStateType.DEFAULT,
          view: ViewStateType.DEFAULT,
          slidoutView: TABLE_ACTIONS.ACCEPT_HIGHEST_BID,
          isOpen: true,
          onBack: () => setSlideOutConfig((prev) => ({ ...prev, isOpen: false, showBackButton: false })),
          wineInfoTemplateConfig: {
            lwin18: product?.lwin18,
            marketValue: product?.valuePerUnit,
            region: product?.region,
            cultWinesAllocationRegion: product?.cultWinesAllocationRegion,
            quantity: product?.qty,
            unitSize: product?.unit,
            vintage: (product?.vintage ?? 0) as number,
            wineName: product?.wineName,
          },
        });
        return;
      }

      default:
        setFeedbackState(ViewStateType.DEFAULT);
        break;
    }
  };

  useEffect(() => {
    if (timestamp !== currentTimestamp) {
      setCurrentTimestamp(timestamp);
      setFeedbackState(ViewStateType.DEFAULT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  const flushProcessor = () => {
    const { loading, error, onError, onSuccess, data } = processor!;
    if (loading) return;
    if (error) onError(error);
    else {
      onSuccess(data);
    }
    setProcessor(null);
  };

  useEffect(() => {
    if (processor?.isFlush) {
      flushProcessor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processor]);

  useEffect(() => {
    if (timestamp !== currentTimestamp) {
      setCurrentTimestamp(timestamp);
      setFeedbackState(ViewStateType.DEFAULT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  if (!product) return null;
  // const productImage = getImageUrl(product.imageFileName || '', { height: 200 });
  return (
    <div className="flex flex-col flex-1 bg-gradient-to-b from-vine to-gray-500  pb-5 px-3 w-screen relative overflow-x-hidden sm:w-[390px] ">
      {slideOutConfig.isOpen && (
        <>
          {slideOutConfig.slidoutView === TABLE_ACTIONS.PURCHASE && (
            <PurchaseView slideOutConfig={slideOutConfig} setSlideOutConfig={setSlideOutConfig} />
          )}
          {slideOutConfig.slidoutView === TABLE_ACTIONS.CREATE_OFFER && (
            <CreateOfferView slideOutConfig={slideOutConfig} setSlideOutConfig={setSlideOutConfig} />
          )}
          {slideOutConfig.slidoutView === TABLE_ACTIONS.ACCEPT_HIGHEST_BID && (
            <AcceptBidTemplate
              slideOutConfig={slideOutConfig}
              setSlideOutConfig={setSlideOutConfig}
              accountInfo={accountInfo}
              gbpToTargetCurrency={gbpToTargetCurrency}
              product={product}
            />
          )}
        </>
      )}

      {!slideOutConfig.isOpen && (
        <FeedbackTemplate
          onClose={onClose}
          templateConfig={feedbackTemplateConfig}
          viewState={feedbackState}
          onCTA={() => onCTA(ProductEventType.DEFAULT)}
        >
          <div className="w-full h-full rounded-md  overflow-y-auto bg-white">
            <ProductImage
              imageByLwin18={product.lwin18}
              bgColor={regionColor}
              region={product.region}
              // imageContainer="!h-[230px]"
            />
            <SellOrInvestProductTemplate
              isDetailsFetched={isDetailsFetched}
              product={product}
              timestamp={timestamp}
              onCTA={onCTA}
              isLoadingMarket={isLoadingMarket}
              isLoadingPortfolioStock={isLoadingPortfolioStock}
              marketOffers={marketOffers}
              portfolioStock={portfolioStock}
            />
          </div>
        </FeedbackTemplate>
      )}
    </div>
  );
};

export default ProductSlideout;
