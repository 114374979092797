import { InvestOffer } from 'views/Invest/types';

interface AccountSummaryType {
  id: string;
  accountHolderId: number;
  date: string;
  statements: {
    main: {
      columns: string[];
      rows: string[][];
    };
  };
}

export interface TransactionsType {
  id: string;
  accountHolderId: string;
  last_updated: string;
  total: string;
  statements: {
    main: {
      columns: string[];
      rows: string[][];
    };
  };
}

export interface ActiveOfferPriceListType {
  id: string;
  last_updated: Date;
  wines: { columns: string[]; rows: string[][] };
}

export interface ActiveOfferPriceListFormatedType {
  allocated_region: string;
  country: string;
  region: string;
  producer: string;
  wineName: string;
  vintage: string;
  unit_size: string;
  quantity: string;
  offer_price: string;
  lwin18: string;
  orderType: string;
  hasPhysical: string;
  WA_score: string;
  Vinous_score: string;
}

export enum PortalDocumentTypeEnum {
  INVESTOFFERS = 'investOffers',
  ACCOUNTSUMMARY = 'accountSummary',
  CASH_FLOW_DETAILS = 'cashFlowDetails',
  TRANSACTIONS = 'Transactions',
  ACTIVE_OFFER_PRICE_LIST = 'ActiveOfferPriceList',
}

export interface PortalDocumentResult {
  count: number;
  data: InvestOffer[] | AccountSummaryType[] | TransactionsType[] | ActiveOfferPriceListType[];
}

export interface PortalDocumentParams {
  docType: PortalDocumentTypeEnum;
  docRef?: string | number | null;
  isDetail?: boolean;
  sort?: string;
  page?: number;
  pageSize?: number;
  sortOrder?: 'ASC' | 'DESC';
}
