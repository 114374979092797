import { TFunction } from 'i18next';
import { NOTIFICATION_TABLE_REFS } from '../types';
import { TableCell, TableColumnType, TableRow } from 'components/Table';
import CheckBox from 'components/CheckBox';
import { NotificationPreferencesType } from '../services/getNotificationPreferencesApi';

export const InitialiseNotificationTable = (
  translation: TFunction,
  onCheck: (categoryId: string, email: boolean) => void,
) => {
  const NOTIFICATION_TABLE_TEXTS = {
    [NOTIFICATION_TABLE_REFS.ID]: 'id',
    [NOTIFICATION_TABLE_REFS.NOTIFICATION_TYPE]: translation(
      `account:settings.table.headers.${NOTIFICATION_TABLE_REFS.NOTIFICATION_TYPE}`,
    ),
    [NOTIFICATION_TABLE_REFS.EMAIL]: translation(`account:settings.table.headers.${NOTIFICATION_TABLE_REFS.EMAIL}`),
  };

  const notificationColumns: TableColumnType[] = [
    {
      dataRef: NOTIFICATION_TABLE_REFS.NOTIFICATION_TYPE,
      className: '',
      text: NOTIFICATION_TABLE_TEXTS[NOTIFICATION_TABLE_REFS.NOTIFICATION_TYPE],
      cellClassName: 'text-black py-3 border-none',
      cellContentTemplate: (cell: TableCell) => <span className="px-2">{cell.text}</span>,
    },
    {
      dataRef: NOTIFICATION_TABLE_REFS.EMAIL,
      className: '',
      text: NOTIFICATION_TABLE_TEXTS[NOTIFICATION_TABLE_REFS.EMAIL],
      cellClassName: 'text-black py-3 border-none',
      cellContentTemplate: (cell: TableCell, t, rowData: TableRow['rowData']) => {
        const preference = rowData as unknown as NotificationPreferencesType;
        return (
          <CheckBox
            inputClassName="max-h-[18px] max-w-[18px] min-h-[18px] min-w-[18px]"
            isChecked={Boolean(cell.text)}
            id={`${preference.categoryId}`}
            onChange={(_, isChecked) => onCheck(preference.categoryId, isChecked)}
          />
        );
      },
    },
    {
      dataRef: NOTIFICATION_TABLE_REFS.ID,
      text: NOTIFICATION_TABLE_REFS.ID,
      isVisible: false,
    },
  ];

  return {
    notificationColumns,
  };
};
