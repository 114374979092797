import moment from 'moment';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { fetchCurrentHoldingDetailsVT2 } from 'views/Portfolio/components/Details/services/fetchCurrentHoldings';
import { OffersType, StocksType } from 'views/Trade/types';
import { buildDisplayText, capitalizeFirstLetter, roundNumber } from '../../../utils';
import { Product } from '../../../types/productType';
import { buildTableRow } from '../../../views/Portfolio/helpers';
import Button from '../../Button';
import Table, { CellTypeEnum, TableColumnType } from '../../Table';
import LiveExPerformanceChart from './LiveExPerformanceChart';
import cutlWinesLoversImage from '../../../assets/images/cult_wines_lovers_image.png';
import { BuySellHoldingModel, DisplayTextKeys, PricingType, ProductEventType, ViewStateType } from '../types';
import SellHoldingTemplate from './SellHoldingTemplate';
import InvestMoreTemplate from './InvestMoreTemplate';
import ArrangeDeliveryTemplate, { blankAddessModel } from './ArrangeDelivery';
import { SortAndFilterLayoutContext } from '../../Layout/SortAndFilterLayout';
import { AppContext } from '../../../context/ContextProvider';
import { ArrangeDeliveryRequest } from '../services/arrangeDeliveryApi';

interface SellOrInvestProductTemplateProps {
  product: Product;
  timestamp?: number;
  onCTA?: <T>(event: ProductEventType, data: T) => void;
  isDetailsFetched?: boolean;
  isLoadingMarket: boolean;
  isLoadingPortfolioStock: boolean;
  marketOffers: OffersType[] | null;
  portfolioStock: StocksType | null;
}

// enum StockStatusEnum {
//   IN_TRANSIT = 'intransit',
// }
// const notApplicable = 'n/a';

enum InfoColors {
  NONE = 'transparent',
  MARKET = '#1D4854',
  P_AND_L = '#FF906D',
  COST = '#55A2A7',
  FEES = '#B1DED8',
}

enum DATA_VALUATIONS_TABLE_HEADINGS {
  PURCHASE_DATE = 'purchaseDate',
  DATE = 'date',
  CASE = 'case',
  REF_NUM = 'reference_number',
  ROTATION_NUM = 'rotation_number',
  STATUS = 'status',
  LOCATION = 'location',
}

const cellClassName = 'text-xs text-black whitespace-nowrap flex-1 divide-gray-100';
const defaultModel = {
  price: 0,
  units: 0,
  reason: '',
  pricingType: PricingType.MARKET,
};
interface MarketInfoGraphProps {
  includeFees: boolean;
  data: {
    marketValue: number;
    pNl: number;
    fees: number;
    pNlIncludeFees: number;
  };
}

const MarketInfoGraph: FC<MarketInfoGraphProps> = ({ includeFees, data }) => {
  const plValue = Math.ceil(((includeFees ? data.pNlIncludeFees : data.pNl) / data.marketValue) * 100);
  const fees = Math.ceil((data.fees / data.marketValue) * 100);
  const feesWidth = `${fees}%`;
  const plWidth = `${plValue}%`;
  const costWidth = `${100 - ((includeFees ? fees : 0) + plValue)}%`;
  if (!data.marketValue) return null;

  return (
    <div className="flex flex-col w-full">
      <div className="w-full h-[16px]" style={{ background: InfoColors.MARKET }} />
      <div className="w-full flex">
        <div className={` h-[16px] `} style={{ background: InfoColors.P_AND_L, width: plWidth }} />
        {includeFees && <div className={`h-[16px] `} style={{ background: InfoColors.FEES, width: feesWidth }} />}
        <div className={` h-[16px] `} style={{ background: InfoColors.COST, width: costWidth }} />
      </div>
    </div>
  );
};

const SellOrInvestProductTemplate: FC<SellOrInvestProductTemplateProps> = ({
  product,
  timestamp,
  onCTA,
  isDetailsFetched = false,
  isLoadingMarket,
  marketOffers,
  isLoadingPortfolioStock,
  portfolioStock,
}) => {
  const NA = 'n/a';
  const { t } = useTranslation();
  const {
    formatter: currencyFormatter,
    state: {
      settings: { currency },
    },
  } = useContext(AppContext);

  const formatter = useMemo(
    () => ({ format: (value: number) => currencyFormatter.format(value, true) }),
    [currencyFormatter],
  );
  const [currentTimestamp, setCurrentTimestamp] = useState<number | undefined>(timestamp);
  const [productDetails, setProductDetails] = useState(product);

  const { data: currentHoldingsDetailsVT2 } = useQuery({
    queryFn: () => fetchCurrentHoldingDetailsVT2({ lwin18: product.lwin18 }),
    queryKey: ['fetchProductDetails', { lwin18: product.lwin18 }],
    enabled: !isDetailsFetched,
  });

  const slideoutContext = useContext(SortAndFilterLayoutContext);
  const colHeaderClassname = 'text-center text-sm bg-vine text-white flex-1';
  const columns: TableColumnType[] = [
    {
      dataRef: 'rotationNumber',
      className: colHeaderClassname,
      text: capitalizeFirstLetter(
        t(`product:dataValuations.tableHeadings.${DATA_VALUATIONS_TABLE_HEADINGS.ROTATION_NUM}`),
      ),
      cellType: CellTypeEnum.TH,
      cellClassName,
    },
    {
      dataRef: 'location',
      className: colHeaderClassname,
      text: capitalizeFirstLetter(t(`product:dataValuations.tableHeadings.${DATA_VALUATIONS_TABLE_HEADINGS.LOCATION}`)),
      cellType: CellTypeEnum.TH,
      cellClassName: `${cellClassName} text-center`,
    },

    {
      dataRef: 'dealDate',
      className: colHeaderClassname,
      text: capitalizeFirstLetter(
        t(`product:dataValuations.tableHeadings.${DATA_VALUATIONS_TABLE_HEADINGS.PURCHASE_DATE}`),
      ),
      cellType: CellTypeEnum.TH,
      cellClassName: `${cellClassName} text-center`,
    },
  ];

  // const getProductDetails = async () => {
  //   const { data, error } = await fetchProductDetails({ variables: { id: product.id } });
  //   if (error instanceof Error) {
  //     logError((error as Error).message);
  //   }
  //   return (data as any)?.portalHoldingDetails as Product;
  // };

  const rows = useMemo(() => {
    const allRows = (
      productDetails?.holdingStocks || [
        {
          rotationNumber: productDetails?.rotationNumber,
          dealDate: productDetails?.dealDate,
          location: productDetails?.location,
        },
      ]
    ).map((valuation) =>
      buildTableRow(
        {
          ...valuation,
          rotationNumber: valuation?.rotationNumber || 'n/a',
          location: valuation?.location || 'n/a',
          dealDate: valuation?.dealDate ? moment(valuation.dealDate).format('DD MMM YYYY') : 'n/a',
        },
        columns,
      ),
    );
    return [...allRows];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  const [buySellModel, setBuySellModel] = useState<BuySellHoldingModel>({ ...defaultModel });

  const [arrangeDeliveryModel, setArrangeDeliveryModel] = useState<ArrangeDeliveryRequest>({
    lwin18: product.lwin18,
    qty: 0,
    ...blankAddessModel,
  });

  const [viewState, setViewState] = useState(ViewStateType.DEFAULT);
  const {
    wineName,
    totalValue: price,
    changedPct,
    profitAndLoss: valuePnL,
    vintage,
    highestBid,
    lowestOffer,
    physicalQuantity,
  } = product;
  const displayText = useMemo(() => buildDisplayText(Object.values(DisplayTextKeys), 'product:wineDetails', t), [t]);
  const pAndL = `${valuePnL > 0 ? '+' : ''}${formatter.format(valuePnL || 0)} (${
    changedPct > 0 ? '+' : ''
  }${roundNumber(changedPct)}%)`;
  const compConfig = useMemo(() => {
    return {
      costPerUnit: 'avgCost' as keyof Product,
    };
  }, []);

  const marketInfos = useMemo(() => {
    const perUnitText = displayText[DisplayTextKeys.PER_UNIT].toLowerCase();
    const bottom = [
      {
        title: displayText[DisplayTextKeys.AVG_PURCHASE_PRICE],
        value: productDetails?.totalCost || 0,
        unitPrice: productDetails?.[compConfig.costPerUnit] || 0,
        unitPriceSuffix: perUnitText,
        color: InfoColors.COST,
      },
    ];

    return {
      top: [
        {
          title: displayText[DisplayTextKeys.MARKET_PRICE],
          value: productDetails?.totalValue || 0,
          unitPrice: productDetails?.valuePerUnit || 0,
          unitPriceSuffix: perUnitText,
          color: InfoColors.MARKET,
        },
        {
          title: displayText[DisplayTextKeys.AVG_PROFIT_LOSS].trim(),
          value: productDetails?.profitAndLoss || 0,
          unitPrice: productDetails?.avgProfitAndLoss || productDetails.profitAndLoss / productDetails.qty || 0,
          unitPriceSuffix: perUnitText,
          color: InfoColors.P_AND_L,
        },
      ],
      bottom,
      graphInfo: {
        marketValue: productDetails?.totalCost,
        pNlIncludeFees: productDetails?.profitAndLoss || 0,
        pNl: productDetails?.netPosition || 0,
        fees: productDetails?.totalMgmtFee,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetails]);

  useEffect(() => {
    if (timestamp !== currentTimestamp) {
      setCurrentTimestamp(timestamp);
      setViewState(ViewStateType.DEFAULT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  const onBack = () => {
    slideoutContext?.updateSlideoutConfig({ showBackButton: false });
    setBuySellModel({ ...defaultModel });
    if (onCTA) onCTA(ProductEventType.SET_TITLE, t`portfolio:wineDetails.title`);
    setViewState(ViewStateType.DEFAULT);
  };

  useEffect(() => {
    if (viewState !== ViewStateType.DEFAULT) slideoutContext?.updateSlideoutConfig({ showBackButton: true, onBack });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState]);

  useEffect(() => {
    if (currentHoldingsDetailsVT2 && !isDetailsFetched) {
      setProductDetails({
        ...currentHoldingsDetailsVT2,
      });
    }
  }, [currentHoldingsDetailsVT2, currency, isDetailsFetched]);

  return (
    <div className="flex flex-col bg-white  divide-y divide-gray-200  ">
      <div className="flex justify-between p-3 pb-5">
        <span className="text-20 mr-5">{`${vintage} ${wineName}`}</span>
      </div>
      {viewState !== ViewStateType.ARRANGE_DELIVERY && viewState !== ViewStateType.ACCEPT_BID && (
        <div className="flex flex-col">
          <div className="flex justify-center flex-col items-center p-5 pb-0">
            <span className="text-sm">{displayText[DisplayTextKeys.TOTAL_VALUE]}</span>
            <div className="flex">
              <div className="text-20 mr-2">{formatter.format(price)}</div>
              <span className={`${valuePnL > 0 ? 'text-trendup' : 'text-trenddown'} text-14`}>{pAndL}</span>
            </div>
          </div>
          <div className="my-5 flex justify-center">
            <div className="flex flex-col items-center flex-1 ">
              <div className="flex flex-col items-center border-r pb-3 border-r-gray-200 w-full">
                <span className="text-xs"> {displayText[DisplayTextKeys.HIGHEST_BID]}</span>
                <span className="text-14">
                  {highestBid?.priceInTargetCurrency
                    ? currencyFormatter.format(highestBid?.priceInTargetCurrency ?? 0, true)
                    : NA}
                </span>
              </div>
              {/* {viewState === ViewStateType.DEFAULT && qtyForSale > 0 && (
                <div className="bg-accent_orange w-full h-10 border-r pr-3 border-gray-200 flex justify-end items-center ">
                  <div className="text-14 mr-1">
                    {`${qtyForSale}x ${t('product:wineDetails.forSale').toLowerCase()}`}
                  </div>
                </div>
              )} */}
            </div>
            <div />
            <div className="flex flex-col items-center flex-1 w-full">
              <div className="flex flex-col items-center border-l pb-3 border-l-gray-200 w-full">
                <span className="text-xs">{displayText[DisplayTextKeys.LOWEST_OFFER]}</span>
                <span className="text-14">
                  {lowestOffer?.priceInTargetCurrency
                    ? currencyFormatter.format(lowestOffer?.priceInTargetCurrency ?? 0, true)
                    : NA}
                </span>
              </div>
              {/* {viewState === ViewStateType.DEFAULT && qtyForSale > 0 && (
                <div className="bg-accent_orange w-full h-10 border-l pl-3 border-gray-200 flex items-center ">
                  <div className="text-14 ">{`${formatter.format(priceForSale)}/${t(
                    'product:wineDetails.unit',
                  ).toLowerCase()}`}</div>
                </div>
              )} */}
            </div>
          </div>

          {viewState === ViewStateType.DEFAULT && (
            <div className="flex flex-col gap-4 px-3 mb-4">
              {viewState === ViewStateType.DEFAULT && isLoadingMarket && (
                <div className="btn rounded-full text-black bg-gray-300 animate-pulse " />
              )}

              {viewState === ViewStateType.DEFAULT && isLoadingPortfolioStock && (
                <div className="btn rounded-full text-black bg-gray-300 animate-pulse " />
              )}
              {viewState === ViewStateType.DEFAULT && !isLoadingMarket && (
                <Button
                  isDisable={!marketOffers}
                  onClick={() => {
                    if (onCTA) onCTA(ProductEventType.BUY, '');
                  }}
                  className="btn bg-orange rounded-full text-black disabled:bg-gray-300"
                >
                  {`${displayText[DisplayTextKeys.BUY]} ${
                    product.lowestOffer?.priceInTargetCurrency
                      ? formatter.format(product.lowestOffer?.priceInTargetCurrency ?? 0)
                      : ''
                  }`}
                </Button>
              )}

              {viewState === ViewStateType.DEFAULT && !isLoadingPortfolioStock && (
                <Button
                  isDisable={!portfolioStock}
                  onClick={() => {
                    if (onCTA) onCTA(ProductEventType.MAKE_OFFER, '');
                  }}
                  className="btn bg-vine text-white rounded-full disabled:bg-gray-300 disabled:text-black"
                >
                  {displayText[DisplayTextKeys.MAKE_OFFER]}
                </Button>
              )}

              <Button
                isDisable={!highestBid?.priceInTargetCurrency || physicalQuantity < 1}
                onClick={() => {
                  if (onCTA) onCTA(ProductEventType.SELL, '');
                }}
                className="btn rounded-full text-black outline disabled:outline-none disabled:bg-gray-300"
              >
                {`${displayText[DisplayTextKeys.SELL]} ${
                  product.highestBid?.priceInTargetCurrency && physicalQuantity > 0
                    ? formatter.format(product.highestBid?.priceInTargetCurrency ?? 0)
                    : ''
                }`}
              </Button>
            </div>
          )}
        </div>
      )}

      {viewState === ViewStateType.DEFAULT && (
        <>
          <div className="py-5 px-3 flex flex-col gap-[10px]">
            <span className="text-14 font-medium py-1">{displayText[DisplayTextKeys.DATA_AND_VALUATION]}</span>
            <div>
              <Table columns={columns} rows={rows} className="border border-gray-100" />
            </div>

            <div className="flex flex-col">
              <div className=" flex w-full h-auto overflow-hidden  items-center py-5 gap-[8px]">
                {marketInfos.top.map((item, index) => {
                  const unitdetails = `${formatter.format(item.unitPrice)} ${item.unitPriceSuffix}`;
                  return (
                    <div key={`${item.title}-${index}`} className="flex ">
                      <div className="w-[4px] h-[56px] self-stretch mr-[8px]" style={{ background: item.color }} />
                      <div className="flex flex-col ">
                        <span className="text-xs whitespace-nowrap">{item.title}</span>
                        <span className="text-14 ">{formatter.format(item.value)}</span>
                        <span className="text-sm whitespace-nowrap">{unitdetails}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <MarketInfoGraph includeFees={false} data={marketInfos.graphInfo} />
              <div className=" flex w-full h-auto overflow-hidden  items-center py-5 gap-[8px]">
                {marketInfos.bottom.map((item, index) => {
                  const unitdetails = `${formatter.format(item.unitPrice as number)} ${item.unitPriceSuffix}`;
                  return (
                    <div key={`${item.title}-${index}`} className="flex ">
                      <div className="w-[4px] h-[56px] self-stretch mr-[8px]" style={{ background: item.color }} />
                      <div className="flex flex-col ">
                        <span className="text-xs whitespace-nowrap">{item.title}</span>
                        <span className="text-14 ">{formatter.format(item.value)}</span>
                        <span className="text-sm whitespace-nowrap">{unitdetails}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <div className="px-5 py-5 text-14 font-medium">{displayText[DisplayTextKeys.LIVE_EX_CHART_TITLE]}</div>
            <div>
              <LiveExPerformanceChart chartData={productDetails?.historicMarketPrices || []} />
              <div className="p-5 text-14">{displayText[DisplayTextKeys.LIVE_EX_PERFORMANCE_CHART_DESCRIPTION]}</div>
            </div>
          </div>
          <div className="w-full p-3 overflow-hidden relative pb-5  border-none">
            <div className="w-full bg-gray-100  text-left rounded-md p-5 gap-2 ">
              <img
                src={cutlWinesLoversImage}
                className="absolute left-[172px] bg-transparent  w-[175px] object-cover"
                alt="wine lovers"
              />
              <div className="text-base font-medium ">{displayText[DisplayTextKeys.ARRANGE_DELIVERY_TITLE]}</div>
              <div className="text-sm w-[233px]">{displayText[DisplayTextKeys.ARRANGE_DELIVERY_SUBTITLE]}</div>
              <Button
                className="btn text-14 font-normal bg-orange rounded-full mt-3  text-black"
                onClick={() => {
                  if (onCTA) onCTA(ProductEventType.SET_TITLE, displayText[DisplayTextKeys.ARRANGE_DELIVERY_TITLE]);
                  setViewState(ViewStateType.ARRANGE_DELIVERY);
                }}
                props={{
                  name: DisplayTextKeys.ARRANGE_DELIVERY_BUTTON_TEXT,
                }}
              >
                {displayText[DisplayTextKeys.ARRANGE_DELIVERY_BUTTON_TEXT]}
              </Button>
            </div>
          </div>
        </>
      )}

      {viewState === ViewStateType.INVEST_MORE && (
        <InvestMoreTemplate
          formatter={formatter}
          onCTA={onCTA}
          lwin18={product.lwin18}
          marketPrice={product.valuePerUnit}
          model={buySellModel}
          qtyOwned={product.qty || 0}
          setModel={(modelIn) => setBuySellModel(modelIn)}
        />
      )}

      {viewState === ViewStateType.SELL_HOLDINGS && (
        <SellHoldingTemplate
          formatter={formatter}
          onCTA={onCTA}
          lwin18={product.lwin18}
          marketPrice={product.valuePerUnit}
          model={buySellModel}
          qtyOwned={product.qty || 0}
          setModel={(modelIn) => setBuySellModel(modelIn)}
        />
      )}

      {viewState === ViewStateType.ARRANGE_DELIVERY && (
        <ArrangeDeliveryTemplate
          qtyOwned={product.qty || 0}
          onCTA={onCTA}
          model={arrangeDeliveryModel}
          setModel={(modelIn) => setArrangeDeliveryModel(modelIn)}
        />
      )}
    </div>
  );
};

export default SellOrInvestProductTemplate;
