import moment from 'moment';
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableRow } from 'components/Table';
import { useQuery } from '@tanstack/react-query';
import { fetchMyCellar, fetchMyCellarDetails } from 'views/MyCellar/services/fetchMyCellar';
import { logError } from 'components/LogError';
import { LocationIcon } from '../../assets/icons';
import { DDFilterItem, FilterTypes } from '../../components/Filters';
import { SortAndFilterLayoutContextType, TabState } from '../../components/Layout/SortAndFilterLayout';
import Loading from '../../components/Loading/loading';
import ImageCard from '../../components/ProductTemplates/components/ImageCard';
import StockTab from '../../components/StockTab';
import { AppContext } from '../../context/ContextProvider';
import { getBlankProduct, getImageUrl, getRegions } from '../../helpers';
import { AppEventTypes } from '../../types/AppType';
import { NavigationPath } from '../../types/DomainTypes';
import { Product, BaseProduct, DATA_REFS } from '../../types/productType';
import { capitalizeFirstLetter, sumBy, toInternalId, uniqueItems } from '../../utils';
import useFadeInOnScroll from '../hooks/useFadeInOnScroll';
import useInfiniteScroll from '../hooks/useInfiniteScroll';
import { buildTableRow, initialiseMyCellarExportColumns } from '../Portfolio/helpers';
import { SortByOption } from '../Portfolio/types';
import ProtectedPageLayout from '../shared/ProtectedPageLayout';

const MyCellar = () => {
  const { t } = useTranslation();
  const regions = useMemo(() => getRegions(t), [t]);
  const {
    state: {
      app: { refresh },
    },
    dispatch,
    gbpToTargetCurrency,
  } = useContext(AppContext);
  const [selectedAssetUnitId, setSelectedAssetUnitId] = useState<string>('');
  const [layoutContext, setLayoutContext] = useState<SortAndFilterLayoutContextType | undefined>();
  const {
    data: myCellarStockVT2,
    isLoading: loadingMyCellarVT2,
    error: myCellarError,
  } = useQuery({
    queryFn: () => fetchMyCellar(),
    queryKey: ['fetchMyCellar'],
  });

  const {
    columns,
    sortByOptions,
    defaultSortBy: currentDefaultSortBy,
  } = useMemo(() => initialiseMyCellarExportColumns(t), [t]);

  const myCellarStock = useMemo(() => {
    return myCellarStockVT2?.data;
  }, [myCellarStockVT2]);

  const loadingMyCellar = useMemo(() => {
    return loadingMyCellarVT2;
  }, [loadingMyCellarVT2]);

  const fetchMyCellarDetailsApi = (assetUnitId: string) => {
    if (!selectedAssetUnitId) return Promise.resolve(null);
    return fetchMyCellarDetails({ assetUnitId });
  };

  const {
    data: myCellarDetailsVT2,
    isFetching,
    error: fetchMyCellarDetailsApiError,
    refetch: refetchMyCellar,
  } = useQuery({
    queryFn: () => fetchMyCellarDetailsApi(selectedAssetUnitId),
    queryKey: ['fetchMyCellarDetails', { assetUnitId: selectedAssetUnitId }],
    enabled: !!selectedAssetUnitId,
  });

  const currentTabState = useRef<TabState | null>(null);
  const pageTitle = 'cellar';
  const title = t(`common:${pageTitle}`);
  const [filteredData, setFilteredData] = useState<Product[]>([]);
  const onStateChange = (state: TabState | null) => {
    const update = state?.filters?.data;
    if (update !== filteredData) {
      setFilteredData(update || []);
    }
    currentTabState.current = state;
  };

  const stocks = useMemo(() => {
    if (!myCellarStock) return [];
    const adjustQtySource = (myCellarStock as BaseProduct[])
      .map((x) => ({ ...getBlankProduct(), ...x }))
      .map((x) => {
        return {
          ...x,
          qty: x.rotationNumber && x.rotationNumber.length > 0 ? 1 : x.qty,
          [DATA_REFS.SANITIZED_WINE_NAME]: x[DATA_REFS.NAME].normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        };
      });
    return adjustQtySource;
  }, [myCellarStock]);

  const { isLoading, results, lastItemRef } = useInfiniteScroll(filteredData);
  const processIntersectionObserverEntry = useCallback((entry: IntersectionObserverEntry) => {
    entry.target.classList.toggle('flex', entry.isIntersecting);
  }, []);

  const { isItemVisible, fadeOnScrollClassName } = useFadeInOnScroll({ isLoading, processIntersectionObserverEntry });

  const statuses = useMemo(() => {
    if (!myCellarStock) return [];
    return uniqueItems((myCellarStock as BaseProduct[]).map((x) => x.status));
  }, [myCellarStock]);

  const totals = useMemo(() => {
    const adjustQtySource = filteredData.map((x) => {
      return {
        ...x,
        cases: x.rotationNumber && x.rotationNumber.length > 0 ? 1 : x.qty,
        bottles: x.rotationNumber && x.rotationNumber.length > 0 ? x.unitCount : x.qty * x.unitCount,
      };
    });

    const bottles = sumBy(adjustQtySource, 'bottles');
    const cases = sumBy(adjustQtySource, 'cases');
    return {
      cases,
      bottles,
    };
  }, [filteredData]);

  const onItemSelect = (item: Product, context: SortAndFilterLayoutContextType | undefined) => {
    if (item.assetUnitId) {
      setLayoutContext(context);
      setSelectedAssetUnitId(item.assetUnitId);
    }
  };

  const buildTableRows = (dataSet: Product[]) => {
    return (dataSet || []).map((report) => {
      return {
        ...buildTableRow({ ...report }, columns || [], `${report.holdingId}`, 'divide-x-0'),
      };
    });
  };

  const tableRows = useMemo(() => {
    return buildTableRows(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  useLayoutEffect(() => {
    if (refresh && (refresh as string[]).length > 0 && (refresh as string[]).includes(NavigationPath.MY_CELLAR)) {
      refetchMyCellar();
      dispatch({
        type: AppEventTypes.UPDATE_STATE,
        payload: { refresh: (refresh as string[]).filter((x) => x !== NavigationPath.MY_CELLAR) },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    if (!isFetching && selectedAssetUnitId && layoutContext && myCellarDetailsVT2) {
      layoutContext.openSlideoutWithProcessor(() => {
        let modifiedCellarDetailsVT2: Product = myCellarDetailsVT2;
        if (myCellarDetailsVT2?.highestBid?.price) {
          modifiedCellarDetailsVT2 = {
            ...modifiedCellarDetailsVT2,
            highestBid: {
              ...myCellarDetailsVT2.highestBid,
              priceInTargetCurrency: gbpToTargetCurrency.convert(myCellarDetailsVT2.highestBid?.price ?? 0),
              priceGbp: myCellarDetailsVT2.highestBid.price,
            },
          };
        }

        if (myCellarDetailsVT2?.lowestOffer?.price) {
          modifiedCellarDetailsVT2 = {
            ...modifiedCellarDetailsVT2,
            lowestOffer: {
              ...myCellarDetailsVT2.lowestOffer,
              priceInTargetCurrency: gbpToTargetCurrency.convert(myCellarDetailsVT2.lowestOffer?.price ?? 0),
              priceGbp: myCellarDetailsVT2.lowestOffer.price,
            },
          };
        }

        return Promise.resolve(modifiedCellarDetailsVT2);
      });
      setSelectedAssetUnitId('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  useEffect(() => {
    if (fetchMyCellarDetailsApiError) {
      logError(JSON.stringify(fetchMyCellarDetailsApiError));
    }
  }, [fetchMyCellarDetailsApiError]);

  useEffect(() => {
    if (myCellarError) {
      logError(JSON.stringify(myCellarError));
    }
  }, [myCellarError]);

  return (
    <ProtectedPageLayout view={NavigationPath.MY_CELLAR} title={title} onBack={() => null} showBackButton={false}>
      {loadingMyCellar && (
        <div className="w-full h-full justify-center p-10">
          <Loading />
        </div>
      )}
      {!loadingMyCellar && (
        <>
          <div className="p-5 flex justify-center items-center">
            <div className="flex divide-x divide-gray-300">
              <div className="text-base px-3">{`${totals.cases} cases`}</div>
              <div className="text-base px-3">{`${totals.bottles} bottles`}</div>
            </div>
          </div>
          <StockTab
            columns={columns}
            sortByOptions={sortByOptions as SortByOption[]}
            defaultSortBy={currentDefaultSortBy}
            tabState={null}
            id="myCellar"
            datasource={stocks as Product[]}
            isCardViewDefault={true}
            onStateChange={onStateChange}
            loading={loadingMyCellar}
            error={myCellarError ? new Error(t('common:somethingWentWrong.subTitle')) : null}
            filterConfigure={(filters: DDFilterItem[]) => {
              return filters.filter((x) => x.type !== FilterTypes.P_AND_L);
            }}
            filterPanelContainerClassName="px-10 bg-gray-100"
            filterOverrides={{
              [FilterTypes.STATUES]: {
                source: statuses,
                filterFn: (data: Product[], ids: string[]) => {
                  let statusesFilterResult: Product[] = [];
                  statuses.forEach((status) => {
                    if (ids.includes(toInternalId(status!))) {
                      statusesFilterResult = [
                        ...statusesFilterResult,
                        ...data.filter((x) => x.status?.toLowerCase() === status?.toLowerCase()),
                      ];
                    }
                  });
                  return statusesFilterResult;
                },
              },
            }}
          >
            {(context) =>
              context?.cardLayoutConfig?.show ? (
                <div className="flex flex-col flex-1 bg-gray-100">
                  <div className="flex  gap-[24px] flex-wrap justify-center overflow-hidden overflow-y-auto  p-7">
                    {results.map((item, index) => {
                      let lastElemRefOption = { ref: isItemVisible };
                      if (results.length === index + 1) {
                        lastElemRefOption = { ref: lastItemRef };
                      }
                      const id = `${item.id}-${index}`;
                      const name = `${item.vintage} ${item.wineName}`;
                      const qtyString = `${item.qty}x (${item.unit})`;
                      const selectedRegion = regions.find(
                        (x) => x.id === toInternalId(item?.cultWinesAllocationRegion!.toLowerCase()),
                      );
                      const regionColor = selectedRegion?.color;
                      const textColor = `text-${selectedRegion?.textColor || 'black'}`;
                      const { dealDate, dealRef, rotationNumber, imageFileName, location, status } = item;
                      const itemImage = getImageUrl(imageFileName || '', { height: 200 });
                      const image = (
                        <div className="h-[130px]">
                          <img className="img h-full" alt={title} src={itemImage} />
                        </div>
                      );
                      return (
                        <ImageCard
                          {...lastElemRefOption}
                          key={id}
                          id={id}
                          image={image}
                          onClick={() => onItemSelect(item, context)}
                          className={`${fadeOnScrollClassName} sm:w-[302px] w-full`}
                          imageClassName="w-full"
                        >
                          <div className="w-full flex-1">
                            <div className="relative space-y-[4px] flex flex-col border-none ">
                              <div className="whitespace-nowrap truncate text-20">{name}</div>
                            </div>
                            <div
                              style={{ background: regionColor! }}
                              className={`flex  justify-center items-center border w-fit px-3 py-[2px] rounded-full h-auto mt-1 ${textColor}`}
                            >
                              <div className="w-fit whitespace-nowrap text-center font-semibold text-sm">
                                {capitalizeFirstLetter(item.cultWinesAllocationRegion!)}
                              </div>
                            </div>
                            <div className="text-14 whitespace-nowrap truncate mt-2">{qtyString}</div>
                            <div className="text-sm py-3 flex divide-x divide-gray-300 ">
                              <span className="pr-3">{`${moment(dealDate).format('DD MMM YYYY')}`.toUpperCase()}</span>
                              <span className="px-3">{dealRef}</span>
                              {rotationNumber && <span className="pl-3"> {rotationNumber}</span>}
                            </div>
                          </div>
                          <div className="text-sm flex w-full ">
                            <div className="mr-3 flex  flex-1 text-xs">
                              <LocationIcon className="mr-1 " />
                              {location}
                            </div>
                          </div>
                        </ImageCard>
                      );
                    })}
                  </div>
                  <div className="flex-1" />
                </div>
              ) : (
                <div className="flex justify-center flex-1 bg-gray-100 p-7">
                  <Table
                    containerClassNames=" w-full rounded-md"
                    columns={columns || []}
                    rows={tableRows || []}
                    onTableEvent={(row) => {
                      const item = row as TableRow;
                      const selectedItem = item.rowData as unknown as Product;
                      // const selectedItem = results.find((x: BaseProduct) => `${x.holdingId}` === (row as TableRow).id);
                      onItemSelect(selectedItem, context);
                    }}
                  />
                </div>
              )
            }
          </StockTab>
        </>
      )}
    </ProtectedPageLayout>
  );
};

export default MyCellar;
