import { useEffect, useState } from 'react';
import { acceptHighestBid, IAcceptHighestBidBody } from 'views/Trade/services/acceptHighestBid';
import useMutate from 'views/Trade/hooks/useMutate';
import { fetchBookGroups } from '../services/fetchBookGroups';

enum StatusTypes {
  success = 'success',
  error = 'error',
  isLoading = 'isLoading',
}

interface OnStatusChangeTypes {
  isLoading: (() => void) | null;
  success: (() => void) | null;
  error: (() => void) | null;
  finally: (() => void) | null;
}

export const useHandleAcceptBid = () => {
  const { mutateAsync: mutateBid } = useMutate({ mutationFn: acceptHighestBid });
  const [mutateStatus, setMutateStatus] = useState<StatusTypes | null>();
  const [onStatusChange, setOnStatusChange] = useState<OnStatusChangeTypes>({
    error: null,
    isLoading: null,
    success: null,
    finally: null,
  });
  const resetStatus = () => setMutateStatus(null);

  useEffect(() => {
    if (mutateStatus === StatusTypes.success && onStatusChange.success) {
      onStatusChange.success();
      onStatusChange.finally?.();
      setMutateStatus(null);
    }
    if (mutateStatus === StatusTypes.isLoading && onStatusChange.isLoading) {
      onStatusChange.isLoading();
    }
    if (mutateStatus === StatusTypes.error && onStatusChange.error) {
      onStatusChange.error();
      onStatusChange.finally?.();
      setMutateStatus(null);
    }
  }, [mutateStatus, onStatusChange]);

  const handlePending = (orderGroupId: string | number) => {
    let attempts = 0;
    const recusrsiveFunc = async () => {
      try {
        const bookGroupsResponse = await fetchBookGroups(orderGroupId);
        const { status } = bookGroupsResponse;
        if (status === 'Created' && attempts < 30) {
          attempts += 1;
          setTimeout(async () => {
            await recusrsiveFunc();
          }, 1000);
        } else if (status === 'Completed') {
          setMutateStatus(StatusTypes.success);
        } else {
          setMutateStatus(StatusTypes.error);
        }
      } catch (error) {
        setMutateStatus(StatusTypes.error);
      }
    };
    recusrsiveFunc();
  };

  const handleAcceptBid = async (params: IAcceptHighestBidBody, onChange?: OnStatusChangeTypes) => {
    setOnStatusChange({
      error: onChange?.error ?? null,
      isLoading: onChange?.isLoading ?? null,
      success: onChange?.success ?? null,
      finally: onChange?.finally ?? null,
    });

    setMutateStatus(StatusTypes.isLoading);
    try {
      const response = await mutateBid(params);
      if (response.data.status === 'Succeed') {
        const { orderGroupId } = response.data.result;
        await handlePending(orderGroupId);
      }
    } catch (error) {
      setMutateStatus(StatusTypes.error);
    }
  };

  return { handleAcceptBid, mutateStatus, resetStatus };
};
