import { FC, ReactNode, useEffect, useRef, useState, useCallback } from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDown, ChevronUp } from '../../assets/icons';
import { classNames } from '../../utils';

interface DropdownItem {
  id: string;
  value: string;
  content: ReactNode;
  text?: string;
}

interface ItemsConfig {
  items?: DropdownItem[];
  displayItems?: DropdownItem[];
  start: number;
  end: number;
}

interface DropdownPropType {
  value?: string;
  placeholder?: string;
  onItemSelect?: (item: DropdownItem) => void;
  items?: DropdownItem[];
  className?: string;
  iconClassName?: string;
  style?: Record<string, unknown>;
  valueText?: string;
  header?: ReactNode;
  footer?: ReactNode;
  itemClassName?: string;
  onOpen?: () => void;
  children?: ReactNode;
  containerClassName?: string;
  itemsContainerClassName?: string;
  itemsWrapperClassName?: string;
  autoClose?: boolean;
  open?: boolean;
  isDisabled?: boolean;
  valueTemplate?: ReactNode;
  itemWrapperStyle?: Record<string, unknown>;
  id: string;
  isActive?: boolean;
}

const InfiniteScrollDropdown: FC<DropdownPropType> = ({
  id,
  onItemSelect,
  items,
  className,
  value,
  placeholder,
  iconClassName,
  style,
  valueText,
  valueTemplate,
  header,
  footer,
  containerClassName,
  itemClassName,
  itemsContainerClassName,
  itemsWrapperClassName,
  onOpen,
  children,
  open,
  isDisabled = false,
  autoClose = true,
  itemWrapperStyle,
  isActive,
}) => {
  const divRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const START = 0;
  const END = 20;
  const [itemsConfig, setItemsConfig] = useState<ItemsConfig>({
    items: [],
    displayItems: [],
    start: START,
    end: END,
  });

  const handleScroll = useCallback(() => {
    const div = divRef.current as unknown as HTMLDivElement;
    if (div.scrollTop + div.clientHeight + 350 >= div.scrollHeight) {
      setItemsConfig((prev) => ({
        ...prev,
        displayItems: [...(prev.items ?? [])].slice(0, prev.end + END),
        end: prev.end + END,
      }));
    }
  }, []);

  useEffect(() => {
    if (items)
      setItemsConfig((prev) => ({
        ...prev,
        items: items,
        displayItems: [...(items ?? [])].slice(0, prev.end),
      }));
  }, [items]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.id === id) {
        setIsOpen((prev) => !prev);
      } else {
        setIsOpen(false);
      }
    };

    const div = divRef.current as unknown as HTMLDivElement;
    if (div) {
      div.addEventListener('scroll', handleScroll);
      document.addEventListener('click', handleClick);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
        document.removeEventListener('click', handleClick);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current, divRef, id]);

  return (
    <Menu as="div" className={`relative  text-left ${containerClassName || ''}`.trim()}>
      <button
        id={id}
        onClick={() => {
          if (onOpen && items && items.length > 0) {
            onOpen();
          }
        }}
        style={style}
        className={classNames(
          `${children ? '' : 'relative '}  ${isDisabled ? `btn-disabled` : ''}`,
          `${className || ''}`,
        )}
      >
        {children || (
          <>
            {isActive && (
              <div className="rounded-full h-[6px] w-[6px]  min-h-[6px]  min-w-[6px]  mr-1 bg-orange pointer-events-none" />
            )}

            {valueTemplate || valueText || value || placeholder || ''}

            {!open && items && items.length > 0 && (
              <ChevronDown
                className={`-mr-1 ml-2 pointer-events-none  ${iconClassName || ''}  ${
                  isDisabled ? 'opacity-30' : 'opacity-100'
                }`}
                aria-hidden="true"
              />
            )}
            {!isDisabled && items && items.length > 1 && open && (
              <ChevronUp className={`-mr-1 ml-2 pointer-events-none  ${iconClassName || ''}`} aria-hidden="true" />
            )}
          </>
        )}
      </button>

      {(header !== undefined || footer !== undefined || (items && items.length > 0)) && (
        <div className={`  ${isOpen ? 'visible' : 'hidden'}`}>
          <Menu.Items
            hidden={!isOpen}
            style={{ ...(itemWrapperStyle || {}) }}
            {...(!autoClose && { static: true })}
            className={`absolute z-10 mt-2  origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
              itemsWrapperClassName || ''
            }`.trim()}
          >
            {header && <div className="py-1">{header}</div>}

            <div className={`py-1 ${itemsContainerClassName || ''}`.trim()} ref={divRef}>
              {itemsConfig.displayItems &&
                itemsConfig.displayItems.length > 0 &&
                itemsConfig.displayItems.map((item) => {
                  return (
                    <div
                      key={item.id}
                      onClick={() => {
                        if (item.value !== value && onItemSelect) onItemSelect(item);
                      }}
                      className={classNames(
                        'cursor-pointer',
                        false ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        `block px-4 py-2 text-sm ${value === item.value ? 'bg-gray-300 text-gray-900' : ''}`.trim(),
                        `${itemClassName || ''}`.trim(),
                      )}
                    >
                      {item.content}
                    </div>
                  );
                })}
            </div>

            {footer && <div className="py-1">{footer}</div>}
          </Menu.Items>
        </div>
      )}
    </Menu>
  );
};

export default InfiniteScrollDropdown;
