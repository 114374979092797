import { useFormik } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { getInvestMorePricingOptions } from '../../../helpers';
import { CurrencyFormater } from '../../../types/commonTypes';
import { buildDisplayText } from '../../../utils';
import Button from '../../Button';
import CustomInput from '../../CustomInput';
import { DisplayFieldType } from '../../DisplayForms';
import Dropdown, { DropdownItem } from '../../Dropdown';
import { SortAndFilterLayoutContext } from '../../Layout/SortAndFilterLayout';
import { BuyProductRequest } from '../services/buyRequestApi';
import { BuySellHoldingModel, InvestMoreTemplateViewEnum, PricingType, ProductEventType } from '../types';

enum BuySellHoldingModelProp {
  UNITS = 'units',
  PRICE = 'price',
  REASON = 'reason',
  MARKET_VALUE = 'marketValue',
}

type FuncType = <T>(x?: T) => void;
interface BuySellTemplateProps {
  model: BuySellHoldingModel;
  setModel: (model: BuySellHoldingModel) => void;
  qtyOwned: number;
  marketPrice: number;
  formatter: CurrencyFormater;
  lwin18?: string;
  onCTA?: <T>(event: ProductEventType, data: T) => void;
  canCustomPrice?: boolean;
  overideOnCTA?: FuncType[] | null;
  quantity?: number | null;
  isPrismicOffer?: boolean;
  extraData?: Record<string, unknown>;
}

enum DisplayTextKeys {
  CURRENT_BALANCE = 'currentBalance',
  BALANCE_REMAINING = 'balanceRemaining',

  NUMBER_OF_UNITS_TEXT = 'number_of_units_text',
  UNIT_COUNT_ABOVE_MAX = 'unit_count_above_max',
  UNIT_COUNT_BELOW_MIN = 'unit_count_below_min',

  PRICE_REQUIRED = 'price_required',
  CUSTOM_PRICE_TEXT = 'custom_price_text',

  PRICING_CHOICE = 'pricing_choice',
  CONTINUE = 'continue_of_units_text',
  SUBMIT_REQUEST = 'submit_request',
  NUMBER_OF_UNITS = 'number_of_units',
  TOTAL = 'total',
  PLEASE_SELECT_TEXT = 'please_select_text',
}

const InvestMoreTemplate = ({
  model,
  setModel,
  onCTA,
  lwin18,
  marketPrice,
  formatter,
  canCustomPrice = true,
  overideOnCTA,
  quantity,
  isPrismicOffer,
  extraData,
}: BuySellTemplateProps) => {
  const { t } = useTranslation();
  const displayText = useMemo(
    () => buildDisplayText(Object.values(DisplayTextKeys), 'product:wineDetails.investMore', t),
    [t],
  );
  const [currentModel, setCurrentModel] = useState<BuySellHoldingModel>(model);
  const slideoutContext = useContext(SortAndFilterLayoutContext);
  const mValidationSchema = yup.object({
    [BuySellHoldingModelProp.UNITS]: yup.number().min(1, displayText[DisplayTextKeys.UNIT_COUNT_BELOW_MIN]),
    [BuySellHoldingModelProp.PRICE]: yup
      .number()
      .min(1, t('product:wineDetails.investMore.price_below_min', { amount: formatter.format(1) }))
      .required(displayText[DisplayTextKeys.PRICE_REQUIRED]),
  });

  const pricingOptions: DropdownItem[] = useMemo(() => getInvestMorePricingOptions(t), [t]);
  const formik = useFormik<BuySellHoldingModel>({
    initialValues: { ...model, price: marketPrice },
    onSubmit: () => {
      if (onCTA)
        onCTA(ProductEventType.EXECUTE_BUY_PRODUCT_REQUEST, {
          lwin18: lwin18,
          qty: Number(currentModel.units),
          avgPurchasePrice: Number(currentModel.price),
          requestPrice: marketPrice,
        } as BuyProductRequest);
    },
    validationSchema: mValidationSchema || model,
  });

  const onPriceTypeChange = (item: DropdownItem) => {
    let price = 0;
    if (item.id === PricingType.MARKET) price = marketPrice;
    setModel({ ...model, price, pricingType: item.id as PricingType });
  };

  useEffect(() => {
    setCurrentModel({ ...model, price: marketPrice });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentModel({ ...model, price: model.pricingType === PricingType.MARKET ? marketPrice : model.price });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  const formIsValid = currentModel.price * currentModel.units > 0;

  const generateDropdownItem = () => {
    const arr: number[] = Array(quantity ?? 0).fill(1, 0);
    const items: DropdownItem[] = [];
    arr.forEach((_, i) => {
      items.push({
        id: `${i + 1}`,
        content: (
          <div key={i}>
            <span>{`${i + 1}`}</span>
          </div>
        ),
        value: `${i + 1}`,
      });
    });
    return items;
  };

  return (
    <div className="py-5 gap-5 text-left">
      {model.investMoreTemplateView === InvestMoreTemplateViewEnum.CAMPAIGNS && (
        <div className="px-5 py-3 flex flex-col gap-1">
          <span className="text-gray-700 text-sm">{displayText[DisplayTextKeys.NUMBER_OF_UNITS_TEXT]}</span>
          <Dropdown
            autoClose={true}
            placeholder="Please select quantity"
            value={model.units ? String(model.units) : ''}
            containerClassName="w-full rounded "
            itemsContainerClassName="max-h-[250px] min-h-10 overflow-y-auto"
            className="flex-1 text-14 text-black border-b  h-12 py-2 rounded justify-between items-center"
            itemsWrapperClassName="w-full"
            items={generateDropdownItem()}
            onItemSelect={(item) => {
              setModel({ ...model, units: Number(item.value) });
            }}
          />
        </div>
      )}
      {model.investMoreTemplateView !== InvestMoreTemplateViewEnum.CAMPAIGNS && (
        <div className="px-5 py-3 flex flex-col gap-3">
          <span className="text-gray-700 text-sm">{displayText[DisplayTextKeys.NUMBER_OF_UNITS_TEXT]}</span>
          <CustomInput
            name={BuySellHoldingModelProp.UNITS}
            type={DisplayFieldType.NUMERIC}
            value={formik.values.units}
            placeholder="0"
            onChange={(
              e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
              value?: string | number,
            ) => {
              formik.handleChange(e);
              setModel({ ...model, units: Number(value) });
            }}
            helperText={currentModel.units > 0 ? formik.errors[BuySellHoldingModelProp.UNITS] : ''}
          />
        </div>
      )}
      {canCustomPrice && (
        <div className="mx-5 pt-3 mt-5 pb-0 flex flex-col  gap-3 border-b border-gray-200">
          <span className="text-gray-700 text-sm">{displayText[DisplayTextKeys.PRICING_CHOICE]}</span>
          <Dropdown
            placeholder="Please select"
            value={`${currentModel.pricingType}`}
            valueTemplate={
              <div className="flex-1 text-start">
                {(pricingOptions.find((x) => x.id === currentModel.pricingType) || { text: '' }).text}
              </div>
            }
            onItemSelect={onPriceTypeChange}
            items={pricingOptions}
            className="flex-1 text-14 text-black"
            itemsWrapperClassName="w-full"
          />
        </div>
      )}
      {currentModel.pricingType === PricingType.CUSTOM && (
        <div className="px-5 py-3 mt-5 flex flex-col gap-3">
          <span className="text-gray-700 text-sm">{displayText[DisplayTextKeys.CUSTOM_PRICE_TEXT]}</span>
          <CustomInput
            value={currentModel.price}
            type={DisplayFieldType.CURRENCY}
            formatter={formatter}
            placeholder={formatter.format(0)}
            onBlur={formik.handleBlur}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
              value?: string | number,
            ) => {
              formik.handleChange(e);
              setModel({ ...model, [BuySellHoldingModelProp.PRICE]: Number(value) });
            }}
            name={BuySellHoldingModelProp.PRICE}
            helperText={
              formik.touched[BuySellHoldingModelProp.PRICE] && Boolean(formik.errors[BuySellHoldingModelProp.PRICE])
                ? formik.errors[BuySellHoldingModelProp.PRICE]
                : ''
            }
          />
        </div>
      )}
      <div className="flex flex-col py-10 px-5 drop-shadow-sm mt-[12px]">
        {(extraData?.isLD as boolean) && (
          <div className="flex justify-between divide-x divide-gray-300 mb-[55px]">
            <div className="flex-1 flex flex-col">
              <span className="text-center text-sm">{displayText[DisplayTextKeys.CURRENT_BALANCE]}</span>
              <span className="text-center text-20">{formatter.format(extraData?.accountBalance as number)}</span>
            </div>

            <div className="flex-1 flex flex-col">
              <span className="text-center text-sm">{displayText[DisplayTextKeys.BALANCE_REMAINING]}</span>
              <span className="text-center text-20">
                {formatter.format(extraData?.accountBalanceRemaining as number)}
              </span>
            </div>
          </div>
        )}

        <div className="flex justify-between items-center ">
          <span className="text-center text-20">{displayText[DisplayTextKeys.TOTAL]}</span>
          <span className="text-center text-20">{formatter.format(currentModel.units * currentModel.price)}</span>
        </div>

        <Button
          isDisable={!formIsValid}
          className={` text-14 font-normal rounded-full mt-5 mb-  ${
            !formIsValid ? 'btn-disabled' : 'btn  bg-orange text-black'
          }`}
          onClick={() => {
            if (overideOnCTA) {
              overideOnCTA?.[0]();
              return;
            }
            slideoutContext?.updateSlideoutConfig({ showBackButton: false });
            formik.handleSubmit();
          }}
          props={{
            name: isPrismicOffer ? displayText[DisplayTextKeys.SUBMIT_REQUEST] : displayText[DisplayTextKeys.CONTINUE],
          }}
        >
          {extraData?.buttonText ? String(extraData?.buttonText) : displayText[DisplayTextKeys.SUBMIT_REQUEST]}
        </Button>

        {!isPrismicOffer && (
          <Button
            className={`my-4 h-8 text-14 font-normal rounded-full btn flex justify-center items-center  text-black disabled:bg-gray-500 bg-orange`.trim()}
            onClick={() => window.open('https://www.wineinvestment.com/offers/investment-analysis/', '_blank')}
          >
            Read analysis
          </Button>
        )}
      </div>
    </div>
  );
};

export default InvestMoreTemplate;
